import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from 'src/app/models/Tag';
import { IntegrationActivationStatus } from 'src/app/models/IntegrationActivated';

import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class Call4UService {

  constructor(private http: HttpClient
    ) { }

  isActivated(): Observable<IntegrationActivationStatus> {
    return this.http.get<IntegrationActivationStatus>(`${environment.apiUrl}/call4u/status`)
  }

  activate(): Observable<IntegrationActivationStatus> {
    return this.http.post<IntegrationActivationStatus>(`${environment.apiUrl}/call4u/activate`, [])
  }
  
}

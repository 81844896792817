import { Injectable } from '@angular/core';
import { Month } from 'src/app/models/Month';
import { environment } from './../../../environments/environment';
import { UserService } from 'src/app/shared/services/user.service'
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UtilService {

  private exportLeadsSubject = new BehaviorSubject<boolean>(false);
  exportLeads$ = this.exportLeadsSubject.asObservable();

  constructor(private userService: UserService) { }
  
  getExportLeads(userId: string): void {
    this.userService.findById(userId).subscribe(user => {
      this.exportLeadsSubject.next(user.leads_export);
    });
  }

  setExportLeads(userId: string, exportLeads: boolean): void {
    this.userService.findById(userId).subscribe(user => {
      user.leads_export = exportLeads;      
      this.exportLeadsSubject.next(exportLeads);
    })  
  };
  
  listYearsApp(): number[] {
    const yearList = [];

    for (let year = environment.firstYear; year <= this.getYear(); year++) {
      yearList.push(year);
    }

    return yearList;
  }

  listMonths(): Month[] {
    const result: Month[] = [];
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    months.forEach((month, index) => {
      result.push({
        number: index + 1,
        name: month
      })
    });
    return result;
  }

  getDate(): Date {
    return new Date();
  }

  getYear(): number {
    return this.getDate().getFullYear();
  }

  getMonth(): number {
    return this.getDate().getMonth() + 1;
  }

  validateUrl(url: string): boolean {
    const urlPattern = /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
    return !url || urlPattern.test(url);
  }

}

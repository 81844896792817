import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { InputCompareValidator } from './../../shared/validators/input-compare.validator';

import { UserService } from './../../shared/services/user.service';
import { ToastService } from './../../shared/components/toast/toast.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  hash: string;
  isLoading: boolean;
  passwordResetForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.passwordResetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(8)], InputCompareValidator.compare('password')]
    });

    this.passwordResetForm
      .get('password')
      .valueChanges
      .subscribe(() => this.passwordResetForm.patchValue({ password_confirmation: '' }));

    this.route
      .params
      .subscribe(params => {
        this.hash = params.hash;

        this.userService.verifyPasswordHash(this.hash)
          .subscribe(result => {
            if (result.expirated) {
              this.toastService.info('Solicitação expirada. Faça o processo de solicitação novamente!');
              this.router.navigate(['/password-reset']);
            }
          });
      });
  }

  changePassword(): void {
    this.isLoading = true;
    const password = this.passwordResetForm.get('password').value;

    this.userService
      .setNewPassword(password, this.hash)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(() => {
        this.toastService.success('Senha alterada com sucesso!');
        this.router.navigate(['/']);
      });
  }

}

import { AbstractControl } from '@angular/forms';
import { map, first } from 'rxjs/operators';
import { of } from 'rxjs';

import { IdentificationType } from './../../../shared/services/mercado-pago.service';

const invalidDocument = (type: string, value: string): boolean => {
  value = value.replace(/\D/g, '');

  const size = value.length;

  if (size < 11){
    return true;
  }
  type = size == 11 ? 'CPF' : 'CNPJ';

  if (value.length < size) {
    return true;
  }

  let invalid = [];
  for (let i = 0; i < size; i++) {
    invalid.push('0');
  }

  for (let i = 0; i <= 10; i++) {
    if (value === invalid.join('')) {
      return true;
    }
    invalid = invalid.fill(i);
  }

  if (type === 'CPF') {
    let totalSum = 0;
    let dv = 0;
    for (let i = 1; i <= 9; i++) {
      totalSum += +value.substring(i - 1, i) * (11 - i);
    }

    dv = (totalSum * 10) % 11;

    if (dv === 10 || dv === 11) {
      dv = 0;
    }

    totalSum = 0;
    for (let i = 1; i <= 10; i++) {
      totalSum += +value.substring(i - 1, i) * (12 - i);
    }

    dv = (totalSum * 10) % 11;

    if (dv === 10 || dv === 11) {
      dv = 0;
    }

    const digit = +value.substring(10, 11);

    return dv !== digit;
  } else {
    let baseSize = value.length - 2;
    let numbers = value.substring(0, baseSize);
    const digits = value.substring(baseSize);
    let totalSum = 0;
    let position = baseSize - 7;
    for (let i = baseSize; i >= 1; i--) {
      totalSum += +numbers.charAt(baseSize - i) * position--;
      if (position < 2) {
        position = 9;
      }
    }

    let result = totalSum % 11 < 2 ? 0 : 11 - totalSum % 11;
    if (result !== +digits.charAt(0)) {
      return true;
    }

    baseSize++;
    numbers = value.substring(0, baseSize);
    totalSum = 0;
    position = baseSize - 7;
    for (let i = baseSize; i >= 1; i--) {
      totalSum += +numbers.charAt(baseSize - i) * position--;
      if (position < 2) {
        position = 9;
      }
    }

    result = totalSum % 11 < 2 ? 0 : 11 - totalSum % 11;
    if (result !== +digits.charAt(1)) {
      return true;
    }
  }

  return false;
};

export class RechargeValidators {

  static documentValidator(): any {

    return (control: AbstractControl) => {
      if (control.parent && control.value) {
        const identificationType = control.parent.get('identification_type')?.value as IdentificationType;
        return control
          .valueChanges
          .pipe(
            map(
              value => (
                invalidDocument(identificationType?.id || 'CPF', value)
                || (value?.length < identificationType?.min_length) ?
            
                { documentvalidator: true } : null)))
          .pipe(first());
      }

      return of();
    };
  }

}

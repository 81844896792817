import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

import { PaginatedResult } from './../model/paginated-result';
import { Control } from './../../models/Control';
import {UserCounter} from '../../models/UserCounter';

@Injectable({ providedIn: 'root' })
export class SmsService {
  constructor(private http: HttpClient) { }

  listMessagesPaginated(date: string, page: number, perPage: number): Observable<PaginatedResult<Control[]>> {
    return this.http.get<PaginatedResult<Control[]>>(`${environment.apiUrl}/messages?date=${date}&page=${page}&per_page=${perPage}`);
  }

  listMessagesByMonthAndyear(month: number, year: number): Observable<UserCounter[]> {
    return this.http.get<UserCounter[]>(`${environment.apiUrl}/messages-report?month=${month}&year=${year}`);
  }
}

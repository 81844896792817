<app-page-title *ngIf="administrator" icon="fa-newspaper-o" title="Configurações do emissor fiscal">
    <button class="btn btn-primary"
            (click)="save()">
      Salvar
    </button>
</app-page-title>

<div *ngIf="administrator" class="d-flex flex-row flex-wrap justify-content-center align-items col-md-12">
  <div class="d-flex flex-row flex-wrap justify-content-evenly align-items-center col-md-12 col-sm-8">

    <div class="d-flex flex-column justify-content-center align-items-center col-md-4">
      <div class="col-md-12" style="margin-bottom: 10px;">
        <label>Custo do envio de SMS</label>
        <input type="number" class="form-control" min="0.00" step="0.01" [(ngModel)]="costs"/>
      </div>
      <div class="col-md-12">
        <label for="description">Descrição</label>
        <textarea class="form-control col-md-12" #desc style="min-height: 400px;" [value]="description" [(ngModel)]="description"></textarea>
      </div>
      <div class="col-md-12 m-4">
        <p>Variáveis disponíveis:</p>
        <span class="badge bg-primary mx-1" (click)="addTag('total_disparos')">{{'{total_disparos}'}}</span>
        <span class="badge bg-primary mx-1" (click)="addTag('valor_total')">{{'{valor_total}'}}</span>
        <span class="badge bg-primary mx-1" (click)="addTag('custo_sms')">{{'{custo_sms}'}}</span>
      </div>
    </div>

    <div class="table col-md-7 col-sm-12">
      <div class="rounded overflow-scroll" style="width: 100% !important;">
        <form class="search_form" (submit)="searchUsers()">
            <input type="text"
                  class="col-md-10 stext"
                  placeholder="Pesquisar..."
                  [(ngModel)]="userQuery"
                  name="search"/>
            <label class="col-md-2 text-white sbtn">
              <input hidden type="submit"/>
              Buscar
            </label>
        </form>
        <div class="col-md-12 col-sm-12 overflow-scroll" style="max-height: 30rem;">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="background-color: #2F3348;">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="toggleAllRows($event)" value="all"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            [checked]="selectedUsers.includes(row.id)"
                            [value]="row.id"
                            (change)="toggleRow($event)"
                            >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Telefone </th>
            <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
          </ng-container>

          <ng-container matColumnDef="profile_url">
            <th mat-header-cell *matHeaderCellDef> Perfil </th>
            <td mat-cell *matCellDef="let element"> <a [href]="element.profile_url"><i class="fa fa-user"></i></a> </td>
          </ng-container>

          <tbody>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                (click)="selection.toggle(row)">
            </tr>
          </tbody>
        </table>
        </div>
      </div>
      <app-pagination
      *ngIf="result"
      [currentPage]="result.current_page"
      [itemsPerPage]="result.per_page"
      [totalItems]="result.total"
      (pageChanged)="pageChanged($event)"
      ></app-pagination>
    </div>


  </div>
</div>

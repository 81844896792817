<section [ngClass]="{ 'small-device': isSmallDevice }">
  <app-menu></app-menu>
  <main>
    <app-header></app-header>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
  <a
    href="{{ supportLink }}?text=Olá, meu nome é {{
      user.name
    }} e estou com uma dúvida!"
    target="_blank"
  >
    <img
      class="whatsapp"
      src="/assets/whatsapp.png"
      ngbTooltip="Fale conosco"
    />
  </a>
</section>

import { Directive, HostListener, EventEmitter, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appCpf]',
  outputs: ['valueChanged']
})
export class CpfDirective implements OnInit {

  valueChanged = new EventEmitter<string>();

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.changeCPF(event);
  }

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    this.changeCPF(event);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    this.changeCPF(event);
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.setAttribute(elementRef.nativeElement, 'maxlength', '14');
  }

  ngOnInit(): void {
    this.changeCPF(this.elementRef.nativeElement.value);
  }

  changeCPF(event: any): void {

    let document = '';

    if (!event) {
      return;
    }

    if (typeof(event) === 'string') {
      document = event.replace(/\D/g, '');
    } else {
      const events = ['Backspace', 'Tab', 'Delete'];
      const value = event.target.value;

      if (events.indexOf(event.code) > -1) {
        this.valueChanged.emit(value);
        return;
      }

      document = value?.replace(/\D/g, '');
      if (!document) {
        this.valueChanged.emit('');
        this.elementRef.nativeElement.value = '';
        return;
      }
    }

    let formattedDocument = '';
    for (const digit of document) {
      formattedDocument += digit;
      if ([3, 7].includes(formattedDocument.length)) {
        formattedDocument += '.';
      } else if (formattedDocument.length === 11) {
        formattedDocument += '-';
      }
    }

    this.elementRef.nativeElement.value = formattedDocument;

    this.valueChanged.emit(formattedDocument);
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilService} from '../../shared/services/util.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ShortenerService} from '../../shared/services/shortener.service';
import {SiteInformation} from '../../models/SiteInformation';
import {Subscription} from 'rxjs';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService} from '../../shared/components/confirmation-modal/confirmation.service';
import {ToastService} from '../../shared/components/toast/toast.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-support-form',
  templateUrl: './short-url-analysis.component.html',
  styleUrls: ['./short-url-analysis.component.scss']
})
export class ShortUrlAnalysisComponent implements OnDestroy, OnInit {

  site: string;
  modalRef: NgbModalRef;
  verificationSite?: SafeUrl;
  validate = false;

  siteInformation?: SiteInformation;
  subscriptions: Subscription[] = [];
  constructor(
    private utilService: UtilService,
    private shortenerService: ShortenerService,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.validate = environment.title !== 'DEV';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  check(): void {
    this.clear(false);
    if (!this.site) {
      return;
    }

    if (!this.validate || this.utilService.validateUrl(this.site)) {
      this.subscriptions.push(
        this.shortenerService
          .findByShortUrl(this.site)
          .subscribe({
            next: site => {
              this.siteInformation = site;
              this.verificationSite = this.domSanitizer.bypassSecurityTrustResourceUrl(site.long_url);
            },
            error: err => {
              if (err.status === 404) {
                this.toastService.error('Site não encontrado!');
              }
            }
          })
        );
    } else {
      this.toastService.error('URL Inválida!');
    }
  }

  openSiteModal(content: any): void {
    this.modalRef = this.modalService.open(content, { size: 'lg'});
  }
  clear(clearSite: boolean = true): void {
    if (clearSite) {
      this.site = null;
    }
    this.siteInformation = null;
    this.verificationSite = null;
  }

  deleteConfirmation(): void {
    this.confirmationService
      .show(`Deseja realmente excluir a URL curta ${this.siteInformation.link}, que redireciona para o site ${this.siteInformation.long_url})?`,
        () => {
          this.subscriptions.push(
            this.shortenerService
              .delete(this.site)
              .subscribe({
                next: () => {
                  this.clear();

                  if (this.modalRef) {
                    this.modalRef.close();
                    this.modalRef = null;
                  }

                  this.toastService.success('Site excluído com sucesso!');
                },
                error: err => {
                  this.toastService.error('Erro ao excluir site.');
                }
              })
          );
        });
  }
}

<ng-template #modalConfirmacao let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Confirmação</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" [innerHtml]="message"></div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close('YES')">Sim</button>
      <button type="button" class="btn btn-secondary" (click)="modal.close('NO')">Não</button>
    </div>
  </div>
</ng-template>

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/models/User';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';

import { ToastService } from './../../../shared/components/toast/toast.service';
import { PartnerService } from './../../../shared/services/partner.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit, OnDestroy {

  @Input() user: User;
  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;

  page = 1;
  perPage = 10;
  clientId = "";
  filter = "";
  subscriptions: Subscription[] = [];
  isAdding: boolean;
  users$: Observable<User[]>;
  clients$: Observable<PaginatedResult<User[]>>;

  constructor(
    private userService: UserService,
    private toast: ToastService,
    private partnerService: PartnerService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.listClients();

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.page = +params.page || 1;
        this.perPage = +params.perPage || this.perPage;
        this.listClients();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listClients() {
    this.clients$ = this.partnerService.listClients(this.user.id, this.page, this.perPage);
  }

  keypress(event: KeyboardEvent): void {
    if (event.key.toLowerCase() === 'enter') {
      this.search();
    }

    if (!this.filter) {
      this.users$ = null;
    }
  }

  search(): void {
    this.users$ = this.userService.listWithoutReference(this.user.id, this.filter);
  }

  showAddForm(): void {
    this.isAdding = true;
    this.filter = '';
    this.users$ = null;
    setTimeout(() => this.filterInput.nativeElement.focus(), 200);
  }

  add(client: User) {
    this.subscriptions.push(
      this.partnerService.addClient(this.user.id, client.id)
        .subscribe({
          next: () => {
            this.toast.success('Cliente adicionado com sucesso!');
            this.isAdding = false;
            this.listClients();
          },
          error: () => this.toast.error('Erro ao cadastrar cliente.')
        })
    );
  }

  delete(user: User) {
    this.subscriptions.push(
      this.partnerService.removeClient(this.user.id, user.id)
        .subscribe({
          next: () => {
            this.toast.success('Cliente excluído com sucesso!');
            this.listClients();
          },
          error: () => this.toast.error('Erro ao excluir cliente.')
        })
    )
  }

  pageChanged(page: number) {
    this.router.navigate([`/users/edit/${this.user.id}`], { queryParams: { page, perPage: this.perPage } });
  }
}

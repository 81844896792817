import { ProfilePipe } from './../../shared/pipes/profile.pipe';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/User';
import { Promotion } from 'src/app/models/Promotion';

import { UserFilter } from './../../models/UserFilter';
import { ConfirmationService } from './../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from './../../shared/components/toast/toast.service';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { UserService } from './../../shared/services/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { PromotionService } from '../../shared/services/promotion.service';
import { Subscription } from "rxjs";
import { Shortener } from 'src/app/models/Shortener';
import { ShortenerService } from 'src/app/shared/services/shortener.service';
import { ProviderAccountService } from 'src/app/shared/services/provider-account.service';
import { ProviderAccount } from 'src/app/models/ProviderAccount';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  page = 1;
  perPage = 15;
  filter: string = null;
  promocodeId: string = "";
  active: boolean;
  validated: boolean;
  promocode: boolean;
  activeUser: User;
  result: PaginatedResult<User[]>;
  promotions: Promotion[] = [];
  invertedOrder: boolean;
  orderBy: string;

  loggedInUser: User;
  subscriptions: Subscription[] = [];

  automationId = "";
  broadcastId = "";
  shortenerId = "";

  providers: ProviderAccount[] = [];
  shorteners: Shortener[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
    private profilePipe: ProfilePipe,
    private confirmationService: ConfirmationService,
    private promotionService: PromotionService,
    private shortenerService: ShortenerService,
    private providerService: ProviderAccountService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.promotionService
      .listAll()
      .subscribe(promotions => this.promotions = promotions);

    this.shortenerService
      .listAll()
      .subscribe(shorteners => this.shorteners = shorteners);

    this.providerService
      .listAll()
      .subscribe(providers => this.providers = providers);

    this.activatedRoute.queryParams.subscribe((params) => {
      this.perPage = +params?.per_page || this.perPage;
      this.page = +params?.page || this.page;
      this.filter = params.filter || this.filter;
      this.promocodeId = params.promocodeId || this.promocodeId;
      this.active = null;
      this.validated = null;
      this.promocode = null;
      this.orderBy = params.orderBy || this.orderBy;
      this.automationId = params.automation_id ?? "";
      this.broadcastId = params.broadcast_id || this.broadcastId;
      this.shortenerId = params.shortener_id || this.shortenerId;

      if (params.active) {
        this.active = JSON.parse(params.active);
      }

      if (params.validated) {
        this.validated = JSON.parse(params.validated);
      }

      if (params.promocode) {
        this.promocode = JSON.parse(params.promocode);
      }

      this.listPaginated();
      this.activeUser = this.authService.getUser();
    });

    this.loggedInUser = this.authService.getLoggedInUser();
  }
    

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listPaginated(): void {
    this.invertedOrder = this.route.queryParams['_value']['invertedOrder'];

    if (this.invertedOrder) {
      this.invertedOrder = JSON.parse(this.invertedOrder.toString())
    }

    this.orderBy = this.route.queryParams['_value']['orderBy'];

    let order = this.orderBy;

    let inverted = this.invertedOrder;

    const filter: UserFilter = {
      active: this.active,
      validated: this.validated,
      promocode: this.promocode,
      text: this.filter,
      promocodeId: this.promocodeId,
      automationId: this.automationId,
      broadcastId: this.broadcastId,
      shortenerId: this.shortenerId
    };

    this.subscriptions.push(

      this.userService
      .listPaginated(this.page, this.perPage, filter, order, inverted)
      .subscribe((result) => (this.result = result))
    );
  }

  filterByAutomation(): void {
    this.pageChanged(this.page);
  }

  filterByBroadcast(): void {

    this.pageChanged(this.page);
  }

  filterByShortener(): void {
    this.pageChanged(this.page);
  }

  filterKeyPress(event: KeyboardEvent): void {

    const key = event?.key?.toLocaleUpperCase();
    if (key === 'ENTER') {
      this.pageChanged(this.page);
    }
  }

  filterByStatus(active: boolean = null, promocode: boolean = null, validated: boolean = null): void {
    
    this.page = 1;
    this.active = active;
    this.validated = validated;
    this.promocode = promocode;

    this.pageChanged(this.page);
  }

  getTitle(user: User): string {
    let title = user.name;

    if (user.validated) {
      title = `[Verificado] ${title}`;
    }

    if (user.promocode) {
      title += ` (Promocode: ${user.promocode})`;
    }

    title += ' (Perfil: ' + this.profilePipe.transform(user.profile_id) + ')';

    return title;
  }

  simulate(user: User): void {
    this.authService.setSimulatedUser(user);
    this.router.navigate(['/']);
  }

  invertOrder() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        invertedOrder: !this.invertedOrder
      },
      queryParamsHandling: 'merge'
    })
  };

  orderByChanged() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        orderBy: this.orderBy
      },
      queryParamsHandling: 'merge'
    })
  }

  validate(user: User, validate: boolean): void {
    const action = validate ? 'validar' : 'remover a validação de';
    const confirmationMessage = `Deseja realmente ${action} o usuário <b>${user.name}</b> para enviar SMSs?`;
    const successMessage = validate ? 'Usuário validado com sucesso. Agora ele pode enviar SMS' : 'Validação removida com sucesso. O usuário não pode mais enviar SMS';
  
    this.confirmationService.show(confirmationMessage, () => {
      if (validate) {
        user.massive_sms_enabled = true;
      }

      this.subscriptions.push(
        this.userService
          .validate(user.id)
          .subscribe(
            () => {
              if (validate) {
                this.userService.save(user).subscribe(
                  (savedUser) => {
                    user = savedUser;
                    this.toastService.success(successMessage);
                    this.listPaginated();
                  },
                  (err) => this.toastService.error(err?.error?.message || `Erro ao ${action} usuário!`)
                );
              } else {
                this.toastService.success(successMessage);
                this.listPaginated();
              }
            },
            err => this.toastService.error(err.error.message || `Erro ao ${action} usuário!`)
          )
      );
    });
  }

  updateCounters(user: User): void {
    this.subscriptions.push(
      this.userService
        .updateCounters(user.id)
        .subscribe(() => {
          this.toastService.success(`Os contadores do usuário ${user.name} serão atualizados em breve!`)
        },
          () => this.toastService.error('error.message'))
    );
  } 
  
  pageChanged(page: any): void {
    const queryParams = {
      page,
      perPage: this.perPage,
      active: this.active,
      validated: this.validated,
      promocode: this.promocode,
      filter: this.filter,
      promocodeId: this.promocodeId,
      orderBy: this.orderBy,
      invertedOrder: this.invertedOrder,
      automation_id: this.automationId,
      broadcast_id: this.broadcastId,
      shortener_id: this.shortenerId
    };

    if (!this.filter) {
      delete queryParams.filter;
    }

    this.router.navigate(['/users'], { queryParams });
  }
}

import { Injectable } from '@angular/core';

import { ToastService } from './../components/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class CopyService {

  constructor(private toastService: ToastService) { }

  copy(id: string, message: string = null): void {
      const el =  document.getElementById(id) as HTMLInputElement;
      const maxRange = el.value?.length || 99999;

      el.select();
      el.setSelectionRange(0, maxRange);

      document.execCommand('copy');

      if (message) {
        this.toastService.info(message);
      }
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent {

  @Input() id: string;
  @Input() name = '';
  @Input() class = '';
  @Input() color = false;
  @Input() label = '';
  @Input() tooltip = '';
  @Input() checked: boolean;
  @Input() display = 'inline-block';
  @Output() checkedChange = new EventEmitter<boolean>();

  toggleChange(): void {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}

<main>
  <div class="card">
    <div class="card-body">
      <div class="logo">
        <img
          src="/assets/logo-smsfunnel-login.png"
          alt="SMS Funnel"
          title="SMS Funnel"
        />
      </div>
      <form [formGroup]="signUpFrom" (submit)="signUp()" #formCadastro="ngForm">
        <div class="form-group">
          <label for="name">Nome Completo</label>
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            id="name"
            name="name"
            formControlName="name"
          />
          <small
            class="text text-danger"
            *ngIf="
              signUpFrom.get('name').touched &&
              signUpFrom.get('name').errors?.required
            "
            >Nome Completo é obrigatório</small
          >
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            autocomplete="off"
            type="email"
            class="form-control"
            id="email"
            name="email"
            formControlName="email"
          />
          <small
            class="text text-danger"
            *ngIf="
              signUpFrom.get('email').touched &&
              signUpFrom.get('email').errors?.required
            "
            >E-mail é obrigatório</small
          >
          <small class="text text-danger" *ngIf="emailExists"
            >E-mail já cadastrado</small
          >
          <small
            class="text text-danger"
            *ngIf="signUpFrom.get('email').errors?.email"
            >E-mail inválido</small
          >
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="phone">Telefone</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                formControlName="phone"
                appPhone
                (valueChanged)="phoneChanged($event)"
              />
              <small
                class="text text-danger"
                *ngIf="
                  signUpFrom.get('phone').touched &&
                  signUpFrom.get('phone').errors?.required
                "
                >Telefone é obrigatório</small
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="document">CPF</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="document"
                name="document"
                formControlName="document"
                appCpf
                (valueChanged)="documentChanged($event)"
              />
              <small class="text text-danger" *ngIf="documentExists"
                >CPF já cadastrado</small
              >
              <small
                class="text text-danger"
                *ngIf="
                  signUpFrom.get('document').touched &&
                  signUpFrom.get('document').errors?.required
                "
                >CPF é obrigatório</small
              >
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="operation">Tipo de operação</label>
              <div>
                <select class="form-control" id="operation_type" formControlName="operation_type" >
                  <option *ngFor="let operationType of operationTypes" [value]="operationType.value">{{ operationType.label }}</option>
                </select>
              </div>
    
              <small *ngIf="signUpFrom.get('operation_type').errors?.required" class="text-danger">
                Preencha com o tipo de operação
              </small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="postcode">CEP</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="postcode"
                name="postcode"
                formControlName="postcode"
                appCep
                (valueChanged)="cepChanged($event)"
              />
              <small
                class="text text-danger"
                *ngIf="
                  signUpFrom.get('postcode').touched &&
                  signUpFrom.get('postcode').errors?.required
                "
                >CEP é obrigatório</small
              >
            </div>
          </div>
        </div>
        <div class="row" *ngIf="address">
          <div class="col-md-6">
            <div class="form-group">
              <label for="street">Endereço</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="street"
                name="street"
                formControlName="street"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="number">Número</label>
              <input
                autocomplete="off"
                type="number"
                class="form-control"
                id="number"
                name="number"
                formControlName="number"
              />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="address">
          <div class="col-md-6">
            <div class="form-group">
              <label for="complement">Complemento</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="complement"
                name="complement"
                formControlName="complement"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="district">Bairro</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="district"
                name="district"
                formControlName="district"
              />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="address">
          <div class="col-md-6">
            <div class="form-group">
              <label for="city">Cidade</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="city"
                name="city"
                formControlName="city"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="region">Estado</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="region"
                name="region"
                formControlName="region"
              />
            </div>
          </div>
        </div>

        <div class="form-group">
          <button
            type="submit"
            class="btn btn-block btn-primary"
            [disabled]="!formCadastro.valid || emailExists || documentExists"
          >
            Cadastrar
          </button>
        </div>
        <div class="form-group text-center">
          <a [routerLink]="['/login']" ngbTooltip="Se já tem usuário, clique aqui!" placement="right">
            <i class="fa fa-chevron-right"></i>
            Entrar
          </a>
        </div>
      </form>
    </div>
  </div>
</main>

<app-page-title icon="fa-rocket" title="Integrações">
  <button class="btn btn-primary btn-icon-right" (click)="add()">
    Nova integração
    <i class="fa fa-plus-circle"></i>
  </button>
</app-page-title>

<div class="card">
  <p><span>ATENÇÃO:</span> Ao excluir uma integração você irá excluir <b>TODAS</b> as listas vinculadas. Caso queira
    manter as listas, apenas desative a integração.</p>
</div>

<div class="mt-3">
  <div class="row mb-2">
    <div class="col">
      <button class="btn me-2 btn-primary" (click)="filterByStatus()">
        <i class="fa fa-check me-1" *ngIf="active === null"></i>
        <span>Todas</span>
      </button>
      <button class="btn me-2 btn-info" (click)="filterByStatus(true)">
        <i class="fa fa-check me-1" *ngIf="active === true"></i>
        <span>Ativas</span>
      </button>
      <button class="btn me-2 btn-secondary" (click)="filterByStatus(false)">
        <i class="fa fa-check me-1" *ngIf="active === false"></i>
        <span>Inativas</span>
      </button>
    </div>
  </div>
  <hr>
</div>

<app-integration [hidden]="hideAdd" (toggleHidden)="hideAdd = !hideAdd" (saved)="saved($event)"
  [integration]="selectedIntegration"></app-integration>

<div *ngIf="result && result.data.length > 0; else notIntegrations">
  <div class="row title">
    <b class="col-md-7">Integração</b>
    <b class="col-md-3 text-center">Plataforma</b>
  </div>
  <div class="card gradient-row" *ngFor="let integration of result.data"
    [class]="integration.active ? 'primary' : 'danger'">

    <div class="row">
      <div class="col-md-7">
        {{integration.name}}
      </div>
      <div class="col-md-3 text-center">
        {{integration.platform.name}}
      </div>
      <div class="col">
        <div class="icon-group">
          <app-hidden-field [name]="'url-' + integration.id" [value]="integration.url"></app-hidden-field>

          <app-switcher [id]="integration.id" [checked]="integration.active" (change)="toggleActive(integration)">
          </app-switcher>

          <p class="btn btn-icon btn-primary ms-1" ngbTooltip="Editar integração" (click)="edit(integration)">
            <i class="fa fa-pencil"></i>
          </p>

          <button class="btn btn-icon btn-danger ms-1" ngbTooltip="Excluir integração"
            (click)="confirmDelete(integration)">
            <i class="fa fa-times"></i>
          </button>

          <button class="btn btn-icon btn-primary ms-1" ngbTooltip="Visualizar postbacks"
            [routerLink]="[integration.id, 'postbacks']">
            <i class="fa fa-search"></i>
          </button>

          <button class="btn btn-icon btn-primary ms-1" ngbTooltip="Criar listas" (click)="createLists(integration)">
            <i class="fa fa-file-archive-o"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7" (click)="copy(integration)">
        <small class="me-2"><b>URL:</b> {{integration.url}}</small>

        <button class="btn btn-icon-right btn-add-webhook" ngbTooltip="Copiar URL de integração" *ngIf="integration.url">
          <i class="fa fa-files-o"></i>
          Copiar URL
        </button>
      </div>
      <div class="col-md-3 text-center">
        <button class="btn btn-icon-right btn-add-webhook" *ngIf="integration.platform.name === 'CARTPANDA'"
                ngbTooltip="Clique para incluir a configuração de webhook na sua loja" (click)="createWebhooks(integration)">
          <i class="fa fa-plus"></i>
          Adicionar webhooks
        </button>
      </div>
    </div>
  </div>
</div>

<app-pagination *ngIf="result" [currentPage]="result.current_page" [itemsPerPage]="result.per_page"
  [totalItems]="result.total" (pageChanged)="pageChanged($event)"></app-pagination>

<ng-template #notIntegrations>
  <div class="card mt-2">
    Nenhuma integração cadastrada
  </div>
</ng-template>

<ng-template #modalCartpanda let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmação</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>Para incluir os webhooks, informe o seu token:</p>
    <div class="form-group">
      <label for="leads">Slug da Loja</label>
      <input type="text" class="form-control" [(ngModel)]="storeId" />
    </div>
    <div class="form-group">
      <label for="leads">Token</label>
      <input type="text" class="form-control" [(ngModel)]="storeToken" />
    </div>
    <small>Caso tenha alguma dúvida, clique <a
        href="https://dev.cartpanda.com/docs/api-v3/ZG9jOjExMDcyMQ-authentication" target="_blank">aqui</a></small>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="!storeToken" (click)="modal.close('YES')">Criar</button>
    <button type="button" class="btn btn-secondary" (click)="modal.close('NO')">Cancelar</button>
  </div>
</ng-template>

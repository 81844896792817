import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  transform(value: string): string {
    if (value === 'paid') {
      return 'Pago';
    }

    if (value === 'pending') {
      return 'Pendente'
    }

    return null;
  }

}

import { Component, Input, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';
import { User } from '../../../models/User';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastService } from '../../../shared/components/toast/toast.service';

@Component({
  selector: 'app-modal-pix-key',
  templateUrl: './modal-pix-key.component.html',
  styleUrls: ['./modal-pix-key.component.scss']
})
export class ModalPixKeyComponent implements OnInit, OnDestroy {

  @Input() showModal = new Subject<string>();
  @Output() modalClosed = new EventEmitter<any>();
  @ViewChild('modalPixKey') modal: any;
  modalRef: NgbModalRef;

  user: User;
  changing = false;
  pixKey: string;

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.showModal.subscribe((pixKey) => {
      this.changing = (pixKey ? true : false);
      this.pixKey = pixKey;
      this.modalRef = this.modalService.open(this.modal);
      this.modalRef.result.then(res => this.modalClosed.emit(res))
    })
  }

  ngOnDestroy(): void {
    this.showModal.unsubscribe();
  }

  checkKey(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'enter') {
      this.save();
    }
  }

  save() {
    if (this.pixKey && this.pixKey.trim() !== '') {
      this.user.pix_key = this.pixKey;
      this.userService.save(this.user).subscribe(() => {
        this.toastService.success('Chave pix cadastrada com sucesso!');

        if (this.authService.getSimulatedUser()) {
          this.authService.setSimulatedUser(this.user);
        } else {
          this.authService.setUser(this.user);
        }

        this.modalRef.close('YES');
      });
    } else {
      this.toastService.error('Preencha a chave PIX!');
    }
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/User';

import { UserCreditInfo } from './../../models/UserCreditInfo';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { RechargesService } from './../../shared/services/recharges.service';
import { ADMINISTRATOR, CUSTOMER_SUCCESS, UserService } from './../../shared/services/user.service';
import { AuthService } from 'src/app/shared/services/auth.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {

  page = 1;
  perPage = 50;
  totalCreditsInfo: UserCreditInfo;
  result: PaginatedResult<User[]>;
  billing: number;
  signatureBilling: number;
  rechargeBilling: number;
  billingMonthStart: string;
  billingMonthEnd: string;
  billingMonths: any[] = [];
  subscriptions: Subscription[] = [];

  user: User;

  startDate: Date;
  endDate: Date;

  get customer_success(): boolean {
    return this.user.profile_id === CUSTOMER_SUCCESS;
  }

  get administrator() {
    return this.user.profile_id === ADMINISTRATOR;
  }

  get billingMonthsBr() {
    return this.billingMonths?.map(bm => {
      bm.month_year = bm
        .year_month
        .split('-')
        .reverse()
        .join('/');

      return bm;
    });
  }

 constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private rechargesService: RechargesService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();

    if (!this.administrator) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (!params.page) {
          this.pageChanged(1, true);
          return;
        }

        this.page = +params.page;
        this.perPage = +params.per_page || this.perPage;
        this.billingMonthStart = params.billing_month_start ?? "";
        this.billingMonthEnd = params.billing_month_end ?? ""
        this.listPaginated();
      })
    );
    this.subscriptions.push(
      this.rechargesService.billingMonths().subscribe(billingMonths => this.billingMonths = billingMonths)
    );

    this.startDate = this.billingMonthStart ? new Date(this.billingMonthStart) : null
    this.endDate = this.billingMonthEnd ? new Date(this.billingMonthEnd) : null
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  listPaginated(): void {
    let credits = 0;
    let creditsBilling = 0;
    let signatureBilling = 0;
    let totalReserved = 0;

    this.subscriptions.push(
      this.userService.listCreditsPaginated(this.page, this.perPage, [this.billingMonthStart, this.billingMonthEnd]).subscribe(result => {
        this.result = result;
        this.result.data.forEach(user => {
          if (user.credit_info.credits) {
            credits += user.credit_info.credits;
            creditsBilling += user.credit_info.credits_billing;
            signatureBilling += user.credit_info.signature_billing;
            totalReserved += user.credit_info.total_reserved;
          }
        });

        this.totalCreditsInfo = {
          credits,
          credits_billing: creditsBilling,
          signature_billing: signatureBilling,
          total_sent: 0,
          total_reserved: totalReserved,
          total_contracted: creditsBilling + signatureBilling
        };
      })
    );

    this.subscriptions.push(
      this.rechargesService.totalBilling([this.billingMonthStart, this.billingMonthEnd]).subscribe(t => {
        this.rechargeBilling = t.credits_billing;
        this.signatureBilling = t.signature_billing;
        this.billing = this.rechargeBilling + this.signatureBilling;
      })
    );
  }

  pageChanged(page: number, force? : boolean): void {
    const queryParams = { page, per_page: this.perPage };

    if(force) {
      this.router.navigate(['/reports'], { queryParams });
    }

    if (this.startDate) {
      queryParams['billing_month_start'] = this.startDate.toISOString().split('T')[0];
    }
    if (this.endDate) {
      queryParams['billing_month_end'] = this.endDate.toISOString().split('T')[0];
    }

    if (this.endDate >= this.startDate || !this.startDate && this.endDate || !this.endDate && this.startDate) {
      this.router.navigate(['/reports'], { queryParams });
    }
  }

  download(): void {
    this.rechargesService.download( [this.billingMonthStart, this.billingMonthEnd] );
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BroadcastWhatsapp } from 'src/app/models/BroadcastWhatsapp';
import { BroadcastContact } from 'src/app/models/BroadcastContact';
import { BroadcastsWhatsappService } from 'src/app/shared/services/broadcasts-whatsapp.service';

import { IMPORTING, SCHEDULED } from '../../../models/BroadcastStatus';
import { PaginatedResult } from './../../../shared/model/paginated-result';
import { CopyService } from './../../../shared/services/copy.service';

@Component({
  selector: 'app-view-broadcast-whatsapp',
  templateUrl: './view-broadcast-whatsapp.component.html',
  styleUrls: ['./view-broadcast-whatsapp.component.scss']
})
export class ViewBroadcastWhatsappComponent implements OnInit, OnDestroy {

  page = 1;
  perPage = 8;
  broadcastId: string;
  broadcast: BroadcastWhatsapp;
  contacts: PaginatedResult<BroadcastContact[]>;

  subscriptions: Subscription[] = [];

  get isScheduled() {
    return this.broadcast.broadcast_whatsapp_status_id === SCHEDULED;
  }

  get isImporting() {
    return this.broadcast.broadcast_whatsapp_status_id === IMPORTING;
  }

  get status() {
    return this.broadcast.status?.status ?? '-';
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private copyService: CopyService,
    private broadcastsWhatsappService: BroadcastsWhatsappService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.broadcastId = params.id;
        this.findById();
        this.listContacts();
      })
    );

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        const page = params.page || 1;
        this.page = page;
        this.listContacts();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  findById() {
    this.subscriptions.push(
      this.broadcastsWhatsappService.findById(this.broadcastId).subscribe(
        broadcast => {
          console.log('broadcast', broadcast);
          this.broadcast = broadcast
        },
        () => this.router.navigate(['/broadcasts'])
      )
    );
  }

  listContacts(): void {
    this.subscriptions.push(
      this.broadcastsWhatsappService
        .listContacts(this.broadcastId, this.page, this.perPage)
        .subscribe(contacts => this.contacts = contacts)
    );
  }

  getStatus(contact: BroadcastContact): string {
    if (contact.sent_date && !contact.cancelled) {
      return 'Enviado';
    }

    if (contact.cancelled) {
      return 'Cancelado';
    }

    return 'Pendente';
  }

  pageChanged(page: any): void {
    this.router
      .navigate(['/broadcasts-whatsapp/view', this.broadcast.id], { queryParams: { page } });
  }

  copyUrl(): void {
    this.copyService.copy('url', 'URL copiada com sucesso!');
  }

  copyShortUrl(): void {
    this.copyService.copy('short_url', 'URL Curta copiada com sucesso!');
  }

}

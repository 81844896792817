import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { ToastService } from '../components/toast/toast.service';
import { LoadingService } from '../components/loading/loading.service';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class UserInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    if (this.authService.isLogged()) {
      const user = this.authService.getUser();
      let newUrl = req.url + (req.url.includes('?') ? '&' : '?') + 't=' + Date.now();

      if (this.authService.isSimulating) {
        newUrl += `&simulate=${user.id}`;
      }

      newUrl += `&global=${user.globalActivated}`;

      if (!environment.production) {
        newUrl += '&XDEBUG_SESSION=docker'
      }

      req = req.clone({
        url: newUrl
      });
    }

    return next.handle(req);
  }
}

import { Component, OnInit } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';

export type ChartOptions = {
  colors: string[];
  series: ApexAxisChartSeries;
  chart: ApexChart;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  grid: ApexGrid;
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  fill: any;
};

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class AnalyticsSalesComponent implements OnInit {
  salesChartOptions: Partial<ChartOptions>;
  sourceChartOptions: Partial<ChartOptions>;

  ngOnInit(): void {
    this.showSalesChartOptions();
    this.showSourceChartOptions();
  }

  showSalesChartOptions(): void {
    this.salesChartOptions = {
      colors: ['#00BE9A'],
      series: [{
        data: [9350.10, 12350.10, 14350.00, 15350.00]
    }],
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        foreColor: '#00BE9A',
      },
      dataLabels: {
        enabled: true,
      },
      grid: {
        show: false,
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      tooltip: {
        enabled: false,
      },
      labels: [],
      legend: {
        horizontalAlign: 'left',
      },
      fill: {
        opacity: 0.3,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          opacityFrom: 0.7,
          opacityTo: 0.3,
        },
      },
    };
  }

  showSourceChartOptions(): void {
    this.sourceChartOptions = {
      colors: ['#67679B','#00BE9A'],
        series: [{
          name: 'Qtd. Vendas',
          data: [100, 125, 175, 185]
        },{
          name: 'R$ Vendas',
          data: [999.10, 1025, 1350, 1550.3]
        }],
      chart: {
        type: 'bar',
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        foreColor: '#FFFFFF',
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        type: 'category',
        categories: ['camp_facebook', 'camp_google', 'camp_instagram', 'camp_youtube'],
      },
      tooltip: {
        enabled: false,
      },
      labels: [],
      legend: {
        horizontalAlign: 'left',
      },
      fill: {
        opacity: 0.3,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          opacityFrom: 0.7,
          opacityTo: 0.3,
        },
      },
    };
  }
}

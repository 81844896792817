import { ListsService } from './../../shared/services/lists.service';
import { LeadList } from './../../models/LeadList';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './../../shared/services/auth.service';
import { ConfirmationService } from './../../shared/components/confirmation-modal/confirmation.service';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ToastService } from './../../shared/components/toast/toast.service';
import { CampaignService } from './../../shared/services/campaigns.service';
import { Campaign } from './../../models/Campaign';
import { CampaignFilter } from 'src/app/models/CampaignFilter';
import { Integration } from 'src/app/models/Integration';
import { IntegrationsService } from 'src/app/shared/services/integrations.service';

@Component({
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit, OnDestroy {

  name = '';
  page = 1;
  pageSize = 10;
  active: boolean;
  result: PaginatedResult<Campaign[]>;

  lists$: Observable<LeadList[]>;
  subscriptions: Subscription[] = [];

  integrationId: string = "";
  integrations: Integration[];

  listId: string = "";
  lists: LeadList[];

  copyName = '';
  copyListId = '';
  campaign: Campaign;

  @ViewChild('modalCampaign') modal: NgbModalRef;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private listsService: ListsService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private campaignService: CampaignService,
    private confirmationService: ConfirmationService,
    private integrationService: IntegrationsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(params => {
        this.page = +params.page || 1;
        this.name = params.name || this.name;
        this.active = null;

        if (params.active) {
          this.active = JSON.parse(params.active);
        }

        this.listPaginated();
      }),

      this.authService.userChanged.subscribe(() => this.listPaginated())
    )
    this.integrationService.listAll().subscribe(integrations => this.integrations = integrations)
    this.listsService.listAll().subscribe(lists => this.lists = lists)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  listPaginated(): void {
    const filter: CampaignFilter = {
      name: this.name,
      integration: this.integrationId,
      list: this.listId,
      active: this.active
    }

    this.subscriptions.push(
      this.campaignService.listPaginated(this.page, this.pageSize, filter).subscribe(result => {
        this.result = result;
        if (result.last_page < this.page) {
          this.pageChanged(result.last_page);
        }
      })
    );
  }

  filterKeyPress(event: KeyboardEvent): void {
    const key = event?.key?.toLocaleUpperCase();
    if (key === 'ENTER' || !this.name) {
      this.pageChanged(this.page);
    }
  }

  filterByStatus(active: boolean = null) {
    this.active = active;
    this.pageChanged(this.page);
  }

  toggleActive(campaign: Campaign): void {
    campaign.active = !campaign.active;

    const successMessage = campaign.active
      ? 'Automação ativada com sucesso!'
      : 'Automação desativada com sucesso!';

    this.subscriptions.push(
      this.campaignService.save(campaign).subscribe(() => this.toastService.success(successMessage))
    )
  }

  pageChanged(page: any): void {
    let queryParams = {
      page,
      per_page: this.pageSize,
      name: this.name,
      active: this.active,
      integration: this.integrationId,
      list: this.listId
    };

    if (!this.name) {
      delete queryParams.name;
    }

    if (this.active === null) {
      delete queryParams.active;
    }

    if (this.integrationId === null || this.integrationId === '') {
      delete queryParams.integration
    }

    if (this.listId === null || this.listId === '') {
      delete queryParams.list
    }

    this.router.navigate(['/campaigns'], { queryParams });
  }

  confirmDelete(campaign: Campaign): void {
    this.confirmationService.show(`Deseja realmente excluir a automação [${campaign.name}]?`, () => this.delete(campaign.id));
  }

  delete(id: string): void {
    this.subscriptions.push(
      this.campaignService.delete(id).subscribe(
        () => {
          this.listPaginated();
          this.toastService.success('Automação excluída com sucesso!');
        },
        () => this.toastService.error('Erro ao excluir automação. Tente novamente mais tarde!')
      )
    );
  }

  showCopy(campaign: Campaign) {
    this.copyName = `Cópia de ${campaign.name}`;
    this.copyListId = '';
    this.lists$ = this.listsService.listAll();
    this.modalService.open(this.modal);
    this.campaign = campaign;
  }

  copy(modal: any): void {
    if (!this.copyName && !this.copyListId) {
      this.toastService.error('Preencha o nome e selecione a lista');
      return;
    }

    if (!this.copyName) {
      this.toastService.error('Preencha o nome');
      return;
    }

    if (!this.copyListId) {
      this.toastService.error('Selecione o a lista');
      return;
    }

    this.subscriptions.push(
      this.campaignService.copy(this.campaign.id, this.copyName, this.copyListId)
        .subscribe({
          next: () => {
            this.toastService.success('Automação copiada com sucesso!');
            this.listPaginated();
            modal.close();
          },
          error: err => {
            this.toastService.error(err.error.message);
          }
        })
    );
  }

}

import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { User } from 'src/app/models/User';

import { environment } from './../../../environments/environment.dev';
import { Affiliate } from './../../models/Affiliate';
import { PartnerInfo } from './../../models/PartnerInfo';
import { ToastService } from './../../shared/components/toast/toast.service';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { AuthService } from './../../shared/services/auth.service';
import { ADMINISTRATOR, CUSTOMER_SUCCESS, UserService } from './../../shared/services/user.service';
import { AGENCY_CLIENT } from '../../shared/services/user.service';
import { AgencyFilter } from 'src/app/models/AgencyFilter';

@Component({
  selector: 'app-partner-dashboard',
  templateUrl: './partner-dashboard.component.html',
  styleUrls: ['./partner-dashboard.component.scss']
})
export class PartnerDashboardComponent implements OnInit {
  page = 1;
  filter = '';
  perPage = 10;

  user: User;
  active = true;
  showModal = new Subject<string>();
  partnerInfo$: Observable<PartnerInfo>;
  affiliates$: Observable<PaginatedResult<Affiliate[]>>;

  get affiliateUrl(): string {
    return `${environment.signatureUrl}?src=${this.user.affiliate_code}`;
  }

  get isAgency(): boolean {
    return this.user.profile_id === AGENCY_CLIENT;
  }

  get customer_success(): boolean {
    return this.user.profile_id === CUSTOMER_SUCCESS;
  }

  get administrator() {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.update();
    this.authService.userChanged.subscribe(() => this.update());
  }

  ngOnInit(): void {
    if (this.customer_success) {
      this.router.navigate(['/dashboard']);
    }
  }

  update() {
    this.user = this.authService.getUser();
    this.partnerInfo$ = this.userService.getPartnerDashboard();

    this.route
      .queryParams
      .subscribe(params => {
        this.page = params.page || this.page;
        if (params.status) {
          this.active = JSON.parse(params.status);
        }
        this.perPage = params.perPage || this.perPage;
        this.filter = params.filter || this.filter;

        if (this.perPage > 25) {
          this.pageChanged(this.page, 25);
        }

        this.listPaginated();
      });
  }

  listPaginated() {
    const filter: AgencyFilter = {
      text: this.filter,
      status: this.active
    }

    this.affiliates$ = this.userService
      .findAffiliates(this.page, this.perPage, filter);
  }

  showActives() {
    this.active = true;
    this.pageChanged(this.page);
  }

  showInactives() {
    this.active = false;
    this.pageChanged(this.page);
  }

  copy(url: string): void {
    navigator.clipboard.writeText(url);
    this.toastService.success('URL copiada com sucesso!');
  }

  copyEmail(user: Affiliate): void {
    navigator.clipboard.writeText(user.email);
    this.toastService.success('E-mail copiado com sucesso!');
  }

  pageChanged(page: any, perPage: number = null): void {
    let queryParams = {
      page,
      perPage: perPage || this.perPage,
      filter: this.filter,
      status: this.active
    }

    if (!this.filter) {
      delete queryParams.filter;
    }

    this.router.navigate(['/partner'], { queryParams });
  }

  simulate(affiliate: Affiliate): void {
    this.userService.findById(affiliate.id)
      .subscribe(user => {
        this.authService.setSimulatedUser(user);
        this.router.navigate(['/']);
      });
  }

  showPixForm() {
    this.showModal.next(this.user.pix_key);
  }

  deletePixKey() {
    this.user.pix_key = null;
    this.userService.save(this.user).subscribe(() => {
      this.toastService.success('Chave pix excluída com sucesso!');

      if (this.authService.getSimulatedUser()) {
        this.authService.setSimulatedUser(this.user);
      } else {
        this.authService.setUser(this.user);
      }
    });
  }

  modalClosed(event: any) {
    if (event === 'YES') {
      this.update();
    }
  }
}

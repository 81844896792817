import { Directive, OnInit, EventEmitter, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCep]',
  outputs: ['valueChanged']
})
export class CepDirective implements OnInit  {

  valueChanged = new EventEmitter<string>();

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.changeCEP(event);
  }

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    this.changeCEP(event);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    this.changeCEP(event);
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.setAttribute(elementRef.nativeElement, 'maxlength', '9');
  }

  ngOnInit(): void {
    this.changeCEP(this.elementRef.nativeElement.value);
  }

  changeCEP(event: any): void {
    let CEP = '';

    if (typeof(event) === 'string') {
      CEP = event?.replace(/\D/g, '');
    } else {
      const events = ['Backspace', 'Tab', 'Delete'];

      if (events.includes(event.code)) {
        return;
      }

      CEP = event.target.value?.replace(/\D/g, '');
    }

    if (!CEP) {
      this.elementRef.nativeElement.value = '';
      this.valueChanged.emit('');
      return;
    }

    let formattedCEP = '';
    for (const unitCEP of CEP) {
      formattedCEP += unitCEP;
      if (formattedCEP.length === 5) {
        formattedCEP += '-';
      }
    }

    this.elementRef.nativeElement.value = formattedCEP;

    this.valueChanged.emit(formattedCEP);
  }

}

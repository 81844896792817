<h1 class="page-title">
  <div class="left">
    <span *ngIf="icon"><i class="fa {{icon}}"></i></span>
    <span *ngIf="image"><img [src]="image"/></span>
    <p>{{title}}</p>
  </div>
  <div class="right">
    <ng-content></ng-content>
  </div>
</h1>

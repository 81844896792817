<app-page-title title="Pagamentos realizados" icon="fa-bank">
  <button class="btn btn-secondary" routerLink="/partner-management">
    Voltar
  </button>
</app-page-title>

<div class="card" *ngIf="agency$ | async as agency">
  <h2>{{ agency.name }}</h2>
  <table *ngIf="payments$ | async as payments">
    <thead>
      <tr>
        <th>Período</th>
        <th>Data Pagamento</th>
        <th>Tipo</th>
        <th>Valor</th>
        <th>Observação</th>
      </tr>
    </thead>
    <tbody *ngIf="payments.data.length > 0">
      <tr *ngFor="let payment of payments.data">
        <td>{{ payment.user_commission.period | period }}</td>
        <td>{{ payment.payment_date | date : "dd/MM/yyyy" }}</td>
        <td>{{ payment.type }}</td>
        <td>{{ payment.amount | numberFormat : "R$ " : true : 2 }}</td>
        <td>{{ payment.description }}</td>
        <td>
          <button
            class="btn btn-danger"
            (click)="confirmDelete(payment)"
            *ngIf="payment.type !== 'sms'"
          >
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr *ngIf="payments.data.length === 0">
        <td>Nenhum pagamento realizado =(</td>
      </tr>
    </tfoot>
  </table>
</div>

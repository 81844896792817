<app-page-title icon="fa-pie-chart" title="Detalhamento da automação">
  <a href="/#/analytics/campaigns" class="btn btn-danger" title="Voltar para página anterior">Voltar</a>
</app-page-title>

<div>
  <div class="row mb-2">
    <div class="col-12 col-sm-12">
      <div class="form-group">
        <label>Filtros rápidos</label>
        <div class="mb-2 btn-group-filter">
          <button (click)="filterByDate('today')" class="btn btn-primary" [ngClass]="(filter == 'today' || filter == null) ? 'active' : ''">Hoje</button>
          <button (click)="filterByDate('yesterday')" class="btn btn-primary" [ngClass]="(filter == 'yesterday') ? 'active' : ''">Ontem</button>
          <button (click)="filterByDate('-7 days')" class="btn btn-primary" [ngClass]="(filter == '-7 days') ? 'active' : ''">7 dias</button>
          <button (click)="filterByDate('-15 days')" class="btn btn-primary" [ngClass]="(filter == '-15 days') ? 'active' : ''">15 dias</button>
          <button (click)="filterByDate('this month')" class="btn btn-primary" [ngClass]="(filter == 'this month') ? 'active' : ''">Mês atual</button>
          <button (click)="filterByDate('last month')" class="btn btn-primary" [ngClass]="(filter == 'last month') ? 'active' : ''">Mês Anterior</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="">De</label>
            <input type="date" class="form-control" [(ngModel)]="fromAt">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="">Até</label>
            <input type="date" class="form-control" [(ngModel)]="toAt">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4 align-self-end">
      <button class="btn btn-primary w-100" (click)="filterBy()">Aplicar filtros</button>
    </div>
  </div>
</div>

<div class="mt-5">
  <div class="d-flex align-items-center justify-content-between my-3">
    <h3 class="my-0">Cliques da automação</h3>
  </div>
  <div class="row">
    <div class="col-sm-4 col-12">
      <app-analytics-card title="Envios" highlightText="{{ totalSent > 0 ? (totalSent | number)?.replace(',', '.') : '0'}}"></app-analytics-card>
    </div>
    <div class="col-sm-4 col-12">
      <app-analytics-card title="Cliques" highlightText="{{ totalClicks > 0 ? (totalClicks | number)?.replace(',', '.') : '0'}}"></app-analytics-card>
    </div>
    <div class="col-sm-4 col-12">
      <app-analytics-card title="CTR" highlightText="{{ (totalClicks && totalSent) ? ((totalClicks/totalSent) | percent:'1.0-2')?.replace('.', ',') : '0' }}"></app-analytics-card>
    </div>
  </div>
</div>

<div class="mt-5">
  <div class="d-flex align-items-center justify-content-between my-3">
    <h3 class="my-0">Cliques por sequência</h3>
  </div>
  <table class="table" *ngIf="sequences?.data.length > 0; else notFound">
    <thead>
      <tr>
        <th>Tempo</th>
        <th>Envios</th>
        <th>Cliques</th>
        <th>CTR</th>
        <th>Mensagem</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let sequence of sequences?.data">
        <td>{{ sequence.interval }} {{ sequence.interval_type.name }}</td>
        <td>{{ sequence.analytics?.qty_sent > 0 ? (sequence.analytics.qty_sent | number)?.replace(',', '.') : '0' }}</td>
        <td>{{ sequence.analytics?.qty_clicks > 0 ? (sequence.analytics.qty_clicks | number)?.replace(',', '.') : '0' }}</td>
        <td>{{ sequence.analytics?.qty_clicks > 0 && sequence.analytics.qty_clicks > 0 ? ((sequence.analytics.qty_clicks/sequence.analytics.qty_sent) | percent:'1.0-2')?.replace('.', ',') : '0' }}</td>
        <td width="40%">{{sequence.text}}</td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #notFound>
  <div class="card">Nenhuma informação disponível para os filtros aplicados.</div>
</ng-template>

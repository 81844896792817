import { AuthService } from './../../shared/services/auth.service';
import { User } from './../../models/User';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

import { MenuService } from './menu/menu.service';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  
  get supportLink() {
    return environment.supportLink;
  }

  user: User;

  isSmallDevice = true;

  constructor(
    private menuService: MenuService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.menuService
      .getIsSmallDevice()
      .subscribe(isSmallDevice => setTimeout(() => this.isSmallDevice = isSmallDevice, 200));

    this.user = this.authService.getUser();
  }

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { MenuService } from '../../pages/layout/menu/menu.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Input() totalItems: number;
  @Input() maxPages = 7;

  @Output() pageChanged = new EventEmitter<number>();

  pages: number[] = [];
  lastPage: number;
  isSmallDevice: boolean;

  startRegistry: number;
  endRegistry: number;

  constructor(
    private menuService: MenuService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalItems) {
      this.createPages();
    }
  }

  ngOnInit(): void {
    if (this.menuService.isSmallDevice) {
      this.maxPages = 3;
      this.isSmallDevice = true;
    }

    if (this.currentPage
      && this.itemsPerPage
      && this.totalItems) {
      this.createPages();
    }
  }

  createPages(): void {
    this.startRegistry = 1;
    this.endRegistry = 1;
    if (this.totalItems < this.itemsPerPage) {
      this.endRegistry = this.totalItems;
    }

    this.lastPage = Math.ceil(this.totalItems / this.itemsPerPage);

    let start = 1;
    let end = this.lastPage;
    const middle = Math.round(this.maxPages / 2) + 1;

    if (this.lastPage > this.maxPages) {
      if (this.currentPage > middle - 1) {
        const quarter = Math.floor(middle / 2);
        start = this.currentPage - quarter;
        end = this.currentPage + quarter + 1;
        if (end - start + 1 > this.maxPages) {
          end -= 1;
        }
        if (end - start + 1 < this.maxPages) {
          start -= 1;
        }
        if (end > this.lastPage) {
          end = this.lastPage;
        }
      } else {
        end = this.maxPages;
      }
    }

    this.pages = [];
    for (let i = start; i <= end; i++) {
      this.pages.push(i);
    }

    this.setRegister();
  }

  selectPage(page: number): void {
    if (page === this.currentPage) { return; }

    this.currentPage = page;
    this.pageChanged.emit(page);
    this.createPages();
  }

  setRegister(): void {
    this.startRegistry = 1 + (this.currentPage - 1) * this.itemsPerPage;
    if (this.currentPage === this.lastPage) {
      this.endRegistry = this.totalItems;
    } else {
      this.endRegistry = this.startRegistry + this.itemsPerPage - 1;
    }
  }

}

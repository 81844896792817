export const CLIENT = 'b88f5d71-cd4d-4ae5-9b4b-0c0880793a54';
export const LIST = '9d25191e-5c69-427a-9c3a-3a6f378a1215';
export const NUMBER = '8c2b290f-1ff9-40f2-a3d9-1763567314c0';

const EnumBroadcastTypeId = {
  client: CLIENT,
  list: LIST,
  number: NUMBER
}

export interface BroadcastType {
  id: string;
  name: string;
}

export const getBroadcastTypeId = (broadcastType: string): string => {
  return EnumBroadcastTypeId[broadcastType] || null;
}

export const getBroadcastTypeName = (id: string): string => {
  if (id === CLIENT) {
    return 'client';
  }

  if (id === LIST) {
    return 'list';
  }

  return 'number';
}


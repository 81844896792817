import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Agency } from 'src/app/models/Agency';
import { AgencyFilter } from 'src/app/models/AgencyFilter';
import { UserPayment } from 'src/app/models/UserPayment';
import { environment } from 'src/environments/environment';

import { PaginatedResult } from '../model/paginated-result';
import { Payment } from './../../models/Payment';

export const PENDING = 'pending';
export const PAID = 'paid';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  constructor(
    private http: HttpClient
  ) { }

  findById(id: string): Observable<Agency> {
    return this.http.get<Agency>(`${environment.apiUrl}/agency/${id}`);
  }

  savePayment(agency: Agency, payment: Payment) {
    return this.http.post(`${environment.apiUrl}/agency/${agency.id}`, payment);
  }

  listAgencies(page: number = 1, pageSize: number = 10, filter: AgencyFilter = null, order: string = 'name', inverted: boolean | number = false): Observable<PaginatedResult<Agency[]>> {
    let url = `${environment.apiUrl}/agency?page=${page}&per_page=${pageSize}`;
    if (filter) {
      url += '&filter=' + JSON.stringify(filter);
    }
    if (order) {
      url += `&order=${order}`;
    }

    url += `&inverted=${inverted}`;
    return this.http.get<PaginatedResult<Agency[]>>(url);
  }

  listPeriods(id: string) {
    return this.http.get(`${environment.apiUrl}/agency/${id}/periods`);
  }

  listPayments(agencyId: string) {
    return this.http.get<PaginatedResult<UserPayment[]>>(`${environment.apiUrl}/agency/${agencyId}/payments`);
  }

  deletePayment(id: number) {
    return this.http.delete(`${environment.apiUrl}/agency/payments/${id}`);
  }
}

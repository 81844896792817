import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoadingService {

  private isLoading = new Subject<boolean>();

  getLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  start(): void {
    this.isLoading.next(true);
  }

  stop(): void {
    this.isLoading.next(false);
  }
}

import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ac-activate-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class ACPopupComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSequenceAC', {static: true}) modalSequenceAC: ElementRef<any>;
  @Input() onClose: CallableFunction;
  @Input() integrationId: string;
  modalRef: any

  constructor(
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.modalRef = this.modalService.open(this.modalSequenceAC, {size: 'lg'})
    this.modalRef.result.then((result) => {},
    (reason) => {
      if (this.onClose) {
        this.onClose();
      };
    })
  }

  closeAll(): void {
    this.modalService.dismissAll();
  };

  ngAfterViewInit(): void {}
}

<div class="accordion-card">
  <div class="title {{ background }}"
    [class.is-open]="!isHidden"
  >
    <p class="title"
      (click)="clickTitle(collapse)"
    >{{ title }}</p>

    <div class="title-icons">
      <a
        *ngFor="let icon of titleIcons"
        [ngbTooltip]="icon.tooltip"
        class="btn btn-icon btn-primary me-1 {{ icon.color }}"
        (click)="clickIcon(icon)"
      >
        <i class="fa {{ icon.icon }}"></i>
        {{ icon.text }}
      </a>
    </div>

    <span
      *ngIf="toggle"
      (click)="clickTitle(collapse)"
    >
      <i class="fa fa-chevron-right"
        [class.is-open]="!isHidden"></i>
    </span>

  </div>
  <div class="body"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isHidden">
    <ng-content></ng-content>
  </div>
</div>

<main>
  <div class="card">
    <div class="card-body">
      <div class="logo">
        <img
          src="/assets/logo-smsfunnel-login.png"
          alt="SMS Funnel"
          title="SMS Funnel"
        />
      </div>
      <form [formGroup]="passwordResetForm" (submit)="changePassword()">
        <div class="form-group">
          <label for="password">Nova senha</label>
          <input
            type="password"
            class="form-control"
            formControlName="password"
          />
          <span *ngIf="passwordResetForm.get('password').touched">
            <small
              class="text text-danger"
              *ngIf="passwordResetForm.get('password').errors?.required"
            >
              Nova senha é obrigatória
            </small>
            <small
              class="text text-danger"
              *ngIf="passwordResetForm.get('password').errors?.minlength"
            >
              A nova senha deve ter no mínimo 8 caracteres
            </small>
          </span>
        </div>

        <div class="form-group">
          <label for="password_confirmation">Confirmação da nova senha</label>
          <input
            type="password"
            class="form-control"
            formControlName="password_confirmation"
          />
          <span *ngIf="passwordResetForm.get('password_confirmation').touched">
            <small
              class="text text-danger"
              *ngIf="
                passwordResetForm.get('password_confirmation').errors?.required
              "
            >
              Confirmação da nova senha é obrigatória
            </small>
            <small
              class="text text-danger"
              *ngIf="
                passwordResetForm.get('password_confirmation').errors?.minlength
              "
            >
              A nova senha deve ter no mínimo 8 caracteres
            </small>
            <small
              class="text text-danger"
              *ngIf="
                passwordResetForm.get('password_confirmation').errors
                  ?.different
              "
            >
              Nova senha não foi confirmada corretamente
            </small>
          </span>
        </div>

        <button class="btn btn-primary btn-block" [disabled]="passwordResetForm.invalid">
          Alterar
        </button>
      </form>
    </div>
  </div>
</main>

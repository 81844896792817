import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SCHEDULED, SENT } from '../../models/BroadcastStatus';
import { User } from '../../models/User';
import { ConfirmationService } from '../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { AuthService } from '../../shared/services/auth.service';
import { ADMINISTRATOR } from '../../shared/services/user.service';
import { BroadcastWhatsapp } from './../../models/BroadcastWhatsapp';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { BroadcastsWhatsappService } from './../../shared/services/broadcasts-whatsapp.service';
import { UserCreditInfo } from "../../models/UserCreditInfo";
import { DashboardService } from "../../shared/services/dashboard.service";

@Component({
  selector: 'app-broadcasts-whatsapp',
  templateUrl: './broadcasts-whatsapp.component.html',
  styleUrls: ['./broadcasts-whatsapp.component.scss']
})
export class BroadcastsWhatsappComponent implements OnInit, OnDestroy {

  pageTitle: string = 'Envio de whatsapp em massa';

  text: string;
  perPage = 10;
  startedMonth: Date;
  endedMonth: Date;
  startDate: string;
  endDate: string;
  invalidTime: boolean;
  orderBy = 'created_at';
  invertedOrder = true;

  page: number;
  pageSize = 10;
  user: User;
  loading: boolean;
  result: PaginatedResult<BroadcastWhatsapp[]>;
  subscriptions: Subscription[] = [];

  statisticsToggled: boolean = false;
  statisticsClass: string = 'appear';

  statisticsTotal = 0;
  statisticsTotalClass = '';
  statisticsEstimated = 0;

  statisticsStartDate: Date;
  statisticsEndDate: Date;
  statisticsFailed: boolean = false;

  userCreditInfo: UserCreditInfo;

  showBroadcastsStatistics: boolean = false;

  get administrator() {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private broadcastsWhatsappService: BroadcastsWhatsappService,
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.page = params.page || this.page;
        this.pageSize = params.page_size || this.pageSize;
        this.startDate = params.started_To_Date ?? '';
        this.endDate = params.ended_To_Date ?? '';
        this.text = params.text || '';
        this.orderBy = params.orderBy || this.orderBy; 
        this.invertedOrder = params.invertedOrder === 'true' || this.invertedOrder;
        this.listPaginated();
      }),

      this.authService.userChanged.subscribe(() => this.listPaginated())
    );

    this.subscriptions.push(
      this.dashboardService.userCreditsInfo()
        .subscribe(userCreditInfo => this.userCreditInfo = userCreditInfo)
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getTitle(broadcast: BroadcastWhatsapp) {
    let title = broadcast.name;

    title += ` - ${broadcast.type?.name} `

    if (this.isImporting(broadcast)) {
      title = '[importando...] ' + title;
    }
    else if (this.isCancelled(broadcast)) {
      title = '[Cancelado] ' + title;
    }

    title += `(${broadcast.leads_count})`;

    return title;
  }

  isScheduled(broadcast: BroadcastWhatsapp): boolean {
    return broadcast.broadcast_whatsapp_status_id === SCHEDULED;
  }

  isHiddenButton(broadcast: BroadcastWhatsapp): boolean {
    return broadcast.broadcast_whatsapp_status_id !== SENT;
  }

  isImporting(broadcast: BroadcastWhatsapp): boolean {
    return this.broadcastsWhatsappService.isImporting(broadcast);
  }

  isCancelled(broadcast: BroadcastWhatsapp): boolean {
    return this.broadcastsWhatsappService.isCancelled(broadcast);
  }

  listPaginated(startDate = null, endDate = null): void {
    this.user = this.authService.getUser();

    this.subscriptions.push(
      this.broadcastsWhatsappService.importFinished.subscribe(() => this.listPaginated())
    );

    if (!startDate && this.startedMonth) {
      startDate = this.startedMonth.toISOString().split('T')[0];
    }

    if (!endDate && this.endedMonth) {
      endDate = this.endedMonth.toISOString().split('T')[0];
    }

    if (this.user && !this.user.validated && !this.administrator) return;

    this.loading = true;

    this.subscriptions.push(
      this.broadcastsWhatsappService.listPaginated(this.page, this.pageSize, startDate, endDate, this.text, this.orderBy, this.invertedOrder)
        .pipe(finalize(() => this.loading = false))
        .subscribe(result => {
          this.result = result;
        })
    )
  }

  pageChanged(page: any): void {
   const queryParams = {  page, per_page: this.perPage };

    if (this.text) {
      queryParams['text'] = this.text;
    }
    
    if (this.startedMonth) {
      queryParams['start_date'] = this.startedMonth.toISOString().split('T')[0];
    }

    if (this.endedMonth) {
      queryParams['end_date'] = this.endedMonth.toISOString().split('T')[0];
    }

    if (this.orderBy) {
      queryParams['orderBy'] = this.orderBy;
    }

    if (this.invertedOrder) {
      queryParams['invertedOrder'] = this.invertedOrder;
    }

    if (this.startDate) {
      queryParams['started_To_Date'] = this.startDate;
    }

    if (this.endDate >= this.startDate || !this.startDate && this.endDate || !this.endDate && this.startDate) {
      this.invalidTime = false;
      this.router.navigate(['/broadcasts-whatsapp'], { queryParams });
    } else {
      this.invalidTime = true;
    }
  }

  confirmDelete(broadcast: BroadcastWhatsapp): void {
    this.confirmationService
      .show(`Deseja realmente excluir o broadcast ${broadcast.name}?`, () => this.delete(broadcast.id));
  }

  confirmCancel(broadcast: BroadcastWhatsapp) {
    this.confirmationService
      .show(`Deseja realmente cancelar o broadcast ${broadcast.name}?`, () => this.cancel(broadcast.id));
  }

  delete(id: string): void {
    this.subscriptions.push(
      this.broadcastsWhatsappService.delete(id).subscribe(() => {
        this.toastService.success('Broadcast Whatsapp excluído com sucesso!');
        this.listPaginated();
      })
    );
  }

  cancel(id: string) {
    this.subscriptions.push(
      this.broadcastsWhatsappService.cancel(id).subscribe(() => {
        this.toastService.success('Broadcast Whatsapp cancelado com sucesso!');
        this.listPaginated();
      })
    );
  }

  toggleStats() {
    this.statisticsClass = this.statisticsToggled ? 'hide' : 'appear';
    if (this.statisticsClass === 'hide') {
      setTimeout(() => {
        this.statisticsToggled = !this.statisticsToggled
      }, 210);
    }else{
      this.statisticsToggled = !this.statisticsToggled;
    }
  }

  changeStatsTotal() {
    this.statisticsTotal = 0;
    this.statisticsTotalClass = 'pulse';
    const increaseNumber = setInterval(() => {
      if (this.statisticsTotal >= this.statisticsEstimated) {
        this.statisticsTotal = this.statisticsEstimated;
        this.statisticsTotalClass = 'boop';
        return clearInterval(increaseNumber);
      }
      if (this.statisticsEstimated <= 100){
        this.statisticsTotal++;
      }
      else{
        this.statisticsTotal += Number.parseInt((this.statisticsEstimated / 15).toFixed());
      }
    }, 50)
  }

  getTotal() {
    if(this.statisticsStartDate && this.statisticsEndDate && this.statisticsStartDate > this.statisticsEndDate) {
      this.statisticsEstimated = 0;
      this.statisticsTotal = 0;
      return;
    }

    if (this.showBroadcastsStatistics) {
      this.listPaginated(this.statisticsStartDate ? this.statisticsStartDate.toISOString() : null, this.statisticsEndDate ? this.statisticsEndDate.toISOString() : null);
    }else {
      this.listPaginated();
    }

    this.broadcastsWhatsappService.getTotalSent(this.statisticsStartDate ? this.statisticsStartDate.toISOString() : null, this.statisticsEndDate ? this.statisticsEndDate.toISOString() : null).subscribe((r) => {
      if(r.total) {
        this.statisticsEstimated = r.total
        this.changeStatsTotal()
      }else{
        this.statisticsEstimated = 0
        this.statisticsTotal = 0
      }
    });
  }

  orderByChanged() {
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        orderBy: this.orderBy,
        startedMonth: this.startedMonth,
        endedMonth: this.endedMonth
      },
      queryParamsHandling: 'merge'
    })
  }
  
  invertOrder(): void {
    this.invertedOrder = !this.invertedOrder;
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        invertedOrder: this.invertedOrder,
        startedMonth: this.startedMonth,
        endedMonth: this.endedMonth
      },
      queryParamsHandling: 'merge'
    })
  }
}

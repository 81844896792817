import { User } from 'src/app/models/User';
import { AuthService } from './../../../shared/services/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { MenuService } from './../menu/menu.service';

import { Router } from '@angular/router';
import {
  CLIENT,
  ADMINISTRATOR,
  AGENCY_CLIENT,
  PARTNER_CLIENT,
  UserService,
} from '../../../shared/services/user.service';
import { Notification } from 'src/app/models/Notification';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { Subscription } from 'rxjs';
import { ListsService } from '../../../shared/services/lists.service';
import { BroadcastsService } from '../../../shared/services/broadcasts.service';
import { Call4UService } from 'src/app/shared/services/call4u.service';
import { IntegrationActivationStatus } from 'src/app/models/IntegrationActivated';

const NOTIFICATION_KEY = 'SMSFUNNEL::NOTIFICATIONS';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  toggleGlobalAccount() {
    this.authService.toggleGlobalAccount();
  }

  user: User;
  timer = null;
  updateNotificationsTimer = null;
  timerNotifications = null;

  isMenuVisible: boolean;
  isSmallDevice: boolean;
  showDropdown: boolean;
  showNotifications: boolean;

  usedCall4U: boolean;
  togglePopUp: boolean = false;

  notifications: Notification[] = [];

  subscriptions: Subscription[] = [];

  get selectedNotifications() {
    return this.notifications.filter((n) => n.checked);
  }

  get isClient(): boolean {
    return this.user.profile_id === CLIENT;
  }

  get isAffiliate(): boolean {
    return this.user.affiliation ? true : false;
  }

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private router: Router,
    private toast: ToastService,
    private userService: UserService,
    private listsService: ListsService,
    private broadcastsService: BroadcastsService,
    private Call4UService: Call4UService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.menuService
        .getMenuVisibility()
        .subscribe((isVisible) => (this.isMenuVisible = isVisible))
    );

    this.subscriptions.push(
      this.menuService
        .getIsSmallDevice()
        .subscribe((isSmallDevice) => (this.isSmallDevice = isSmallDevice))
    );

    this.user = this.authService.getUser();

    this.subscriptions.push(
      this.authService.userChanged.subscribe((user) => {
        this.user = user;
        this.listAlerts();
      })
    );

    this.subscriptions.push(
      this.listsService.importFinished.subscribe(() => this.listAlerts())
    );

    this.subscriptions.push(
      this.broadcastsService.importFinished.subscribe(() => this.listAlerts())
    );

    this.loadNotifications();
    this.listAlerts();

    this.Call4UService.isActivated().subscribe(
      (response: IntegrationActivationStatus) => {
        this.usedCall4U = response.status;
      }
    );

    if (this.updateNotificationsTimer) {
      clearInterval(this.updateNotificationsTimer);
    }

    this.updateNotificationsTimer = setInterval(() => {
      this.listAlerts();
    }, 30 * 1000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    if (this.updateNotificationsTimer) {
      clearInterval(this.updateNotificationsTimer);
    }
  }

  listAlerts() {
    if (this.authService.isLogged()) {
      this.subscriptions.push(
        this.userService
          .listAlerts()
          .subscribe((notifications) =>
            this.verifyNewNotifications(notifications)
          )
      );
    }
  }

  verifyNewNotifications(notifications: Notification[]) {
    const savedNotifications = localStorage.getItem(NOTIFICATION_KEY);
    if (savedNotifications) {
      const newNotifications = notifications.filter((n) => {
        const exist = this.notifications.find(
          (notification) => notification.id === n.id
        );
        return exist ? false : true;
      });

      // newNotifications.forEach(n => this.toast.success(n.message));
    }

    this.saveNotifications(notifications);
  }

  markAllRead() {
    this.notifications.forEach((n) => this.markAsRead(n));
  }

  saveNotifications(notifications: Notification[]) {
    this.notifications = notifications;
    localStorage.setItem(NOTIFICATION_KEY, JSON.stringify(notifications));
  }

  loadNotifications() {
    const savedNotifications = localStorage.getItem(NOTIFICATION_KEY);
    if (savedNotifications) {
      const notifications: Notification[] = JSON.parse(savedNotifications);
      this.notifications = notifications;
    }
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  toggleNotifications(over: boolean = false) {
    this.stopCloseNotifications();
    if (over) {
      this.showNotifications = true;
    } else {
      this.timerNotifications = setTimeout(
        () => (this.showNotifications = false),
        300
      );
    }
  }

  stopCloseNotifications() {
    if (this.timerNotifications) {
      clearTimeout(this.timerNotifications);
    }
  }

  markAsRead(notification: Notification) {
    this.subscriptions.push(
      this.userService.updateAlert(notification.id).subscribe({
        next: () => {
          this.notifications = this.notifications.filter(
            (n) => n.id !== notification.id
          );
          this.toggleNotifications(true);
          if (this.notifications.length === 0) {
            this.toast.success('Todas as notificações foram lidas!');
          }
          setTimeout(() => {
            this.toggleNotifications(false);
          }, 300);
        },
      })
    );
  }
  startCloseTime(): void {
    this.stopCloseTime();
    this.timer = setTimeout(() => {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    }, 300);
  }

  stopCloseTime(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  closeCall4U(activated: boolean): void {
    if (activated) {
      const call4uHeader = document.body.querySelector('#call4u-header');
      call4uHeader.remove();
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  backToLoggedInUser(): void {
    this.authService.setSimulatedUser(null);
    this.router.navigate(['/users']);
  }

  openPopup(): void {
    this.togglePopUp = !this.togglePopUp;
  }
}

<nav *ngIf="pages.length > 1; else noPages">
  <p>Exibindo registro {{startRegistry}} a {{endRegistry}} de {{totalItems}} registro(s).</p>
  <ul class="pagination">
    <li class="page-item" [class.disabled]="currentPage === 1">
      <a class="page-link primary" (click)="selectPage(1)">
        <span><i class="fa fa-step-backward"></i></span>
      </a>
    </li>
    <li class="page-item" [class.disabled]="currentPage === 1">
      <a class="page-link primary" (click)="selectPage(currentPage - 1)">
        <span><i class="fa fa-caret-left"></i></span>
      </a>
    </li>
    <li *ngFor="let page of pages" class="page-item page" [ngClass]="{ active: page === currentPage }">
      <a class="page-link" (click)="selectPage(page)">
        <span>{{ page }}</span>
      </a>
    </li>
    <li class="page-item" [class.disabled]="currentPage === lastPage">
      <a class="page-link primary" (click)="selectPage(currentPage + 1)">
        <span><i class="fa fa-caret-right"></i></span>
      </a>
    </li>
    <li class="page-item" [class.disabled]="currentPage === lastPage">
      <a class="page-link primary" (click)="selectPage(lastPage)">
        <span><i class="fa fa-step-forward"></i></span>
      </a>
    </li>
  </ul>
</nav>

<ng-template #noPages>
  <nav *ngIf="totalItems > 0">
    <p *ngIf="endRegistry > 1">Exibindo registros de {{startRegistry}} a {{endRegistry}}.</p>
    <p *ngIf="endRegistry == 1">Exibindo registro de {{startRegistry}} de {{endRegistry}}.</p>
  </nav>
</ng-template>

import { Postback } from './../../../models/Postback';
import { CopyService } from './../../../shared/services/copy.service';
import { Subscription } from 'rxjs';
import { Integration } from './../../../models/Integration';
import { ActivatedRoute } from '@angular/router';
import { IntegrationsService } from './../../../shared/services/integrations.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-postbacks',
  templateUrl: './postbacks.component.html',
  styleUrls: ['./postbacks.component.scss']
})
export class PostbacksComponent implements OnInit, OnDestroy {

  loading = true;
  integration: Integration;
  subs: Subscription[] = [];

  get postbacksCount() {
    return this.integration?.postbacks.length;
  }

  constructor(
    private integrationService: IntegrationsService,
    private copyService: CopyService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.subs.push(this.loadIntegration(params.id))
    }).unsubscribe();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  loadIntegration(id: string) {
    return this.integrationService.findById(id, true)
      .subscribe(integration => {
        integration.postbacks.forEach(postback => {
          if (typeof postback.data === 'string') {
            postback.data = JSON.parse(postback.data);
          }
        });

        this.integration = integration;
        this.loading = false;
      });
  }

  copy(id: number): void {
    this.copyService.copy(`postback-${id}`, 'Postback copiado com sucesso!');
  }

}

<div class="card elevated mt-4">
  <h2>
    {{ isAdding ? "Adicionar cliente" : "Clientes" }}
    <button
      class="btn btn-primary btn-icon-right"
      (click)="showAddForm()"
      *ngIf="!isAdding"
    >
      <i class="fa fa-plus"></i>
      Adicionar
    </button>
  </h2>

  <div *ngIf="isAdding">
    <div class="row mb-2">
      <div class="col">
        <div class="filter">
          <input
            type="text"
            #filterInput
            name="filter"
            id="filter"
            class="form-control"
            [(ngModel)]="filter"
            placeholder="Digite nome, telefone ou email para pesquisar"
            (keypress)="keypress($event)"
          />
          <button class="btn-primary btn" (click)="search()">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" *ngIf="users$ | async as users">
        <table class="table">
          <caption>
            {{
              users.length
            }}
            usuário(s) encontrado(s)
          </caption>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of users">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <button class="btn btn-primary" (click)="add(user)">
                  Adicionar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="isAdding = false"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!isAdding">
    <div *ngIf="clients$ | async as result">
      <div *ngIf="result.data.length > 0; else noClients">
        <div class="clients mb-2">
          <b>Nome</b>
          <b>E-mail</b>
          <b></b>
        </div>
        <div
          class="clients form-control mb-2"
          *ngFor="let client of result.data"
        >
          <span>{{ client.name }}</span>
          <span>{{ client.email }}</span>
          <span>
            <button class="btn btn-danger" (click)="delete(client)">
              <i class="fa fa-trash"></i>
            </button>
          </span>
        </div>

        <app-pagination
          *ngIf="result"
          [currentPage]="result.current_page"
          [itemsPerPage]="result.per_page"
          [totalItems]="result.total"
          (pageChanged)="pageChanged($event)"
        ></app-pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #noClients>
  <p *ngIf="!isAdding">Nenhum cliente.</p>
</ng-template>

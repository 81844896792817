<app-page-title icon="fa-file" title="Relatório analítico de vendas">
  <a href="/#/analytics" class="btn btn-danger" title="Voltar para página anterior">Voltar</a>
</app-page-title>

<div class="app-analytics">
  <div>
    <div class="row mb-2">
      <div class="col-12 col-sm-4">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="">De</label>
              <input type="date" class="form-control">
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="">Até</label>
              <input type="date" class="form-control">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8">
        <div class="form-group">
          <label>Filtros rápidos</label>
          <div class="mb-2">
            <button class="btn btn-primary">Hoje</button>
            <button class="btn btn-primary">Ontem</button>
            <button class="btn btn-primary">7 dias</button>
            <button class="btn btn-primary">15 dias</button>
            <button class="btn btn-primary">Mês atual</button>
            <button class="btn btn-primary">Mês Anterior</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row">
      <div class="col-12 col-sm-4 my-1">
        <app-analytics-card
          title="Recuperações"
          highlightText="R$ 36.590,00"
          tooltipText="Olá mundo!"
        >
          <div class="mt-2 badgeds">
            <span class="badge badge-info">100 conversões</span>
            <span class="badge badge-info">R$ 99,12 CPV</span>
            <span class="badge badge-info">1.219x ROAS</span>
          </div>
        </app-analytics-card>
      </div>
      <div class="col-12 col-sm-4 my-1">
        <app-analytics-card
          title="Boletos"
          highlightText="R$ 36.590,00"
          tooltipText="Olá mundo!"
        >
          <div class="mt-2 badgeds">
            <span class="badge badge-info">100 conversões</span>
            <span class="badge badge-info">R$ 99,12 CPV</span>
            <span class="badge badge-info">1.219x ROAS</span>
          </div>
        </app-analytics-card>
      </div>
      <div class="col-12 col-sm-4 my-1">
        <app-analytics-card
          title="Pix"
          highlightText="R$ 36.590,00"
          tooltipText="Olá mundo!"
        >
          <div class="mt-2 badgeds">
            <span class="badge badge-info">100 conversões</span>
            <span class="badge badge-info">R$ 99,12 CPV</span>
            <span class="badge badge-info">1.219x ROAS</span>
          </div>
        </app-analytics-card>
      </div>
      <div class="col-12 col-sm-4 my-1">
        <app-analytics-card
          title="Quantidade SMS Enviados"
          highlightText="300"
          tooltipText="Olá mundo!"
        >
          <div class="mt-2 badgeds">
            <span class="badge badge-info">R$ 30,00 custo</span>
          </div>
        </app-analytics-card>
      </div>
      <div class="col-12 col-sm-4 my-1">
        <app-analytics-card
          title="Quantidade SMS Enviados"
          highlightText="140"
          tooltipText="Olá mundo!"
        >
          <div class="mt-2 badgeds">
            <span class="badge badge-info">R$ 14,00 custo</span>
          </div>
        </app-analytics-card>
      </div>
      <div class="col-12 col-sm-4 my-1">
        <app-analytics-card
          title="Quantidade SMS Enviados"
          highlightText="245"
          tooltipText="Olá mundo!"
        >
          <div class="mt-2 badgeds">
            <span class="badge badge-info">R$ 24,50 custo</span>
          </div>
        </app-analytics-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3 class="mt-3 mb-2">Progressão de vendas no período</h3>
      </div>
      <div class="col-12 col-sm-8 my-1">
        <div class="card">
          <apx-chart
            *ngIf="salesChartOptions"
            style="width: 100%"
            [series]="salesChartOptions.series"
            [chart]="salesChartOptions.chart"
            [title]="salesChartOptions.title"
            [tooltip]="salesChartOptions.tooltip"
            [colors]="salesChartOptions.colors"
            [grid]="salesChartOptions.grid"
            [xaxis]="salesChartOptions.xaxis"
            [dataLabels]="salesChartOptions.dataLabels"
          >
          </apx-chart>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <app-analytics-card
          title="Total de vendas"
          highlightText="245"
          tooltipText="Olá mundo!"
          autoHeight="false"
        >
        </app-analytics-card>
        <div style="height:10px"></div>
        <app-analytics-card
          title="Quantidade SMS Enviados"
          highlightText="245"
          tooltipText="Olá mundo!"
          autoHeight="false"
        >
        </app-analytics-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3 class="mt-3 mb-2">Origem de vendas (UTMs)</h3>
      </div>
      <div class="col-12 my-1">
        <div class="card">
          <apx-chart
            *ngIf="sourceChartOptions"
            style="width: 100%"
            [series]="sourceChartOptions.series"
            [chart]="sourceChartOptions.chart"
            [title]="sourceChartOptions.title"
            [tooltip]="sourceChartOptions.tooltip"
            [colors]="sourceChartOptions.colors"
            [grid]="sourceChartOptions.grid"
            [xaxis]="sourceChartOptions.xaxis"
            [dataLabels]="sourceChartOptions.dataLabels"
          >
          </apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { LeadResponse } from '../models/LeadResponse';
import { LeadResponseFilter } from '../models/LeadResponseFilter';
import { PaginatedResult } from './model/paginated-result';

@Injectable({
  providedIn: 'root'
})
export class LeadResponseService {

  constructor(
    private httpClient: HttpClient
  ) { }

  listPaginated(
    page: number = 1,
    perPage: number = 10,
    filter: LeadResponseFilter = null
  ): Observable<PaginatedResult<LeadResponse[]>> {
    let url = `${environment.apiUrl}/lead-response?page=${page}&per_page=${perPage}`;
    if (filter) {
      url += '&filter=' + JSON.stringify(filter);
    }
    return this.httpClient.get<PaginatedResult<LeadResponse[]>>(url);
  }


  addToBlacklist(leadResponse: LeadResponse): Observable<LeadResponse> {
    const url = `${environment.apiUrl}/lead-response/${leadResponse.id}/blacklist`;
    return this.httpClient.post<LeadResponse>(url, {});
  }

  deleteFromBlacklist(leadResponse: LeadResponse): Observable<any> {
    const url = `${environment.apiUrl}/lead-response/${leadResponse.id}/blacklist`;
    return this.httpClient.delete(url, {});
  }
}

<button
  type="button"
  class="btn btn-primary"
  [class.btn-global]="!user.global"
  [class.btn-global-activated]="user.global"
  (click)="open()"
  ngbTooltip="{{ user.global ? 'Alterar Credenciais' : 'Ativar Conta Global' }}"
>
  <i class="fa fa-globe"></i>
  Conta Global (Twilio)
</button>

<ng-template #modalTwilio let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Conta Global (Twilio)</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <form [formGroup]="form">
        <div class="form-group">
          <label for="account_sid">Account SID *</label>
          <input
            #accountInput
            type="text"
            class="form-control"
            id="account_sid"
            name="account_sid"
            formControlName="account_sid"
          />
        </div>
        <div class="form-group">
          <label for="auth_token">Auth Token *</label>
          <input
            type="text"
            class="form-control"
            id="auth_token"
            name="auth_token"
            formControlName="auth_token"
          />
        </div>
        <div class="form-group">
          <label for="phone">Twilio Phone Number *</label>
          <input
            type="text"
            class="form-control"
            id="phone"
            name="phone"
            formControlName="phone"
          />
        </div>
        <div class="form-group">(*) campos obrigatórios</div>
      </form>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="submit(modal)"
        [disabled]="form.invalid"
      >
        Gravar
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="delete(modal)"
        *ngIf="editing"
      >
        Excluir
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.close('NO')"
      >
        Cancelar
      </button>
    </div>
  </div>
</ng-template>

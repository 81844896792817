import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { User } from '../../../models/User';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { AuthService } from '../../../shared/services/auth.service';
import { environment } from './../../../../environments/environment';
import { Integration } from './../../../models/Integration';
import { Lead } from './../../../models/Lead';
import { LeadList } from './../../../models/LeadList';
import { PlatformEvent } from './../../../models/PlatformEvent';
import { CopyService } from './../../../shared/services/copy.service';
import { ListsService } from './../../../shared/services/lists.service';
import { MenuService } from './../../layout/menu/menu.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  title = 'Nova lista';

  file: File;
  user: User;
  leads: Lead[];
  leadList: LeadList;
  listId: string;
  listType: string;
  formList: UntypedFormGroup;
  integrations: Integration[];
  selectedEvent: PlatformEvent;

  dynamic = false;

  reloadLeads = new EventEmitter<boolean>();
  subscriptions: Subscription[] = [];

  isUserValidated: boolean = false;

  @ViewChild('nameInput') nameInput: ElementRef<HTMLElement>;
  @ViewChild('modalListType') modalListType: ElementRef<any>;

  get integrationName(): string {
    if (this.leadList && this.leadList.integration) {
      return  `[${this.leadList.integration.platform.name}] ${this.leadList.integration.name}`;
    }
    return null;
  }

  get platformEventName(): string {
    if (
      this.leadList &&
      this.leadList.platform_events[0] &&
    typeof this.leadList.platform_events[0] === 'object'
    ) {
      return this.leadList.platform_events[0].description;
    }

    return null;
  }

  get isManual(): boolean {
    return this.formList.get('manual').value ? true : false;
  }

  get integrationUrl(): string {
    if (this.listId) {
      return `${environment.postbackUrl}/lists/${this.listId}/add-lead`;
    }

    return null;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private activetedRouter: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    private listsService: ListsService,
    private copyService: CopyService,
    private menuService: MenuService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.isUserValidated = this.user.validated; 

    this.formList = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      manual: [false],
      new_leads: [''],
      leads_count: [0],
      importing: [false],
      integration_id: [''],
      dynamic: [this.dynamic],
      tags: [''],
      platform_events: this.formBuilder.array([
        new UntypedFormControl(true)
      ], this.requiredMinChecked(1))
    });

    this.subscriptions.push(
      this.listsService.importFinished.subscribe(updateLeadList => {
        if (this.leadList.id === updateLeadList.id) {
          this.loadList(updateLeadList);
        }
      })
    );

    this.subscriptions.push(
      this.activetedRouter.params.subscribe(params => {
        if (params.id) {
          this.listId = params.id;
          this.title = 'Alterar lista';
          this.findById();
        }
      })
    );

    if (!this.user.validated) {
      this.setListType('automática');
    }

    if (!this.listId) {
      this.setListType('manual');
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(s => s.unsubscribe());
  }

  findById(): void {
    this.subscriptions.push(
      this.listsService.findById(this.listId).subscribe(leadList => {
        this.loadList(leadList);
      })
    );
  }

  toggleDynamic(): void {
    this.formList.patchValue({ dynamic: this.dynamic });
  }

  loadList(leadList: LeadList): void {
    this.listId = leadList.id;
    this.leadList = leadList;
    this.dynamic = leadList.dynamic;
    this.formList.patchValue(leadList);
    this.selectEvent(leadList.platform_events[0] as PlatformEvent);

    this.leads = leadList.leads;
    this.listType = leadList.manual ? 'manual' : 'automática';
    this.title = `Alterar lista ${this.listType}`;

    this.reloadLeads.emit();
  }

  selectEvent(platformEvent: PlatformEvent): void {
    this.selectedEvent = platformEvent;
    this.formList.patchValue({ platform_events: [platformEvent] });
  }

  setListType(listType: string): void {
    this.listType = listType;
    this.title = (this.listId ? 'Alterar' : 'Nova') + ' lista';

    this.formList.removeControl('platform_events');

    if (listType === 'manual') {
      this.formList.patchValue({ manual: true, integration_id: '' });
      this.formList.addControl('platform_events', this.formBuilder.array([]));
    } else {
      this.formList.patchValue({ manual: false });
      this.formList.addControl('platform_events', this.formBuilder.array([], this.requiredMinChecked(1)));
    }

    this.formList.updateValueAndValidity();

    this.title += ' ' + listType;

    if (!this.menuService.isSmallDevice) {
      setTimeout(() => {
        this.nameInput?.nativeElement.focus();
      }, 200);
    }
  }

  requiredMinChecked(min: number = 1): any {
    const validator = (formArray: UntypedFormArray) => {

      if (this.formList?.get('manual').value) {
        return null;
      }

      const totalChecked = formArray.controls.map(v => v.value)
        .reduce((total, current) => current ? total + 1 : total, 0);

      return totalChecked >= min ? null : { required: true };
    };

    return validator;
  }

  copy(): void {
    this.copyService.copy('id', 'URL de para página web copiada!');
    const listName = this.formList.get('name').value;
  }

  updateList(leadList: LeadList): void {
    this.leadList = leadList;
    this.formList.patchValue(leadList);
    this.listsService.addToStorage(leadList);
  }

  back(): void {
    history.back();
  }

  save(): void {

    let listData = Object.assign({}, this.formList.getRawValue());

    listData = Object.assign(listData, {
      platform_events: [this.selectedEvent]
    }) as LeadList;

    if (listData.manual === false && !listData.integration_id) {
      this.toastService.error('Selecione uma integração!');
      return;
    }

    if (listData.tags?.length > 0) {
      const tags = listData.tags.join(',');
      if (tags.length > 500) {
        this.toastService.error('Você adicionou muitas TAGs. Você pode ter no máximo 500 caracteres de TAGs.');
        return;
      }
    }

    const successMessage = listData.id
      ? 'Lista alterada com sucesso!'
      : 'Lista cadastrada com sucesso!';

    const errorMessage = listData.id
      ? 'Erro ao alterar lista! Tente novamente mais tarde'
      : 'Erro ao cadastrar lista! Tente novamente mais tarde';

    this.subscriptions.push(
      this.listsService.save(listData, this.file).subscribe(
        res => {
          this.toastService.success(successMessage);
          this.listsService.addToStorage(res);
          if (!listData.id) {
            this.formList.reset();
            this.router.navigate(['/lists/edit', res.id]);
          }
        },
        err => {
          const message = err.error.message || errorMessage;
          this.toastService.error(message);
        }
      )
    );
  }
}

<app-page-title icon="fa-user" title="Meus dados">
  <app-modal-twilio *ngIf="false"></app-modal-twilio>
</app-page-title>
<div class="card elevated">
  <form [formGroup]="userForm" class="card-body" (submit)="update()">
    <div class="row mb-2">
      <div class="form-group col">
        <label for="name">Nome</label>
        <input
          id="name"
          type="name"
          class="form-control"
          formControlName="name"
          autofocus
          #name
        />
        <span *ngIf="userForm.get('name').touched">
          <small
            class="text text-danger"
            *ngIf="userForm.get('name').errors?.required"
          >
            Nome é obrigatório
          </small>
          <small
            class="text text-danger"
            *ngIf="userForm.get('name').errors?.minlength"
          >
            Nome deve ter ao menos 3 caracteres.
          </small>
        </span>
      </div>
      <div class="form-group col">
        <label>E-mail</label>
        <span class="form-control">{{ userForm.get('email').value }}</span>
      </div>
      <div class="form-group col">
        <label>Documento</label>
        <input type="text" id="document" class="form-control" formControlName="document" />
      </div>
  </div>
    <div class="row mb-2">
      <div class="form-group col">
        <label for="phone">Telefone</label>
        <input
          id="phone"
          type="text"
          class="form-control"
          appPhone
          formControlName="phone"
        />
      </div>
      <div class="form-group col">
        <label for="pix_key">Chave Pix</label>
        <input type="text" id="pix_key" class="form-control" formControlName="pix_key" />
      </div>
      <div class="form-group col">
        <label for="utm_source">UTM Source</label>
        <input
          id="utm_source"
          type="utm_source"
          class="form-control"
          formControlName="utm_source"
        />
      </div>

      <div class="col">
        <div class="form-group">
          <label for="operation">Tipo de operação</label>
          <div>
            <select class="form-control" id="operation_type" formControlName="operation_type" >
              <option *ngFor="let operationType of operationTypes" [value]="operationType.value">{{ operationType.label }}</option>
            </select>
          </div>

          <small *ngIf="userForm.get('operation_type').errors?.required" class="text-danger">
            Preencha com o tipo de operação
          </small>
        </div>
      </div>
      
    </div>
    <div class="row">
      <app-address [addressForm]="userForm"></app-address>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-checkbox
          [(ngModel)]="diffNF"
          [ngModelOptions]="{standalone: true}"
        >
          <span style="color: rgb(163, 163, 163);">
            Meus dados de faturamento são diferentes dos dados principais
          </span>
        </mat-checkbox>
      </div>
    </div>

    <div *ngIf="diffNF" class="row">
      <div class="col-md-12">
        <h2 class="my-3">Dados de faturamento</h2>
        <div class="row">
          <div class="col-sm-6">
            <label for="billing_document">CPF/CNPJ</label>
            <input type="text" (input)="formatCpfCnpj($event)" (change)="formatCpfCnpj($event)" maxlength="18" id="billing_document" required class="form-control" formControlName="billing_document" />
          </div>
          <div class="col-sm-6">
            <label for="billing_email">E-mail</label>
            <input type="text" id="billing_email" class="form-control" formControlName="billing_email" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4">
            <label for="billing_company_name">Razão social</label>
            <input type="text" id="billing_company_name" class="form-control" formControlName="billing_company_name" />
          </div>
          <div class="col-md-4">
            <label for="billing_company_alias">Nome fantasia</label>
            <input type="text" id="billing_company_alias" class="form-control" formControlName="billing_company_alias" />
          </div>
          <div class="col-md-4">
            <label for="billing_im">Inscrição municipal</label>
            <input type="text" id="billing_im" class="form-control" formControlName="billing_im" />
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-6">
            <label for="billing_postcode">CEP</label>
            <input type="text" id="billing_postcode" (input)="cepChanged($event)" class="form-control" maxlength="9" formControlName="billing_postcode" />
            <small *ngIf="errorCEP" style="color: rgb(255, 77, 77);">Não foi possível puxar os dados do CEP automaticamente. Por favor, verifique se a numeração está correta.</small>
            
          </div>
          <div class="col-sm-6">
            <label for="billing_street">Endereço</label>
            <input type="text" id="billing_street" class="form-control" formControlName="billing_street" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-6">
            <label for="billing_number">Número</label>
            <input type="number" id="billing_number" class="form-control" formControlName="billing_number" />
          </div>
          <div class="col-sm-6">
            <label for="billing_complement">Complemento</label>
            <input type="text" id="billing_complement" class="form-control" formControlName="billing_complement" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4">
            <label for="billing_district">Bairro</label>
            <input type="text" id="billing_district" class="form-control" formControlName="billing_district" />
          </div>
          <div class="col-md-4">
            <label for="billing_city">Cidade</label>
            <input type="text" id="billing_city" class="form-control" formControlName="billing_city" />
          </div>
          <div class="col-md-4">
            <label for="billing_state">Estado</label>
            <input type="text" id="billing_state" class="form-control" formControlName="billing_state" />
          </div>
        </div>
      </div>
    </div>

    <hr/>

    <button class="btn btn-primary">
      Salvar
    </button>
  </form>
</div>

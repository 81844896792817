<app-page-title
  icon="fa-bullhorn"
  title="Programa de afiliados"
></app-page-title>

<div class="card elevated">
  Nosso programa de afiliados vai lhe premiar com 500 créditos de SMS para cada
  cliente que assinar nossa plataforma à partir da sua indicação.
</div>

<div class="card elevated mt-4">
  <div class="form-group row" *ngIf="!isClient">
    <div class="col">Links de Afiliado</div>
  </div>
  <div class="form-group row">
    <div class="col" *ngIf="!isClient">
      <label>URL de Assinatura Gratuita</label>
      <input
        type="text"
        class="form-control"
        ngbTooltip="Clique para copiar"
        (click)="copy(user.affiliation.short_free_url)"
        [value]="user.affiliation.short_free_url"
        readonly
      />
    </div>
    <div class="col">
      <label>URL de Assinatura Paga</label>
      <input
        type="text"
        class="form-control"
        ngbTooltip="Clique para copiar"
        (click)="copy(user.affiliation.short_paid_url)"
        [value]="user.affiliation.short_paid_url"
        readonly
      />
    </div>
  </div>
  <hr />
  <div class="row mt-2 mb-2">
    <form (submit)="pageChanged(1)">
      <div class="row">
        <div class="col">
          <div class="input-group">
            <input
              type="text"
              name="filter"
              id="filter"
              class="form-control"
              placeholder="Pesquisar pelo nome ou e-mail"
              [(ngModel)]="filter"
            />
            <button class="btn btn-primary">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="form-group">
    <label>Clientes indicados</label>
    <span *ngIf="affiliates$ | async as result">
      <table class="table" *ngIf="result.data.length > 0; else noAffiliates">
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let affiliate of result.data">
            <td>{{ affiliate.name }}</td>
            <td>{{ affiliate.email }}</td>
          </tr>
        </tbody>
      </table>

      <app-pagination
        [currentPage]="result.current_page"
        [itemsPerPage]="result.per_page"
        [totalItems]="result.total"
        (pageChanged)="pageChanged($event)"
      ></app-pagination>
    </span>
  </div>
</div>

<ng-template #noAffiliates>
  <div class="text-center">
    <p>Por enquanto nenhum cliente assinou :(</p>
    <p>
      Mas não fique triste! Compartilhe o seu link de afiliado e comece a ganhar
      créditos!
    </p>
  </div>
</ng-template>

<div class="messages">
  <app-page-title icon="fa-paper-plane" [title]="title">
    <button class="btn btn-secondary" (click)="back()">Voltar</button>
  </app-page-title>

  <div *ngIf="result?.data?.length > 0; else notFound">
    <app-accordion-panel
      *ngFor="let control of result?.data"
      [title]="control.message"
    >
      <div class="row message-line">
        <div class="col-md-3 col-sm-12"><i class="fa fa-user me-2"></i>{{ control.lead.name }}</div>
        <div class="col-md-3 col-sm-12"><i class="fa fa-phone me-2"></i>{{ control.lead.phone }}</div>
        <div class="col-md-3 col-sm-12"><i class="fa fa-calendar me-2"></i>{{ control.sent_date | date:'dd/MM/yyyy HH:mm' }}</div>
      </div>
    </app-accordion-panel>
  </div>

  <ng-template #notFound>
    <div class="card">
      Nenhuma mensagem enviada
    </div>
  </ng-template>

  <app-pagination
    *ngIf="result"
    [currentPage]="result.current_page"
    [itemsPerPage]="result.per_page"
    [totalItems]="result.total"
    (pageChanged)="pageChanged($event)"
  ></app-pagination>
</div>

<app-page-title icon="fa-money" title="Recargas">
  <a [routerLink]="['/recharges/', addPage]" class="btn btn-primary btn-icon-right">
    Adicionar créditos <i class="fa fa-plus-circle"></i>
  </a>
</app-page-title>

<form (submit)="pageChanged(page)">
  <div class="row col-md-12">
    <div class="col-md-12 col-sm-12" style="width: fit-content;">
      <mat-form-field>
        <mat-label>Data inicial</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="startedMonth" name="startedMonth">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <small
        class="text text-danger"
        *ngIf="startedMonth && endedMonth && startedMonth > endedMonth"
        >Período inválido.</small
      >
      </mat-form-field>
  </div>
  <div class="col-md-12 col-sm-12" style="width: fit-content;">
      <mat-form-field>
        <mat-label>Data final</mat-label>
        <input matInput [matDatepicker]="picker2" [(ngModel)]="endedMonth" name="endedMonth">
        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <small
        class="text text-danger"
        *ngIf="startedMonth && endedMonth && startedMonth > endedMonth"
        >Período inválido.</small
      >
      </mat-form-field>
  </div>
  </div>
  <div class="row col-md-12 col-sm-12 flex-wrap">
  <div class="row mb-5">
    <div class="col-md-11 col-sm-12">
      <div class="form-group">
        <label>Digite o nome ou e-mail</label>
        <input
          type="text"
          name="filter"
          class="form-control"
          placeholder="Digite o nome ou e-mail"
          [(ngModel)]="text"
        />
      </div>
    </div>

    <div style="width: fit-content;" class="d-flex justify-content-center align-items-center">
      <button class="btn btn-primary mt-4" (click)="pageChanged(page)">
        <i class="fa fa-search"></i>
      </button>
    </div>
    <hr class="mt-4"/>
    <div class="col-xs-12 mt-2 mb-2 d-flex flex-row">
      <div style="width: 90%;">
        <label>Ordenar por</label>
        <select
          name="order"
          id="order"
          class="form-control"
          [(ngModel)] = "orderBy"
          [value] = "orderBy"
          (change) = "filterChanged()"
        >
          <option value="created_at">Data</option>
          <option value="credits">Créditos</option>
          <option value="user">Usuário</option>
          <option value="status">Situação</option>
        </select>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center mt-2" style="width: fit-content; margin: auto;">
        <label>Reverso</label>
        <app-switcher
        [checked]="invertedOrder"
        (change)="invertOrder()"
        style="max-height: 30px !important;user-select: none;"></app-switcher>
      </div>
    </div>
    <div class="col-xs-12 mt-2 mb-2 d-flex flex-row">
      <div style="width: 90%;">
        <label>Responsável</label>
        <select
          name="author"
          id="author"
          class="form-control"
          [(ngModel)] = "author"
          [value] = "author"
          (change) = "filterChanged()"
        >
          <option value="">Todos</option>
          <option *ngFor="let user of users" [value]="user.email">
            {{ user.name }} - {{ user.email }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
</form>

<section>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Data</th>
          <th>Descrição</th>
          <th>Serviço</th>
          <th>Créditos</th>
          <th>Preço</th>
          <th>Valor</th>
          <th></th>
          <ng-container *ngIf="administrator || customer_success">
            <th>Usuário</th>
          </ng-container>
          <th style="width: 200px">Situação</th>
          <th class="d-flex justify-content-center align-items-center" *ngIf="administrator || customer_success">Ações</th>
          <th *ngIf="administrator || customer_success">Responsável</th>
          <th *ngIf="(administrator || customer_success) || user.is_billable">NF</th>
        </tr>
      </thead>
      <tbody *ngIf="result?.data.length > 0; else notRecharges">
        <tr *ngFor="let recharge of result.data">
          <td>{{ recharge.created_at | date: "dd/MM/yyyy H:mm" }}</td>
          <td>{{ recharge.description }}</td>
          <td>{{ recharge.target_name }}</td>
          <td>{{ recharge.credits | numberFormat }}</td>
          <td>
            <span ngbTooltip="{{ recharge.price | numberFormat: 'R$ ':true:3 }}">
              {{ recharge.price | numberFormat: "R$ ":true:2}}</span>
          </td>
          <td><span
              ngbTooltip="{{ recharge.price | numberFormat: 'R$ ':true:3 }} x {{ recharge.credits | numberFormat }} SMS">
              {{ recharge.total | numberFormat: "R$ ":true:2 }}
            </span></td>
          <td>
            <button class="btn" ngbTooltip="QRCode do PIX"
              *ngIf="recharge.payment_type === 'pix' && !recharge.confirmated_at"
              (click)="showQRCode(modalQRCode, recharge)">
              <img src="/assets/pix.gif" width="25" />
            </button>
          </td>
          <ng-container *ngIf="administrator || customer_success">
            <td>{{ recharge.user?.name }}</td>
            <td>
              <div class="btn-group">

                <span *ngIf="
                !recharge.confirmated_at &&
                recharge.payment_status === 'rejected'
              ">Pagamento rejeitado</span>
                <button class="btn btn-primary" *ngIf="!recharge.confirmated_at; else creditsConfirmated"
                  (click)="confirmCredits(recharge)">
                  Confirmar créditos
                </button>
                <button class="btn btn-danger" *ngIf="!recharge.confirmated_at" (click)="confirmDelete(recharge)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="!administrator && !customer_success">
            <td>
              {{
              recharge.confirmated_at
              ? "Créditos confirmados"
              : recharge.payment_status === "rejected"
              ? "Pagamento rejeitado"
              : "Créditos Pendentes"
              }}
            </td>
          </ng-container>
          <ng-container *ngIf="(administrator || customer_success) && recharge.target == '0' && recharge.confirmated_at && recharge.credits > 0">
            <td>
              <button *ngIf="recharge.confirmated_at" class="btn btn-primary" style="padding: 5px 10px;" ngbTooltip="Transferir" (click)="newActionModal('transfer', modalTransfer, recharge)">
                <i class="fa fa-exchange d-flex justify-content-center align-items-center"></i>
              </button>
              <button *ngIf="recharge.confirmated_at" class="btn btn-danger" style="padding: 5px 10px;" ngbTooltip="Cancelar" (click)="newActionModal('delete', modalDelete, recharge)">
                <i class="fa fa-close d-flex justify-content-center align-items-center"></i>
              </button>
            </td>
            <td>
              {{ recharge.created_by }}
            </td>
          </ng-container>
          <td *ngIf="(administrator || customer_success) || user.is_billable">
            <ng-container *ngIf="recharge.invoice_url">
              <a [href]="recharge.invoice_url" target="download"><i class="fa fa-download" style="cursor: pointer; color: #00BE9A;"></i></a>
            </ng-container>
            <ng-container *ngIf="!isFailed(recharge.invoice_status) && !recharge.invoice_url">
              -
            </ng-container>
            <ng-container *ngIf="isFailed(recharge.invoice_status)">
              Erro
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-pagination *ngIf="result" [currentPage]="result.current_page" [itemsPerPage]="result.per_page"
    [totalItems]="result.total" (pageChanged)="pageChanged($event)"></app-pagination>
</section>

<ng-template #creditsConfirmated>
  <span>Créditos confirmados</span>
</ng-template>

<ng-template #notRecharges>
  <tbody>
    <tr>
      <td colspan="9999">Nenhuma recarga encontrada.</td>
    </tr>
  </tbody>
</ng-template>

<ng-template #modalDelete let-modalDelete>
  <div class="modal-header">
    <h4 class="modal-title">
      Cancelar recarga
    </h4>
  </div>
  <div class="modal-body">
    <form (submit)="cancel()" [formGroup]="deleteForm">
      <label for="reason" class="reason-label">
        <span>Razão da exclusão</span>
        <input type="text"
        required
        autocomplete="off"
        id="reason"
        name="reason"
        formControlName="reason"
        class="form-control"
        placeholder="Ex: Cliente se arrependeu..."/>
      </label>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="modalDelete.dismiss()">Cancelar</button>
    <button class="btn btn-primary" [disabled]="deleteForm.invalid" (click)="cancel()">Confirmar</button>
  </div>
</ng-template>

<ng-template #modalTransfer let-modalTransfer>
  <div class="modal-header d-flex justify-content-start align-items-center flex-column">
    <h4 class="modal-title">
      Transferência de recarga
    </h4>
    <h6>{{ recharge.user.name }}</h6>
  </div>
  <div class="modal-body">
    <form (submit)="transfer()" [formGroup]="transferForm">
      <div class="form-group col-md-12">
        <label for="user_id">Selecione a conta de destino</label>
        <select name="to_user" id="to_user" class="form-control" formControlName="to_user" required>
          <option *ngFor="let user of users" [value]="user.id">
            {{ user.name }} - {{ user.email }}
          </option>
        </select>
        <label for="reason" class="reason-label" style="margin-top: 10px; margin-bottom: 10px;">
          <span>Razão da transferência</span>
          <input type="text"
          autocomplete="off"
          required
          id="reason"
          name="reason"
          formControlName="reason"
          class="form-control"
          placeholder="Ex: Cliente se arrependeu..."/>
        </label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="modalTransfer.dismiss()">Cancelar</button>
    <button class="btn btn-primary" [disabled]="transferForm.invalid" (click)="transfer()">Confirmar</button>
  </div>
</ng-template>

<ng-template #modalQRCode let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        QR Code para pagamento do PIX
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('CLOSE')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <p><img [src]="pixImage" *ngIf="pixImage" class="w-100" /></p>
      <div *ngIf="pixCode != ''" class="text-center">
        <p><small>Se você não conseguir ler o QR Code, pode usar o código abaixo e usar no "Pix Copia e Cola":</small></p>
        <p>
          <textarea rows="3" class="w-100 form-control text-center">{{pixCode}}</textarea><br>
          <button (click)="pixCopy(pixCode)" class="btn btn-primary"><i class="fa fa-copy"></i> Copiar</button>
        </p>
      </div>
    </div>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const ignoreVerification = state.url.includes('/password-reset') || state.url.includes('/login');

    if (!ignoreVerification && this.authService.checkTokenExpired()) {
      this.router.navigate(['/login']);
      return;
    }

    if (!ignoreVerification && !this.authService.isLogged()) {
      this.router.navigate(['/login']);
      return false;
    }

    const user = this.authService.getUser();
    if (user.temporary && state.url !== '/change-password') {
      this.router.navigate(['/change-password']);
      return false;
    }

    return true;
  }

}

<app-page-title *ngIf="administrator" icon="fa-file" title="Relatório de faturamento">
  <button type="button" class="btn btn-primary" (click)="download()">
    <i class="fa fa-download"></i>
    Download CSV
  </button>
</app-page-title>

<section class="reports" *ngIf="administrator">
  <div class="row">
    <div class="col-md-2" style="width: fit-content;">
      <div class="form-group">
          <mat-form-field>
            <mat-label>Data inicial</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" (dateChange)="pageChanged(page)">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <small
            class="text text-danger"
            *ngIf="startDate && endDate && startDate > endDate"
            >Período inválido.</small
          > 
          </mat-form-field>
        </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
          <mat-form-field>
            <mat-label>Data final</mat-label>
            <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" (dateChange)="pageChanged(page)">
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <small
            class="text text-danger"
            *ngIf="startDate && endDate && startDate > endDate"
            >Período inválido.</small
          > 
          </mat-form-field>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Assinaturas</label>
            <h2>{{ signatureBilling | numberFormat : "" : true }}</h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Recargas</label>
            <h2>{{ rechargeBilling | numberFormat : "" : true }}</h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Total</label>
            <h2>{{ billing | numberFormat : "" : true }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-hover">
    <thead>
      <tr>
        <th>Usuário</th>
        <th class="text-right">Valor Assinatura</th>
        <th class="text-right">Valor Créditos</th>
        <th class="text-right">Total</th>
      </tr>
    </thead>
    <tbody *ngIf="result?.data.length > 0; else notRecharges">
      <tr *ngFor="let user of result?.data">
        <td>{{ user.name }}</td>
        <td class="text-right">
          {{ user.credit_info.signature_billing | numberFormat : "" : true }}
        </td>
        <td class="text-right">
          {{ user.credit_info.credits_billing | numberFormat : "" : true }}
        </td>
        <td class="text-right">
          {{
            user.credit_info.credits_billing +
              user.credit_info.signature_billing | numberFormat : "" : true
          }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr *ngIf="totalCreditsInfo">
        <td>Total</td>
        <td class="text-right">
          {{ totalCreditsInfo.signature_billing | numberFormat : "" : true }}
        </td>
        <td class="text-right">
          {{ totalCreditsInfo.credits_billing | numberFormat : "" : true }}
        </td>
        <td class="text-right">
          {{ totalCreditsInfo.total_contracted | numberFormat : "" : true }}
        </td>
      </tr>
    </tfoot>
  </table>
  <app-pagination
    *ngIf="result"
    [currentPage]="result.current_page"
    [itemsPerPage]="result.per_page"
    [totalItems]="result.total"
    (pageChanged)="pageChanged($event)"
  ></app-pagination>
</section>

<ng-template #notRecharges>
  <tbody>
    <tr>
      <td colspan="7">Nenhum usuário encontrado.</td>
    </tr>
  </tbody>
</ng-template>

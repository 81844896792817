<div class="leads card mt-3 elevated">
  <div class="card-body">
    <h3>Sequências</h3>
    <div *ngIf="sequences$ | async as sequences">
      <div *ngIf="sequences.length > 0; else noSequences">
        <div *ngFor="let control of sequences">
          <div class="form-group row">
            <div class="row">
              <div class="col">
                <label>Mensagem:</label>
                <p>{{ control.message || control.sequence?.text }}</p>
              </div>
              <hr>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label>Enviar após?</label>
                <p>{{ control.sequence?.interval }} {{ control.sequence?.interval_type_id | intervalType }}</p>
              </div>

              <div class="col-md-2">
                <label>Status:</label>
                <p>
                  {{ status(control) }}
                  <i *ngIf="!control.sent && !control.cancelled && control.queued"
                     class="fa fa-question-circle info"
                     ngbTooltip="Informa se está na fila de envio para o provedor"></i>
                </p>
              </div>

              <div class="col-md-2" *ngIf="control.sent || control.cancelled">
                <label *ngIf="control.sent">Data/Hora envio:</label>
                <label *ngIf="control.cancelled">Cancelada em:</label>
                <p>{{ control.updated_at | date:'dd/MM/yyyy, H:mm:ss' }}</p>
              </div>

              <div class="col-md-2" *ngIf="!control.cancelled && !control.sent">
                <label>Será enviada em:</label>
                <p>{{ control.sent_date | date:'dd/MM/yyyy, H:mm:ss' }}</p>
              </div>

              <div class="col-md-2" *ngIf="isAdministrator">
                <label>Provedor Status:</label>
                <p>{{ control.webhook_status ? getProviderStatus(control.webhook_status) : 'Não fornecido' }}</p>
              </div>
              
                
              <div class="col-md-2">
                <label *ngIf="control.cancelled">Motivo:</label>
                <p>{{ control.reason }}</p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-secondary" (click)="back()">
        Voltar
      </button>
    </div>
  </div>
</div>

<ng-template #noSequences>
  <p>Nenhuma sequência criada para este lead</p>
</ng-template>

import { Plan } from './../app/models/Plan';

export const FREE_PLAN = '8e291031-457d-11ec-9f39-0242c0a80303';
export const FUNNEL_GOLD = '';

const plans: Plan[] = [{
  id: FREE_PLAN,
  name: 'Grátis para Testar'
}, {
  id: FUNNEL_GOLD,
  name: 'Funnel Gold'
}];

export default plans;

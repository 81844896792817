import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LeadsService } from './../../../shared/services/leads.service';
import { Component, OnInit } from '@angular/core';
import { Control } from 'src/app/models/Control';
import { providerStatus } from '../../../shared/utils';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ADMINISTRATOR } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-lead-sequences',
  templateUrl: './lead-sequences.component.html',
  styleUrls: ['./lead-sequences.component.scss']
})
export class LeadSequencesComponent implements OnInit {

  leadId: string;
  sequences$: Observable<Control[]>;
  user: User;

  constructor(
    private leadService: LeadsService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.route.params
      .pipe(take(1))
      .subscribe(params => {
        if (params.id) {
          this.leadId = params.id;
        }
      });
  }

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  ngOnInit(): void {
    if (this.leadId) {
      this.sequences$ = this.leadService.listSequences(this.leadId);
    }

    this.user = this.authService.getUser(true);
  }

  back() {
    window.history.back();
  }

  status(control: Control): string {
    if (!control.sent && !control.cancelled && !control.queued) {
      return 'Aguardando horário';
    }

    if (!control.sent && !control.cancelled && control.queued) {
      return 'Agendada';
    }

    if (control.cancelled) {
      return 'Cancelada';
    }

    if (control.sent) {
      return 'Enviada';
    }

    return '';
  }

  getProviderStatus(status: string = '') {
    let provider = providerStatus(status);

    return provider;
  }

}

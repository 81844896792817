import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/User';

import { Price } from './../../../models/Price';
import { ConfirmationService } from './../../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from './../../../shared/components/toast/toast.service';
import { AuthService } from './../../../shared/services/auth.service';
import { RechargesService } from './../../../shared/services/recharges.service';
import { MenuService } from './../../layout/menu/menu.service';
import { RechargeValidators } from './recharge.validators';

interface PagePrice {
  startQuantity: number;
  endQuantity: number;
  price: number;
}

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss'],
})
export class RechargeComponent implements OnInit, OnDestroy {
  credits = 100;

  user: User;
  target: string = 'sms'; // default
  price: number;
  total: number;
  prices: Price[];
  pagePrices: PagePrice[] = [];

  isCnpj: boolean = false;
  oldCNPJCPF: string = '';

  showAddressTab: boolean;
  showAddress: boolean;
  tab: string;
  tabs = [
    { id: 'credits', text: 'Créditos' },
    { id: 'address', text: 'Endereço' },
    { id: 'payment', text: 'Pagamento' },
  ];

  paymentType = 'pix';

  modal: any;
  issuers: any[];
  pixImage: string;
  pixCode: string;
  rechargeForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  @ViewChild('inputCredits') inputCredits: ElementRef;
  @ViewChild('document') inputDocument: ElementRef<HTMLElement>;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private menuService: MenuService,
    private modalService: NgbModal,
    private authService: AuthService,
    private toastService: ToastService,
    private rechargesService: RechargesService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.tab = 'credits';
    this.user = this.authService.getUser();
    this.showAddress = false;
    this.showAddressTab = !this.user.postcode;

    this.subscriptions.push(
      this.authService.userChanged.subscribe(() => {
        this.ngOnDestroy();
        this.ngOnInit();
      })
    );

    if (!this.showAddressTab) {
      this.tabs = this.tabs.filter((t) => t.id !== 'address');
    }

    this.rechargeForm = this.fb.group({
      name: [
        this.user.name,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(25),
        ],
      ],
      target: [this.target, Validators.required],
      email: [this.user.email, Validators.required],
      credits: [this.credits, Validators.required],
      payment_type: ['', Validators.required],
      phone: [this.user.phone, [Validators.required, Validators.minLength(10)]],
      document: [
        '',
        [Validators.required, Validators.minLength(14)],
        RechargeValidators.documentValidator(),
      ],
      postcode: [
        this.user.postcode,
        [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
      ],
      street: [
        this.user.street,
        [Validators.required, Validators.minLength(3)],
      ],
      number: [this.user.number, Validators.required],
      complement: [this.user.complement],
      district: [
        this.user.district,
        [Validators.required, Validators.minLength(3)],
      ],
      city: [this.user.city, Validators.required],
      region: [this.user.region, Validators.required],
      card_number: ['', Validators.required],
      due_date: ['', [Validators.required, Validators.minLength(7)]],
      cvv: ['', Validators.required],
    });

    // this.changeDocument(this.user.document);
    this.rechargeForm.patchValue({ document: this.user?.document ?? '' });

    this.subscriptions.push(
      this.rechargeForm.get('credits').valueChanges.subscribe((value) => {
        this.creditsValuesChange(value);
      })
    );

    this.subscriptions.push(
      this.rechargesService.price().subscribe((prices) => {
        this.pagePrices = [];
        this.prices = prices;
        this.price = this.getPrice();
        this.total = this.price * this.credits;

        if (prices.length === 1 && prices[0].quantity === 0) {
          return;
        }

        this.renderPagePrices();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  renderPagePrices(): void {
    this.pagePrices = [];
    this.prices[this.target].forEach((p, idx) => {
      let start = this.credits;
      if (idx > 0) {
        start = this.prices[this.target][idx - 1].quantity + 1;
      }

      this.pagePrices.push({
        startQuantity: start,
        endQuantity: p.quantity,
        price: p.price,
      });
    });
  }

  creditsValuesChange(value: number): void {

    if(!value) {
      value = this.credits;
    }

    this.credits = +value;

    this.price = this.getPrice();

    console.log(this.price);

    this.total = +value * this.price;

  }

  getPrice(): number {

    const _prices = this.prices[this.target];

    const price = _prices.find(p => p.quantity >= this.credits);

    if (price) {
      return price.price;
    }

    if (_prices?.length > 0) {
      if (this.credits > _prices[_prices.length - 1].quantity) {
        return _prices[_prices.length - 1].price;
      }
    }
  }

  setDocumentFocus(): void {
    if (!this.menuService.isSmallDevice) {
      setTimeout(() => this.inputDocument?.nativeElement?.focus(), 200);
    }
  }

  setCreditsFocus(): void {
    if (!this.menuService.isSmallDevice) {
      setTimeout(() => this.inputCredits?.nativeElement?.focus(), 200);
    }
  }

  togglePaymentType(paymentType: string): void {
    this.paymentType = paymentType;
    this.rechargeForm.patchValue({ payment_type: paymentType });
    this.setDocumentFocus();
  }

  changeDocument(ev): void {
    if (!ev) {
      return;
    }

    const document = ev.target.value;
    this.rechargeForm.patchValue({ document });
  }

  targetChanged(evt): void {
    this.rechargeForm.patchValue({ target: evt.target.value });
    this.creditsValuesChange(null);
    this.renderPagePrices();
  }

  changePhone(phone: string): void {
    this.rechargeForm.patchValue({ phone });
  }

  changeCardNumber(cardNumber: any): void {
    this.rechargeForm.patchValue({ card_number: cardNumber });
  }

  formatDueDate(event: any): void {
    if (event.code === 'Backspace' || event.code === 'Tab') {
      return;
    }

    const dueDate = this.rechargeForm
      .get('due_date')
      ?.value.match(/\d+/g)
      ?.join([]);
    if (!dueDate) {
      this.rechargeForm.patchValue({ due_date: '' });
      return;
    }

    let formattedDueDate = '';
    for (const digit of dueDate) {
      formattedDueDate += digit;
      if (formattedDueDate.length === 2) {
        formattedDueDate += '/';
      }
    }

    this.rechargeForm.patchValue({ due_date: formattedDueDate });
  }

  backClick(): void {
    const index = this.tabs.findIndex((t) => t.id === this.tab);

    if (index === 0) {
      this.showAddress = false;
    }

    if (index > 0) {
      this.tab = this.tabs[index - 1].id;
    } else {
      window.history.back();
    }
  }

  nextClick(modal: any): void {
    this.modal = modal;
    if (
      (this.tab === 'credits' && !this.showAddressTab) ||
      this.tab === 'address'
    ) {
      this.tab = 'payment';
      this.setDocumentFocus();
      return;
    }

    if (this.tab === 'credits') {
      this.tab = 'address';
      return;
    }

    this.buy(null);
  }

  isFormValid(): boolean {
    if (this.tab === 'credits') {
      return this.credits >= 100;
    }

    const rechargeForm = this.rechargeForm.getRawValue();

    if (this.tab === 'address') {
      return (
        this.showAddress &&
        rechargeForm.number > 0 &&
        rechargeForm.street.trim() &&
        rechargeForm.district.trim() &&
        rechargeForm.city.trim() &&
        rechargeForm.region.trim()
      );
    }

    if (this.tab === 'payment') {
      if (this.paymentType === 'pix') {
        return (
          rechargeForm.document?.trim() &&
          !this.rechargeForm.get('document').errors
        );
      }

      return (
        +rechargeForm.cvv &&
        rechargeForm.name?.trim() &&
        rechargeForm.document?.trim() &&
        rechargeForm.card_number?.trim() &&
        rechargeForm.due_date?.trim() &&
        rechargeForm.due_date.trim().length === 7
      );
    }
  }

  buy(event: any): void {
    if (event) {
      event.preventDefault();
    }

    if (this.tab === 'credits' && this.credits > 100) {
      if (this.tabs.length === 3) {
        this.tab = 'address';
      } else {
        this.tab = 'payment';
      }
      return;
    }

    if (!this.isFormValid()) {
      return;
    }

    const rechargeForm = this.rechargeForm.getRawValue();

    rechargeForm.payment_type = this.paymentType;
    rechargeForm.card_number = rechargeForm.card_number.replace(/\D/g, '');
    rechargeForm.document = rechargeForm.document.replace(/\D/g, '');

    this.confirmationService.show(
      `Confirma a compra de ${this.credits} créditos no valor de R$ ${this.total
        .toFixed(2)
        .replace('.', ',')}?`,
      () => this.registerBuy(rechargeForm)
    );
  }

  pixCopy(pixCode: string): void {
    navigator.clipboard.writeText(pixCode);
    this.toastService.success('Código PIX copiado para a área de transferência!');
  }

  registerBuy(recharge: any): void {
    this.rechargesService.buy(recharge).subscribe({
      next: res => {
        let message = 'Créditos incluídos com sucesso.';
        if (!res.confirmated_at) {
          message +=
            ' Ainda não foram confirmados, será necessário aguardar alguns minutos!';
        }

        this.toastService.success(message);

        if (this.paymentType === 'pix') {
          this.modalService.open(this.modal);
          this.pixImage = res.url_qrcode_pix;
          this.pixCode = res.pix_code;
        }

        this.updateUser();

        this.router.navigate(['/recharges']);
      },
      error: res => {
        if (res.error?.message) {
          this.toastService.error(res.error.message);
        }
      }
    });
  }

  updateUser(): void {
    const rechargeForm = this.rechargeForm.getRawValue();

    this.user.document = rechargeForm.document;
    this.user.city = rechargeForm.city;
    this.user.complement = rechargeForm.complement;
    this.user.district = rechargeForm.district;
    this.user.number = rechargeForm.number;
    this.user.postcode = rechargeForm.postcode;
    this.user.region = rechargeForm.region;
    this.user.street = rechargeForm.street;
    this.user.phone = rechargeForm.phone;

    if (this.authService.getSimulatedUser()) {
      this.authService.setSimulatedUser(this.user);
    } else {
      this.authService.setUser(this.user);
    }
  }

  formatCpfCnpj(e) {
    const cleanedValue = e.target.value.replace(/\D/g, '');

    if (e.target.value.length < this.oldCNPJCPF.length) {
      this.oldCNPJCPF = e.target.value
      return;
    }

    let newValue = '';

    if (cleanedValue.length <= 11) {
      // Aplica máscara para CPF parcial
      newValue = cleanedValue.replace(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
    } else {
      // Aplica máscara para CNPJ parcial
      newValue = cleanedValue.replace(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
    }

    this.oldCNPJCPF = newValue.toString();
    return e.target.value = newValue
  }

}

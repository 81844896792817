import { InputCompareValidator } from './../../shared/validators/input-compare.validator';
import { ToastService } from './../../shared/components/toast/toast.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { AuthService } from './../../shared/services/auth.service';

import { User } from 'src/app/models/User';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {

  user: User;
  title = 'Alterar senha';

  changePasswordForm: UntypedFormGroup;

  @ViewChild('password') inputPassword: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      new_password: ['', [
        Validators.required,
        Validators.minLength(8)
      ]
      ],
      password_confirmation: ['', [
        Validators.required,
        Validators.minLength(8)
      ],
        InputCompareValidator.compare('new_password')
      ],
    });

    this.changePasswordForm
      .get('new_password')
      .valueChanges
      .subscribe(() => this.changePasswordForm.patchValue({ password_confirmation: '' }));

    this.user = this.authService.getUser();

    this.authService.userChanged.subscribe(() => {
      if (this.authService.isSimulating) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.inputPassword.nativeElement.focus(), 200);
  }

  update(): void {
    const password = this.changePasswordForm.get('password').value;
    const newPassword = this.changePasswordForm.get('new_password').value;

    this.authService
      .changePassword(password, newPassword)
      .subscribe(
        () => {
          this.toastService.success('Senha alterada com sucesso!');
          this.router.navigate(['/']);
        }, err => {
          this.toastService.error(`Erro ao alterar senha: ${err.error}`);
        }
      );
  }
}

<app-page-title *ngIf="!customer_success" title="Parceiros" icon="fa-fire"></app-page-title>

<div class="card gradient-row danger" *ngIf="!user.pix_key && !customer_success">
  (*) ATENÇÃO: Você deve cadastrar sua chave pix para receber os pagamentos
</div>

<div *ngIf="!customer_success" class="dashboard">
  <div *ngIf="partnerInfo$ | async as partnerInfo">
    <div class="row">
      <div class="col mb-4">
        <div class="card bg-transparent">
          <h2>Seus ganhos</h2>
          <h3>Mês anterior</h3>
          <div class="fa-2x">
            <b>
              <span class="symbol">R$</span>
              {{
                partnerInfo.lastPayment?.amount | numberFormat : "" : true : 2
              }}
            </b>
            <div class="status" *ngIf="partnerInfo.lastPayment">
              <b>STATUS:</b>
              {{ partnerInfo.lastPayment.status | paymentStatus }}
            </div>
          </div>
        </div>
      </div>

      <div class="col mb-4 flex">
        <div class="card bg-transparent">
          <h2>
            Seus ganhos
            <i
              class="fa fa-question-circle ms-2"
              ngbTooltip="Seus ganhos são calculados sobre as recargas realizadas pelos seus parceiros"
            ></i>
          </h2>
          <h3>Mês atual</h3>
          <div class="fa-2x">
            <b>
              <span class="symbol">R$</span>
              {{
                partnerInfo.actualPayment?.amount | numberFormat : "" : true : 2
              }}
            </b>
            <div class="status" *ngIf="partnerInfo.actualPayment">
              <b>STATUS:</b>
              {{ partnerInfo.actualPayment.status | paymentStatus }}
            </div>
          </div>
        </div>
      </div>

      <div class="col mb-4">
        <div class="card bg-green">
          <h2>Seus ganhos</h2>
          <h3>Totais</h3>
          <div class="fa-2x">
            <b>
              <span class="symbol">R$</span>
              {{ partnerInfo.totalPaid | numberFormat : "" : true : 2 }}
            </b>
            <div class="status">ATÉ O ÚLTIMO DEPÓSITO</div>
            <div class="float-right">
              <a class="btn btn-secondary" routerLink="/partner/payments">
                <i class="fa fa-line-chart"></i>
                Relatório
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-sm-12 mb-4">
        <div class="card bg-green">
          <h2>
            MEUS DADOS
            <div class="ms-2">
              <a
                class="me-2"
                (click)="showPixForm()"
                ngbTooltip="{{
                  user.pix_key ? 'Alterar' : 'Cadastrar'
                }} chave pix"
              >
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-plus': !user.pix_key,
                    'fa-edit': user.pix_key
                  }"
                ></i>
              </a>
              <a
                (click)="deletePixKey()"
                *ngIf="user.pix_key"
                ngbTooltip="Excluir chave pix"
              >
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </h2>
          <div class="field">
            <p><b>NOME: </b> {{ user.name }}</p>
            <p>
              <b>CHAVE PIX:</b>
              {{ user.pix_key ?? "-" }}
            </p>
            <p>
              <b>E-MAIL:</b>
              {{ user.email }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 mb-4">
        <div class="card bg-transparent-gray card-url">
          <h2>URL DE INDICAÇÃO</h2>
          <div>
            <label>URL Paga</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                [value]="user.affiliation?.short_paid_url"
              />
              <button
                class="btn btn-primary"
                (click)="copy(user.affiliation?.short_paid_url)"
                ngbTooltip="Clique aqui para copiar"
              >
                <i class="fa fa-file"></i>
              </button>
            </div>
          </div>
          <div>
            <label>URL Gratuita</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                [value]="user.affiliation?.short_free_url"
              />
              <button
                class="btn btn-primary"
                (click)="copy(user.affiliation?.short_free_url)"
                ngbTooltip="Clique aqui para copiar"
              >
                <i class="fa fa-file"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 mb-4">
        <div class="card bg-transparent-gray">
          <h2>RELATÓRIO DE INDICAÇÃO</h2>
          <div class="field">
            <p>
              <b>MÊS ATUAL:</b>
              {{ partnerInfo.actualIndications | numberFormat }}
            </p>
            <p>
              <b>MÊS PASSADO:</b>
              {{ partnerInfo.lastIndications | numberFormat }}
            </p>
            <p>
              <b>TOTAL:</b> {{ partnerInfo.totalIndications | numberFormat }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="affiliates$ | async as affiliates">
    <div class="row">
      <div class="row toolbar">
        <div class="status">
          <div
            class="active me-1"
            [class.selected]="active"
            (click)="showActives()"
          >
            ATIVOS
          </div>
          <div
            class="inactive"
            [class.selected]="!active"
            (click)="showInactives()"
          >
            INATIVOS
          </div>
        </div>
        <div class="filter" *ngIf="false">
          <i class="fa fa-filter me-1"></i>FILTRO
        </div>
      </div>

      <div class="row mt-2 mb-2">
        <form (submit)="pageChanged(1)">
          <div class="row">
            <div class="col">
              <div class="input-group">
                <input
                  type="text"
                  name="filter"
                  id="filter"
                  class="form-control"
                  placeholder="Pesquisar pelo nome ou e-mail"
                  [(ngModel)]="filter"
                />
                <button class="btn btn-primary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div
        class="card card-user"
        [class.inactive]="!user.active"
        *ngFor="let user of affiliates.data"
      >
        <p class="user">
          <a
            *ngIf="user.phone"
            href="https://wa.me/+55{{ user.phone }}"
            target="_blank"
            ngbTooltip="Enviar mensagem via whatsapp {{ user.phone }}"
            placement="right"
          >
            <i class="fa fa-whatsapp"></i>
          </a>
          <span
            (click)="copyEmail(user)"
            class="ms-2"
            ngbTooltip="E-mail {{ user.email }}. Clique para copiar"
            placement="right"
          >
            <i class="fa fa-envelope-o"></i>
          </span>
          {{ user.name }}
        </p>
        <p class="credits">
          SALDO: {{ user.credits | numberFormat }}
          <i class="fa fa-question-circle" ngbTooltip="Saldo total de SMS"></i>
        </p>
        <button
          class="btn btn-primary"
          *ngIf="isAgency"
          (click)="simulate(user)"
        >
          Acessar conta
        </button>
      </div>

      <p *ngIf="affiliates.data.length === 0" class="card no-clients mt-2">
        Nenhum cliente :(
      </p>

      <app-pagination
        *ngIf="affiliates.data.length > 0"
        [currentPage]="affiliates.current_page"
        [itemsPerPage]="affiliates.per_page"
        [totalItems]="affiliates.total"
        (pageChanged)="pageChanged($event)"
      >
      </app-pagination>
    </div>
  </div>

  <app-modal-pix-key
    [showModal]="showModal"
    (modalClosed)="modalClosed($event)"
  ></app-modal-pix-key>
</div>

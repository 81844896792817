import { ToastService } from './../../../shared/components/toast/toast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Price } from './../../../models/Price';
import { User } from './../../../models/User';
import { RechargesService } from './../../../shared/services/recharges.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'app-add-credits',
  templateUrl: './add-credits.component.html',
  styleUrls: ['./add-credits.component.scss']
})
export class AddCreditsComponent implements OnInit {

  users: User[] = [];
  prices: Price[] = [];

  /**
   * Target da recarga de créditos: sms ou whatsapp : default sms
   */
  target: string = 'sms';

  rechargeForm: UntypedFormGroup;

  constructor(
    private rechargesService: RechargesService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    this.userService
      .listAll()
      .subscribe(users => this.users = users);

    this.rechargesService
      .price()
      .subscribe(prices => this.prices = prices);

    this.rechargeForm = this.fb.group({
      user_id: ['', Validators.required],
      credits: [null, Validators.required],
      price: [null, Validators.required],
      total: [null, Validators.required],
      payment_type: [null, [Validators.required, Validators.minLength(3)]],
      payment_status: [null, [Validators.required, Validators.minLength(3)]],
      target: [this.target, Validators.required]
    });

    this.rechargeForm
      .get('credits')
      .valueChanges.subscribe(credits => this.calculateTotal(credits));

    this.rechargeForm
      .get('total')
      .valueChanges.subscribe(total => this.calculateTargetProductPrice(total));

    this.rechargeForm
      .get('target')
      .valueChanges.subscribe(target => this.targetChanged(target));

    this.route.params
      .subscribe(params => {
        if (params.userId) {
          this.rechargeForm.patchValue({ user_id: params.userId});
        }
      })
  }

  getServiceName() {
    return this.target === 'sms' ? 'SMS' : 'Whatsapp';
  }

  targetChanged(_target: string): void {
    this.target = _target;
    this.calculateTotal(this.rechargeForm.get('credits').value);
  }

  calculateTotal(credits: number): void {
    let total = 0;
    let price = 0;
    let calculate = true;

    const targetPrices = this.prices[this.target];

    targetPrices.forEach(priceInfo => {
      if (calculate && credits <= priceInfo.quantity) {
        price = priceInfo.price;
        calculate = false;
      }
    });

    if (price === 0) {
      price = targetPrices[targetPrices.length - 1].price;
    }

    total = credits * price;

    this.rechargeForm.patchValue({ total: total.toFixed(2) });
  }

  calculateTargetProductPrice(total: number): void {
    const credits = +this.rechargeForm.get('credits').value;

    if (!credits) { return; }

    const servicePrice = total / credits;

    this.rechargeForm.patchValue({ price: servicePrice.toFixed(3) });
  }

  back(): void {
    window.history.back();
  }

  save(): void {
    const formData = this.rechargeForm.getRawValue();

    this.rechargesService
      .add(formData)
      .subscribe(res => {
        this.toast.success('Créditos cadastrados com sucesso! Basta confirmá-los!');
        this.router.navigate(['/recharges']);
      });
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'period'
})
export class PeriodPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      return value.split('-').reverse().join('/');
    }

    return null;
  }

}

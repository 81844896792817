import { Directive, ElementRef, HostBinding, HostListener, Renderer2, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[appPhone]',
  outputs: ['valueChanged']
})
export class PhoneDirective implements OnInit {

  valueChanged = new EventEmitter<string>();

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.changePhone(event);
  }

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    this.changePhone(event);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    this.changePhone(event);
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.setAttribute(elementRef.nativeElement, 'maxlength', '14');
  }

  ngOnInit(): void {
    this.changePhone(this.elementRef.nativeElement.value);
  }

  changePhone(event: any): void {
    let phone = '';

    if (!event) {
      return;
    }

    if (typeof(event) === 'string') {
      phone = event.replace(/\D/g, '');
    } else {
      const events = ['Backspace', 'Tab', 'Delete'];

      if (events.indexOf(event.code) > -1) {
        phone = event.target.value?.replace(/\D/g, '');
        if (phone.length === 10) {
          this.formatPhone(phone);
        }
        return;
      }

      phone = event.target.value?.replace(/\D/g, '');
      if (!phone) {
        this.elementRef.nativeElement.value = '';
        this.valueChanged.emit('');
        return;
      }
    }

    this.formatPhone(phone);
  }

  formatPhone(phone: string): void {
    let formattedPhone = '(';
    for (const phoneNumber of phone) {
      formattedPhone += phoneNumber;
      if (formattedPhone.length === 3) {
        formattedPhone += ')';
      } else if (
        (phone.length < 11 && formattedPhone.length === 8) ||
        (phone.length === 11 && formattedPhone.length === 9)
      ) {
        formattedPhone += '-';
      }
    }

    this.elementRef.nativeElement.value = formattedPhone;

    this.valueChanged.emit(formattedPhone);
  }

}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Video, HelpService } from './help.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  videos: Video[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private helpService: HelpService
  ) { }

  ngOnInit(): void {
    this.helpService
      .tutorial()
      .subscribe(videos => {
        this.videos = videos;

        this.videos.forEach(video => {

          let url = '';
          if (video.type === 'vimeo') {
            url = 'https://player.vimeo.com/video/{:id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
          } else if (video.type === 'youtube') {
            url = 'https://www.youtube.com/embed/{:id}';
          }
          video.url = this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('{:id}', video.id));
        });
      });
  }

}


<app-page-title icon="fa-bullhorn" [title]="pageTitle">
  <a
    class="btn btn-primary btn-icon-right m-2"
    style="border: 1px solid #6c757d; background-color: #6c757d;"
    *ngIf="user.validated && userCreditInfo && userCreditInfo.whatsapp_credits > 0"
    (click)="toggleStats()"
    >
  Ver estatísticas <i class="fa fa-info-circle"></i>
  </a>


  <a
    [routerLink]="['/broadcasts-whatsapp/new']"
    class="btn btn-primary btn-icon-right"
    *ngIf="user.validated && userCreditInfo && userCreditInfo.whatsapp_credits > 0"
  >
    Criar envio em massa <i class="fa fa-whatsapp"></i>
  </a>
</app-page-title>

<div class="card danger mb-2" *ngIf="userCreditInfo && userCreditInfo.whatsapp_credits <= 0">
  <span>Você não possui créditos. Recarregue agora para realizar seu envio em massa
    <a routerLink="/recharges/new">clicando aqui</a>
  </span>
</div>

<form (submit)="pageChanged(page)">
  <div class="row col-md-12">
    <div class="col-md-12 col-sm-12" style="width: fit-content">
      <mat-form-field>
        <mat-label>Data inicial</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="startedMonth"
          name="startedMonth"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <small
          class="text text-danger"
          *ngIf="startedMonth && endedMonth && startedMonth > endedMonth"
          >Período inválido.</small
        >
      </mat-form-field>
    </div>
    <div class="col-md-12 col-sm-12" style="width: fit-content">
      <mat-form-field>
        <mat-label>Data final</mat-label>
        <input
          matInput
          [matDatepicker]="picker2"
          [(ngModel)]="endedMonth"
          name="endedMonth"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <small
          class="text text-danger"
          *ngIf="startedMonth && endedMonth && startedMonth > endedMonth"
          >Período inválido.</small
        >
      </mat-form-field>
    </div>
  </div>
  <div class="row col-md-12 col-sm-12 flex-wrap">
    <div class="row mb-5">
      <div class="col-md-11 col-sm-12">
        <div class="form-group">
          <label>Nome do envio massivo</label>
          <input
            type="text"
            name="text"
            [(ngModel)]="text" 
            class="form-control"
            placeholder="Nome do envio massivo"
          />
        </div>
      </div>

      <div
        style="width: fit-content"
        class="d-flex justify-content-center align-items-center"
      >
        <button
          class="btn btn-primary mt-4"
          type="submit"
          (click)="pageChanged(page)"
        >
          <i class="fa fa-search"></i>
        </button>
      </div>
      <hr class="mt-4" />
      <div class="col-xs-12 mt-2 mb-2 d-flex flex-row">
        <div style="width: 90%">
          <label>Ordenar por</label>
          <select
            name="orderBy"
            id="orderBy"
            class="form-control"
            [(ngModel)]="orderBy"
            [value]="orderBy"
            (change)="orderByChanged()"
          >
            <option value="created_at">Data</option>
            <option value="broadcast_whatsapp_status_id">Status</option>
            <option value="leads_count">Leads</option>
          </select>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-2"
          style="width: fit-content; margin: auto"
        >
          <label>Reverso</label>
          <app-switcher
            [checked]="invertedOrder"
            (change)="invertOrder()"
            style="max-height: 30px !important; user-select: none"
          ></app-switcher>
        </div>
      </div>
    </div>
  </div>
</form>

<div *ngIf="statisticsToggled" class="card d-flex justify-content-center align-items-center mb-4" [class]="[statisticsClass]">
  <div class="col-md-12">
    <h3>Estatísticas</h3>
    <h5 style="margin-left: 1.5rem;">Selecione um intervalo para visualizar a quantidade total de mensagens de broadcasts emitidas:</h5>
  </div>
  <div class="row col-md-11 m-4">
    <div style="width: 50%;">
      <mat-form-field style="width: 100%;">
        <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Data inicial" [(ngModel)]="statisticsStartDate" [disabled]="false"/>
        <ngx-mat-datepicker-toggle matSuffix [for]="picker1"></ngx-mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker1 [showSpinners]="true" [showSeconds]="false"
          [stepHour]="1" [stepMinute]="1" [stepSecond]="1" [touchUi]="false"
          [enableMeridian]="true" [disableMinute]="false" [hideTime]="false">
        </ngx-mat-datetime-picker>
        <small
            class="text text-danger"
            *ngIf="statisticsStartDate && statisticsEndDate && statisticsStartDate > statisticsEndDate"
            >Período inválido.
        </small>
      </mat-form-field>
    </div>
    <div style="width: 50%;">
      <mat-form-field style="width: 100%;">
        <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Data final" [(ngModel)]="statisticsEndDate" [disabled]="false">
        <ngx-mat-datepicker-toggle matSuffix [for]="picker2"></ngx-mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker2 [showSpinners]="true" [showSeconds]="false"
          [stepHour]="1" [stepMinute]="1" [stepSecond]="1" [touchUi]="false"
          [enableMeridian]="true" [disableMinute]="false" [hideTime]="false">
        </ngx-mat-datetime-picker>
        <small
            class="text text-danger"
            *ngIf="statisticsStartDate && statisticsEndDate && statisticsStartDate > statisticsEndDate"
            >Período inválido.
        </small>
      </mat-form-field>
    </div>
    <app-switcher
    display="block"
    style="margin-bottom: 20px;user-select: none;"
    label="Ocultar broadcasts fora deste período abaixo"
    [(checked)]="showBroadcastsStatistics"
  ></app-switcher>
    <button
      class="btn btn-primary"
      (click)="getTotal()"
    >
      <i class="fa fa-check-circle"></i>
      Confirmar
    </button>
  </div>
  <h1 style="margin-left: 1.5rem;" [class]="statisticsTotalClass">
    Total: {{ statisticsTotal | numberFormat }}
  </h1>
</div>

<app-verification-message></app-verification-message>

<div *ngIf="result?.data.length > 0; else notFound">
  <app-accordion-panel
    *ngFor="let broadcastWhatsapp of result.data"
    [title]="getTitle(broadcastWhatsapp)"
    [class]="isCancelled(broadcastWhatsapp) ? 'danger' : 'primary'"
  >
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <label>Envio</label>
        <p>{{ broadcastWhatsapp.lead_list_id ? "Para lista" : "Para números" }}</p>
      </div>
      <div class="col-md-2 col-sm-6">
        <label>Status?</label>
        <p>{{ broadcastWhatsapp.status?.status ?? '-' }}</p>
      </div>
      <div class="col-md-2 col-sm-6">
        <label>Data envio</label>
        <p>
          {{broadcastWhatsapp.scheduled_date | date : "dd/MM/yyyy, H:mm"}}
        </p>
      </div>
      <div class="col-md-1 col-sm-4">
        <label>Leads totais</label>
        <p>{{ broadcastWhatsapp.leads_count }}</p>
      </div>
      <div class="col-md-1 col-sm-4" *ngIf="broadcastWhatsapp.short_url">
        <label>Total clicks</label>
        <p>{{ broadcastWhatsapp.clicks || '0' }}</p>
      </div>
    </div>
    <div class="row" *ngIf="isCancelled(broadcastWhatsapp)">
      <div class="col">
        <label>Motivo do cancelamento</label>
        <p>{{ broadcastWhatsapp.reason }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card gradient-row danger">
          IMPORTANTE: Após a ação de WhatsApp Massivo mudar o status para "enviado", as mensagens têm até 48 horas para serem entregues devido às verificações que podem ocorrer na plataforma WhatsApp.<br><br>
          <b class="b-left">Envios são realizados somente em dias de semana nos horários comerciais.</b>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-2 text-right">
        <a
          class="btn btn-secondary me-1"
          [routerLink]="['/broadcasts-whatsapp/view', broadcastWhatsapp.id]"
        >
          <i class="fa fa-search"></i>
        </a>
        <a
          class="btn btn-primary me-1"
          [routerLink]="['/broadcasts-whatsapp/', broadcastWhatsapp.id]"
          *ngIf="isImporting(broadcastWhatsapp) && isScheduled(broadcastWhatsapp)"
        >
          <i class="fa fa-edit"></i>
        </a>
        <a
          class="btn btn-danger me-1"
          (click)="confirmDelete(broadcastWhatsapp)"
          *ngIf="administrator && isHiddenButton(broadcastWhatsapp)"
        >
          <i class="fa fa-trash"></i>
        </a>

        <a
          class="btn btn-danger me-1"
          (click)="confirmCancel(broadcastWhatsapp)"
          *ngIf="isScheduled(broadcastWhatsapp)"
        >
          <i class="fa fa-ban"></i>
        </a>
      </div>
    </div>
  </app-accordion-panel>
</div>

<app-pagination
  *ngIf="result"
  [currentPage]="result.current_page"
  [itemsPerPage]="result.per_page"
  [totalItems]="result.total"
  (pageChanged)="pageChanged($event)"
></app-pagination>

<ng-template #notFound>
  <div class="card" *ngIf="!loading && user.validated">
    <div class="col">Nenhum envio em massa realizado!</div>
  </div>
</ng-template>

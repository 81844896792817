import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';
import { User } from 'src/app/models/User';

import { Control } from '../../models/Control';
import { Provider } from '../../models/Provider';
import { UserCreditInfo } from '../../models/UserCreditInfo';
import { AuthService } from '../../shared/services/auth.service';
import { ADMINISTRATOR } from 'src/app/shared/services/user.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { ProviderService } from 'src/app/shared/services/Provider.service';

export type ChartOptions = {
  colors: string[];
  series: ApexAxisChartSeries;
  chart: ApexChart;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  grid: ApexGrid;
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  fill: any;
};

@Component({
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss'],
})
export class ProviderComponent implements OnInit, OnDestroy {
  controls: Control[] = [];
  providers: Provider[];

  user: User;
  creditsLow = false;
  creditInfo: UserCreditInfo;
  reservatedCredits: number;

  messagesChartOptions: Partial<ChartOptions>;
  subscriptions: Subscription[] = [];

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private providerService: ProviderService,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.loadProvider();

    this.subscriptions.push(
      this.authService.userChanged.subscribe(() => {
        this.loadProvider();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  loadProvider(): void {
    this.user = this.authService.getUser();

    if (this.isAdministrator) {
      this.subscriptions.push(

        this.providerService.providerCredits().subscribe((providers) => {
          this.providers = providers;

          let credits = 0;
          providers.forEach(provider => {
            credits += provider.credits;
          });
          this.creditsLow = credits < 100;
        })
      );
    }
  }

  toggle(provider: Provider) {
    provider.active = !provider.active;
    const message = provider.active
      ? 'Provedor ativado com sucesso!'
      : 'Provedor desativado com sucesso!';

    this.subscriptions.push(
      this.providerService
        .updateProvider(provider)
        .subscribe(() => this.toastService.success(message))
    );
  }
}

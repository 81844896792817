import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/User';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(
    private http: HttpClient
  ) { }

  listClients(userId: string, page: number = 1, perPage: number = 10): Observable<PaginatedResult<User[]>> {
    return this.http.get<PaginatedResult<User[]>>(
      `${environment.apiUrl}/users/${userId}/clients?page=${page}&per_page=${perPage}`
    );
  }

  addClient(userId: string, clientId: string): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/users/${userId}/clients/${clientId}`, {});
  }

  removeClient(userId: string, clientId: string): Observable<User> {
    return this.http.delete<User>(`${environment.apiUrl}/users/${userId}/clients/${clientId}`);
  }
}

<div
  class="accordion-panel {{ style }}"
  [class.open]="!isHidden"
>
  <div class="title"
    (click)="collapse.toggle()"
  >
    <div class="text">
      {{ title }}
    </div>
    <span>
      <i class="fa fa-chevron-right"
        [class.open]="!isHidden"></i>
    </span>
  </div>
  <div class="body"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isHidden">
    <ng-content></ng-content>
  </div>
</div>

export const environment = {
  production: true,
  title: 'DEV',
  apiUrl: 'https://smsconvert.marcelosantos.bsb.br/api',
  baseUrl: 'https://smsconvert.marcelosantos.bsb.br',
  postbackUrl: 'https://smsconvert.marcelosantos.bsb.br/integrations',
  signatureUrl: 'https://azpague.com.br/cart/U9s6Jx',
  supportLink: 'https://wa.me/555195224391',
  mercadoPago: {
    publicKey: 'TEST-a40c494a-ec85-4700-8210-30d58325c2ee'
  },
  firstYear: 2021
};

<div class="leads card mt-3 elevated">
  <div class="card-body">
    <h3>Postback
      <div>
        <button class="btn btn-primary" ngbTooltip="Copiar postback" (click)="copy('postback')">
          <i class="fa fa-file"></i>
          Copiar
        </button>
        <button class="btn btn-secondary ms-1" ngbTooltip="Voltar" (click)="back()">
          <i class="fa fa-arrow-left"></i>
          Voltar
        </button>
      </div>
    </h3>
    <div *ngIf="postback$ | async as postback">
      <pre>{{ postback.data | json }}</pre>
      <app-hidden-field name="postback" value="{{ postback.data | prettyJson }}"></app-hidden-field>
    </div>
  </div>
</div>

<main>
  <div class="card">
    <div class="card-body">
      <div class="logo">
        <img src="/assets/logo-smsfunnel-login.png" alt="SMS Funnel" title="SMS Funnel" />
      </div>
      <form [formGroup]="passwordResetForm" (submit)="changePassword()">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" class="form-control" id="email" name="email" formControlName="email" #inputEmail />
        </div>
        <div class="form-group d-grid mb-3">
          <button type="submit" class="btn btn-primary" [disabled]="isLoading">
            {{isLoading ? 'Recuperando...' : 'Recuperar'}}
          </button>
        </div>
        <div class="form-group">
          <a [routerLink]="['/']">
            <i class="fa fa-chevron-right"></i>
            Efetuar login
          </a>
        </div>
      </form>
    </div>
  </div>
</main>

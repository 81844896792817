import { Pipe, PipeTransform } from '@angular/core';
import { CANCELLED, IMPORTING, SCHEDULED, SENDING, SENT, SENT_WITH_ERRORS } from "../../models/BroadcastStatus";

@Pipe({
  name: 'broadcastStatus'
})
export class BroadcastStatusPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case CANCELLED:
        return 'Cancelado';
      case IMPORTING:
        return 'Importando leads';
      case SCHEDULED:
        return 'Agendado';
      case SENDING:
        return 'Enviando';
      case SENT:
        return 'Enviado';
      case SENT_WITH_ERRORS:
        return 'Enviado com alguns erros';
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {ProviderAccount} from '../../models/ProviderAccount';
import {Shortener} from '../../models/Shortener';
import {SiteInformation} from '../../models/SiteInformation';

@Injectable({
  providedIn: 'root'
})
export class ShortenerService {

  constructor(
    private http: HttpClient
  ) {}

  listAll(): Observable<Shortener[]> {
    return this.http.get<Shortener[]>(`${environment.apiUrl}/shorteners`);
  }

  findByShortUrl(shortUrl: string): Observable<SiteInformation> {
    shortUrl = encodeURI(shortUrl);
    return this.http.get<SiteInformation>(`${environment.apiUrl}/shorteners/find?short_url=${shortUrl}`);
  }

  delete(shortUrl: string): Observable<any> {
    shortUrl = encodeURI(shortUrl);
    return this.http.delete(`${environment.apiUrl}/shorteners?short_url=${shortUrl}`);
  }
}

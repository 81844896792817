import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Control } from 'src/app/models/Control';
import { environment } from 'src/environments/environment';

import { Lead } from './../../models/Lead';
import { ToastService } from './../components/toast/toast.service';
import { PaginatedResult } from './../model/paginated-result';

@Injectable({ providedIn: 'root' })
export class LeadsService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) { }

  listByLeadListId(leadListId: string, page: number = 1, perPage: number = 5, filter: string = null): Observable<PaginatedResult<Lead[]>> {
    let url = `${environment.apiUrl}/lists/${leadListId}/leads?page=${page}&per_page=${perPage}`;
    if (filter) {
      url += `&filter=${filter}`;
    }
    return this.http.get<PaginatedResult<Lead[]>>(url);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/leads/${id}`);
  }

  import(leadListId: string, file: File, text: string, deleteLeads: boolean = false, sanitize: boolean = true): Observable<any> {
    const formData = new FormData();
    formData.append('lead_list_id', leadListId);
    formData.append('file', file);
    formData.append('text', text);
    formData.append('delete_leads', JSON.stringify(deleteLeads));
    formData.append('sanitize', JSON.stringify(sanitize));

    return this.http.post(`${environment.apiUrl}/leads/import`, formData).pipe(take(1));
  }

  export(leadListId: string): void {
    this.http
      .post(`${environment.apiUrl}/leads/export/${leadListId}`, {}, {
        responseType: 'blob'
      })
      .subscribe(
        (res) => this.downloadFile(res),
        () => this.toastService.error('Erro ao baixar leads.')
      );
  }

  // private download(data) {
  //   const blob = new Blob([data], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   window.open(url);
  // }

  private downloadFile(data) {
    const url = window.URL.createObjectURL(data);
    const e = document.createElement('a');
    e.href = url;
    e.download = url.substr(url.lastIndexOf('/') + 1);
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
  }

  listSequences(leadId: string): Observable<Control[]> {
    return this.http
      .get<Control[]>(`${environment.apiUrl}/leads/${leadId}/sequences`);
  }

  showPostback(leadId: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/leads/${leadId}/postback`);
  }

}

<ng-template #modalPixKey let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ changing ? "Alterar" : "Cadastrar" }} chave pix
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <label>Chave pix</label>
      <input
        class="form-control"
        type="text"
        name="pix_key"
        id="pix_key"
        [(ngModel)]="pixKey"
        (keypress)="checkKey($event)"
      />
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="save()">
        {{ changing ? "Alterar" : "Cadastrar" }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.close('NO')"
      >
        Cancelar
      </button>
    </div>
  </div>
</ng-template>

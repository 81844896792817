<div class="toast-container">
  <div
    class="animate__animated animate__fadeIn toast"
    *ngFor="let toast of toastService.toasts"
    [class]="toast.classname"
  >
    <div><i class="fa fa-2x" [ngClass]="toast.icon"></i></div>
    <div [innerHTML]="toast.text"></div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Platform } from 'src/app/models/Platform';

@Injectable({ providedIn: 'root' })
export class PlatformsService {
  constructor(private http: HttpClient) { }

  listAll(): Observable<Platform[]> {
    return this.http.get<Platform[]>(`${environment.apiUrl}/platforms`);
  }

}

<section *ngIf="listType; else modalListType">
  <app-page-title icon="fa-list" [title]="title">
    <a *ngIf="listId" [routerLink]="['/lists/new']" class="btn btn-info btn-icon-right me-1">
      Nova Lista <i class="fa fa-plus-circle"></i>
    </a>
  </app-page-title>

  <div class="card gradient-row primary" *ngIf="listId && leadList?.importing">
    Os Leads da sua lista estão sendo importados. Assim que finalizar você será notificado.
  </div>

  <form [formGroup]="formList" (submit)="save()">
    <div class="card elevated mb-2">
      <div class="card-body">
        <div class="row" *ngIf="integrationUrl && listType === 'manual'">
          <div class="col text-end">
            <button type="button" class="btn btn-info me-1 mb-1 form-fields"
              [ngbTooltip]="formFieldsTooltip" placement="left">
              Campos integração
              <i class="fa fa-question-circle"></i>
            </button>

            <button type="button" class="btn btn-primary mb-1"
              ngbTooltip="Copiar URL para utilizar em um formulário web" (click)="copy()"
              placement="top"
              *ngIf="isUserValidated"
              >
              URL Integração
              <i class="fa fa-files-o"></i>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="form-group col">
            <label for="name">Nome</label>
            <input type="text" class="form-control" id="name" formControlName="name" #nameInput />
            <small *ngIf="formList.get('name').errors?.required" class="text-danger">
              Preencha o nome da lista
            </small>
            <small *ngIf="formList.get('name').errors?.minlength" class="text-danger">
              O nome da lista deve ter pelo menos 5 caracteres
            </small>
          </div>
        </div>

        <div class="row mt-4" *ngIf="!isManual">
          <div class="form-group col">
            <label>Integração</label>
            <p>{{ integrationName }}</p>
          </div>

          <div class="form-group col">
            <label>Evento</label>
            <p>{{ platformEventName }}</p>
          </div>
        </div>


        <div class="row mt-2" *ngIf="isManual">
          <div class="form-group col">
            <label>
              Tags
              <i class="fa fa-info-circle" ngbTooltip="Digite a tag e pressione ENTER para adicionar"></i>
            </label>
            <tag-input formControlName="tags" placeholder="Adicionar" secondaryPlaceholder="Adicionar"
                       [modelAsStrings]="true" />
          </div>
        </div>

        <div class="col-md-10 form-group mt-3 mb-3" *ngIf="formList.get('manual').value">
          <app-switcher id="dinamic_list" label="Lista dinâmica?" display="block" [(checked)]="dynamic"
                        (change)="toggleDynamic()" placement="bottom" class="customizable-switcher"
                        ngbTooltip="Lista dinâmica permite que você crie seu funil, fazendo com que o Lead seja
                        movimentado de uma lista dinâmica para outra. Quando uma lista é dinamica o lead será
                        retirado de outras listas dinamicas, mantendo-se apenas na ultima lista de inserção.">
          </app-switcher>
        </div>

        <div class="mt-2">
          <button class="btn btn-primary me-1" [disabled]="formList.invalid">
            Salvar
          </button>

          <button type="button" class="btn btn-secondary me-1" (click)="back()">{{listId ? "Voltar" : "Cancelar"}}</button>
        </div>
      </div>
    </div>
  </form>
</section>

<app-leads *ngIf="listId"
           (onImport)="updateList($event)"
           [leadListId]="listId"
           [reload]="reloadLeads"
           [showPostbackButton]="listType !== 'manual'"
           [showImportButton]="!listId || !leadList?.importing"></app-leads>

<ng-template #modalListType>
  <div>
    <app-page-title icon="fa-list" title="Tipo de Lista"></app-page-title>
    <div class="card elevated select-type">
      <div class="row">
        <div class="col mt-3 mb-3">
          <button class="btn btn-lg btn-primary me-2" (click)="setListType('manual')" *ngIf="user.validated">
            <i class="fa fa-list"></i>
            Lista Manual
          </button>
          <button class="btn btn-lg btn-danger" (click)="setListType('automática')">
            <i class="fa fa-rocket"></i>
            Lista Automática
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-hidden-field name="id" [value]="integrationUrl"></app-hidden-field>

<ng-template #formFieldsTooltip>
  <p class="title">Campos do formulário:</p>
  <p class="attributes">
    <span><b>(<i>*</i>)name:</b> Nome do lead. Obrigatório!</span>
    <span><b>(<i>*</i>)phone:</b> Telefone do lead. Obrigatório!</span>
    <span><b>email:</b> E-mail do lead</span>
    <span><b>pix_code:</b> Código do pix para geração da página copia e cola</span>
    <span><b>customized_url:</b> Url à ser enviada nas mensagens de SMS</span>
    <span><b>success_url:</b> Url de redirecionamento (Utilizar apenas em caso de formulário web)</span>
    <br />
    <span>(<i>*</i>) campos obrigatórios</span>
  </p>
</ng-template>

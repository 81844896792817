import { Component, Input } from '@angular/core';

export interface TitleIcon {
  icon: string;
  color: string;
  text?: string;
  tooltip?: string;
  action?: (data?: any) => void;
}

@Component({
  selector: 'app-accordion-card',
  templateUrl: './accordion-card.component.html',
  styleUrls: ['./accordion-card.component.scss']
})
export class AccordionCardComponent {

  @Input() title: string;
  @Input() toggle = true;
  @Input() isHidden = true;
  @Input() data?: any;
  @Input() titleIcons?: TitleIcon[] = [];
  @Input() background = 'light-blue';

  constructor() { }

  clickTitle(collapse: any): void {
    if (collapse && this.toggle) {
      collapse.toggle();
    }
  }

  clickIcon(icon: TitleIcon): void {
    if (icon.action) {
      icon.action(this.data);
    }
  }

  getTitleClass(): any {
    const cssClass = this.background;
    return {cssClass: !this.isHidden};
  }
}

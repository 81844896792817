import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Price } from './../../models/Price';
import { Recharge } from './../../models/Recharge';
import { PaginatedResult } from './../model/paginated-result';
import { UserCreditInfo } from 'src/app/models/UserCreditInfo';
import {RechargeFilter} from '../../models/RechargeFilter';

@Injectable({ providedIn: 'root' })
export class RechargesService {

  constructor(private http: HttpClient) { }

  listPaginated(page: number, perPage: number, filter?: RechargeFilter): Observable<PaginatedResult<Recharge[]>> {
    let url = `${environment.apiUrl}/recharges?page=${page}&per_page=${perPage}`;

    if (filter.text) {
      url += `&text=${filter.text}`;
    }

    if (filter.startDate) {
      url += `&start_date=${filter.startDate}`;
    }
    if (filter.endDate) {
      url += `&end_date=${filter.endDate}`;
    }

    if (filter.order) {
      url += `&order=${filter.order}`;
    }

    if (filter.author) {
      url += `&author=${filter.author}`;
    }

    const orderDirection = filter.orderDirection ? 'DESC' : 'ASC';
    url += `&direction=${orderDirection}`;

    return this.http.get<PaginatedResult<Recharge[]>>(url);
  }

  listall() {
    return this.http.get<object[]>(`${environment.apiUrl}/billing-months`);
  }

  price(): Observable<Price[]> {
    return this.http.get<Price[]>(`${environment.apiUrl}/recharges/price`);
  }

  buy(recharge: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/recharges`, recharge);
  }

  add(recharge: any): Observable<Recharge> {
    return this.http.post<Recharge>(`${environment.apiUrl}/recharges/add`, recharge);
  }

  confirm(id: string): Observable<Recharge> {
    return this.http.put<Recharge>(`${environment.apiUrl}/recharges/${id}/confirm`, {});
  }

  delete(id: string, reason: string): Observable<any> {
    let url = `${environment.apiUrl}/recharges/${id}`;
    if (reason && reason !== '') {
      url += `?reason=${reason}`;
    }
    return this.http.delete(url);
  }

  transfer(id: string, reason: string, to_user: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/recharges/${id}/transfer`, {
      to_user,
      reason
    })
  }

  billingMonths(): Observable<object[]> {
    return this.http.get<object[]>(`${environment.apiUrl}/billing-months`);
  }

  totalBilling(period?: Array<string>): Observable<any> {
   let filter = '?';
   if (period[0]) {
      filter += '&start=' + period[0];
    }
    if (period[1]) {
      filter += '&end=' + period[1];
    }
    return this.http.get(`${environment.apiUrl}/total-billing${filter}`);
  }

  getPixImage(rechargeId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/pix/${rechargeId}`);
  }

  getUserCreditInfo(userId: string): Observable<UserCreditInfo> {
    return this.http.get<UserCreditInfo>(`${environment.apiUrl}/user-credits-info?simulate=${userId}`);
  }

  download(period: Array<string>) {
    let queryString = '?';

    if (period[0]){
      queryString += `&start=${period[0]}`
    }

    if (period[1]) {
      queryString += `&end=${period[1]}`
    }

    this.http.get(`${environment.apiUrl}/credits-info/download${queryString}`, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    }).subscribe(data => {
      const contentType = 'text/csv';
      const blob = new Blob([data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      const fileName = 'smsfunnel_billing_' + (period || 'all') + '_' + Date.now() + '.csv';
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    })
  }

}

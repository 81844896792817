import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../models/User';
import { AuthService } from '../../shared/services/auth.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { PaginatedResult } from '../../shared/model/paginated-result';
import { CLIENT, UserService } from '../../shared/services/user.service';
import { Observable } from 'rxjs';
import { Affiliate } from '../../models/Affiliate';
import { ActivatedRoute, Router } from '@angular/router';
import { AgencyFilter } from 'src/app/models/AgencyFilter';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})
export class AffiliatesComponent implements OnInit {

  page = 1;
  filter = '';
  perPage = 10;

  user: User;
  affiliates$: Observable<PaginatedResult<Affiliate[]>>;

  get isClient(): boolean {
    return this.user.profile_id === CLIENT;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.user = authService.getUser();
  }

  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        this.page = params.page || this.page;
        this.perPage = params.per_page || this.perPage;

        let filter: AgencyFilter = {
          text: this.filter,
          status: true
        }

        this.affiliates$ = this.userService.findAffiliates(this.page, this.perPage, filter);
      });
  }

  copy(url?: string): void {
    navigator.clipboard.writeText(url);
    this.toastService.success('URL copiada com sucesso!');
  }

  pageChanged(page: any): void {
    let queryParams = {
      page,
      filter: this.filter,
      per_page: this.perPage
    };

    if (!this.filter) {
      delete queryParams.filter;
    }

    this.router.navigate(['/affiliates'], { queryParams });
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

  file: File;

  @Input()
  extensionAccepted: string

  @Input()
  class: string;

  @Output()
  fileSelected: EventEmitter<File> = new EventEmitter();

  constructor(
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {}

  changeFile(event: Event): void {
    this.file = undefined;

    const inputFile = event.target as HTMLInputElement;
    const file = inputFile.files[0];

    if (this.extensionAccepted) {
      if (false === this.extensionAccepted.includes(file?.name?.split('.').pop())) {
        this._toastService.error('Arquivo inválido')!
        this.fileSelected.emit(undefined);
        return;
      }
    }

    this.file = file;
    this.fileSelected.emit(file);
  }

}

<div
  class="accordion-card-flat"
  [class.is-open]="!isHidden"
>
  <div class="title {{ style }}"
    (click)="toggle()"
  >
    {{ title }}
    <span>
      <i class="fa fa-chevron-right"></i>
    </span>
  </div>
  <div class="body {{ style }}"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isHidden">
    <ng-content></ng-content>
  </div>
</div>

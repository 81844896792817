import { ToastService } from './../../shared/components/toast/toast.service';
import { ConfirmationService } from './../../shared/components/confirmation-modal/confirmation.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AgencyService } from './../../shared/services/agency.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserPayment } from 'src/app/models/UserPayment';
import { Agency } from 'src/app/models/Agency';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';

@Component({
  selector: 'app-view-partner-payments',
  templateUrl: './view-partner-payments.component.html',
  styleUrls: ['./view-partner-payments.component.scss']
})
export class ViewPartnerPaymentsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  agencyId: string;
  agency$: Observable<Agency>;
  payments$: Observable<PaginatedResult<UserPayment[]>>;

  constructor(
    private agencyService: AgencyService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe(params => this.loadPayments(params.id))
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadPayments(id: string) {
    this.agencyId = id;
    this.agency$ = this.agencyService.findById(id);
    this.payments$ = this.agencyService.listPayments(id);
  }

  delete(id: number) {
    this.subscriptions.push(
      this.agencyService.deletePayment(id).subscribe(() => {
        this.loadPayments(this.agencyId);
        this.toastService.success('Pagamento excluído com sucesso!')
      },
        e => this.toastService.error('Erro ao excluir pagamento.'))
    )
  }

  confirmDelete(payment: UserPayment) {
    this.confirmationService.show(
      `Deseja realmente excluir o pagamento no valor de R$ ${payment.amount}?`,
      () => this.delete(payment.id)
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsSequence } from 'src/app/models/AnalyticsSequence';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-campaign-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['../../analytics.component.scss']
})

export class AnalyticsCampaignsDetailsComponent implements OnInit {
  filter: string;
  fromAt: string;
  toAt: string;
  campaignId: string;
  sequences: PaginatedResult<AnalyticsSequence[]>;

  totalSent: number;
  totalClicks: number;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.campaignId = this.route.snapshot.params.id,
      this.route.queryParams.subscribe((params) => {

        this.fromAt = params.from;
        this.toAt = params.to;

        this.loadFromApi();
      })
    );
  }

  loadFromApi() {
    this.analyticsService.getSequences(this.campaignId, this.fromAt, this.toAt)
    .subscribe(result => {
      this.sequences = result;
      this.totalSent = 0;
      this.totalClicks = 0;

      for (var i = 0; result.data.length > i; i++) {
        if (result.data[i].analytics?.qty_sent != null) {
          this.totalSent += (result.data[i].analytics.qty_sent * 1);
        }

        if (result.data[i].analytics?.qty_clicks != null) {
          this.totalClicks += (result.data[i].analytics.qty_clicks * 1);
        }
      }
    })
  }

  filterBy(): void {
    const queryParams = {
      from: this.fromAt,
      to: this.toAt
    };

    this.router.navigate(['/analytics/campaigns', this.campaignId], { queryParams });
  }

  clearFilter(): void {
    this.router.navigate(['/analytics/campaigns', this.campaignId]);
  }

  filterByDate(value: string): void {
    let from = new Date();
    let to = new Date();

    this.filter = value;

    if (value == 'yesterday') {
      from.setDate(from.getDate()-1);
      to.setDate(to.getDate()-1);
    }

    if (value == '-7 days') {
      from.setDate(from.getDate()-7);
    }

    if (value == '-15 days') {
      from.setDate(from.getDate()-15);
    }

    if (value == 'this month') {
      from.setDate(1);
      to = new Date(to.getFullYear(), to.getMonth()+1, 0);
    }

    if (value == 'last month') {
      from.setMonth(to.getMonth()-1);
      from.setDate(1);
      to.setMonth(to.getMonth()-1);
      to = new Date(to.getFullYear(), to.getMonth()+1, 0);
    }

    const [fromDay, fromMonth, fromYear] = from.toLocaleDateString('pt-br').split('/');
    const [toDay, toMonth, toYear] = to.toLocaleDateString('pt-br').split('/');

    const queryParams = {
      from: `${fromYear}-${fromMonth}-${fromDay}`,
      to: `${toYear}-${toMonth}-${toDay}`
    };

    this.router.navigate(['/analytics/campaigns', this.campaignId], { queryParams });
  }
}

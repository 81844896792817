import { ADMINISTRATOR, PARTNER_CLIENT, AGENCY_CLIENT, CUSTOMER_SUCCESS } from './../services/user.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profile'
})
export class ProfilePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case ADMINISTRATOR:
        return 'Administrator';
      case PARTNER_CLIENT:
        return 'Parceiro';
      case AGENCY_CLIENT:
        return 'Agência';
      case CUSTOMER_SUCCESS:
        return 'Customer Success';
      default:
        return 'Cliente'
    }
  }

}

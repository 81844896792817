import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberFormat' })
export class NumberFormaterPipe implements PipeTransform {

  transform(
    value: any,
    symbol: string = '',
    showDecimal: boolean = false,
    decimalSize: number = 2
  ): any {

    if (symbol === null || symbol === undefined) {
      symbol = "";
    }

    if (showDecimal === undefined) {
      showDecimal = false;
    }

    if (decimalSize === undefined) {
      decimalSize = 2;
    }

    if (value) {
      const floatValue = Number(value).toFixed(decimalSize);

      const splitValue = String(floatValue).split('.');
      const integerPart = splitValue[0];
      const decimalPart = splitValue[1];

      value = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

      if (parseInt(decimalPart, 10) > 0 || showDecimal) {
        value += ',' + decimalPart;
      }

      if (symbol) {
        value = symbol + value;
      }
    }
    if (!value && showDecimal && decimalSize) {
      return symbol + new Number(value).toFixed(decimalSize).replace('.', ',');
    }

    return value;
  }

}

<div class="messages-report">
  <app-page-title icon="fa-paper-plane" title="Mensagens Enviadas"></app-page-title>

  <div class="row" *ngIf="error">
    <div class="col">
      <p class="card gradient-row danger">{{ error }}</p>
    </div>
  </div>

  <form (submit)="search()">
  <div class="row">
    <div class="col-md-6">
      <label>Mês</label>
      <select
        name="month"
        id="month"
        class="form-control"
        [(ngModel)] = "month"
        (change)="reset()"
      >
        <option value="0">Selecione um mês</option>
        <option *ngFor="let month of months" [value]="month.number">{{ month.name }}</option>
      </select>
    </div>
    <div class="col-md-6">
      <label>Ano</label>
      <input type="number" class="form-control" name="year" id="year" (change)="reset()" (keydown)="reset()"
            [(ngModel)]="year" placeholder="Digite o ano" />
    </div>
  </div>

  <div class="row mt-2 mb-2">
    <div class="col">
      <button class="btn btn-primary" type="submit">
        <i class="fa fa-search"></i>
        Pesquisar
      </button>
    </div>
  </div>
  </form>

  <div *ngIf="result?.length > 0; else notFound">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Data</th>
          <th>Quantidade de envios</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let userCounter of result">
          <td>{{ userCounter.counter_date | date:"dd/MM/yyyy" }}</td>
          <td>{{ userCounter.quantity }}</td>
          <td>
            <a [routerLink]="['./', userCounter.counter_date]" *ngIf="userCounter.quantity > 0; else noLink">
              <i class="fa fa-envelope" matTooltip="Visualizar SMS enviados do dia"></i>
            </a>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="text-end"><b>Total enviado:</b></td>
          <td colspan="2">{{ totalSent }}</td>
        </tr>
      </tfoot>
    </table>
  </div>

  <ng-template #notFound>
    <div class="card" *ngIf="searchClicked">
      Nenhuma mensagem enviada
    </div>
  </ng-template>

  <ng-template #noLink>
    <i class="fa fa-envelope disabled" matTooltip="Nenhum SMS enviado nesta data"></i>
  </ng-template>
</div>

import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthService } from './../services/auth.service';
import { ToastService } from './../components/toast/toast.service';
import { LoadingService } from './../components/loading/loading.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LoadingInterceptor implements HttpInterceptor {

  constructor(
    private loadingService: LoadingService,
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    const showLoading = !req.url.includes('logout') && !req.url.includes('no-loading');
    const urlList = document.URL.includes('lists'); 

    return next
      .handle(req)
      .pipe(tap(event => {
        if (event instanceof HttpResponse && !urlList) {
          this.loadingService.stop();
        } else if (showLoading && !urlList) {
          this.loadingService.start();
        }
      }))
      .pipe(catchError(err => {
        this.loadingService.stop();

        if (err.status === 0 && showLoading) {
          this.toastService.error('Erro ao executar operação! Tente novamente mais tarde');
          return of();
        }

        if ((err.status === 403 || err.status === 401)
          && err.error.status?.toLowerCase().includes('token')) {
          this.authService.removeData();
          this.router.navigate(['/login']);
        }

        if (err.status === 401 && !err.url.includes('login')) {
          const message = err.error.message || 'Acesso negado!';
          this.toastService.error(message);
        }

        if (err.status === 422 && err.error) {
          const message = [];

          for (const attr in err.error) {
            message.push(err.error[attr]);
          }

          if (message.length > 0) {
            this.toastService.error(message.join(', '));
          }
        }
        throw err;
      }));
  }
}

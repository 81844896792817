import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material.module';

import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { MenuComponent } from './menu/menu.component';
import { MenuService } from './menu/menu.service';
import { PageTitleComponent } from './page-title/page-title.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignUpSuccessComponent } from './sign-up/sign-up-success/sign-up-success.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LayoutComponent,
    MenuComponent,
    PageTitleComponent,
    SignUpComponent,
    SignUpSuccessComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgbTooltipModule,
    RouterModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    LayoutComponent,
    PageTitleComponent
  ],
  providers: [MenuService]
})
export class LayoutModule { }

import { BroadcastWhatsappContact } from './../../models/BroadcastWhatsappContact';

import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

import { BroadcastWhatsapp } from 'src/app/models/BroadcastWhatsapp';
import { PaginatedResult } from './../model/paginated-result';
import { take } from 'rxjs/operators';
import { AuthService } from "./auth.service";
import { CANCELLED, IMPORTING } from 'src/app/models/BroadcastStatus';

const STORAGE_KEY = 'SMSFUNNEL::BROADCASTS-WHATSAPP';

const importFinished = new EventEmitter<BroadcastWhatsapp>();

@Injectable({ providedIn: 'root' })
export class BroadcastsWhatsappService {

  get importFinished() {
    return importFinished;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  isImporting(broadcastWhatsapp: BroadcastWhatsapp): boolean {
    return broadcastWhatsapp.importing || broadcastWhatsapp.broadcast_whatsapp_status_id === IMPORTING;
  }

  isCancelled(broadcastWhatsapp: BroadcastWhatsapp): boolean {
    return broadcastWhatsapp.error || broadcastWhatsapp.broadcast_whatsapp_status_id === CANCELLED;
  }

  findById(id: string): Observable<BroadcastWhatsapp> {
    return this.http.get<BroadcastWhatsapp>(`${environment.apiUrl}/broadcasts-whatsapp/${id}`);
  }

  status(id: string): Observable<BroadcastWhatsapp> {
    const url = `${environment.apiUrl}/broadcasts-whatsapp/${id}/status?no-loading=true`;
    return this.http.get<BroadcastWhatsapp>(url).pipe(take(1));
  }

  listContacts(id: string, page: number = 1, perPage: number = 15): Observable<PaginatedResult<BroadcastWhatsappContact[]>> {
    return this.http.get<PaginatedResult<BroadcastWhatsappContact[]>>
      (`${environment.apiUrl}/broadcasts-whatsapp/${id}/contacts?page=${page}&per_page=${perPage}`);
  }

  listPaginated(page: number = 1, perPage: number = 15, startDate = null, endDate = null, text = null, orderBy = null, invertedOrder= null, status= null): Observable<PaginatedResult<BroadcastWhatsapp[]>> {
    let url = `${environment.apiUrl}/broadcasts-whatsapp?page=${page}&per_page=${perPage}`

    if (startDate) {
      url += `&startDate=${startDate}`
    }

    if (endDate) {
      url += `&endDate=${endDate}`
    }

    if (text) {
      url += `&text=${text}`
    }

    if (orderBy) {
      url += `&orderBy=${orderBy}`
    }

    if (invertedOrder) {
      url += `&invertedOrder=${invertedOrder}`
    }

    return this.http.get<PaginatedResult<BroadcastWhatsapp[]>>(url);
  }

  send(broadcastWhatsapp: BroadcastWhatsapp): Observable<BroadcastWhatsapp> {
    return this.http.post<BroadcastWhatsapp>(`${environment.apiUrl}/broadcasts-whatsapp`, broadcastWhatsapp);
  }

  update(broadcastWhatsapp: BroadcastWhatsapp): Observable<BroadcastWhatsapp> {
    return this.http.put<BroadcastWhatsapp>(`${environment.apiUrl}/broadcasts-whatsapp/${broadcastWhatsapp.id}`, broadcastWhatsapp);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/broadcasts-whatsapp/${id}`);
  }

  cancel(id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/broadcasts-whatsapp/${id}/cancel`, {});
  }

  getTotalSent(statisticsStartDate = null, statisticsEndDate = null): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/broadcasts-whatsapp/total-sent?endDate=${statisticsEndDate}&startDate=${statisticsStartDate}`);
  }
}

<div class="sign-up-success">
  <div class="card message">
    <h1>
      Cadastro realizado com sucesso
      <i class="fa fa-check" aria-hidden="true"></i>
    </h1>
    <h3>
      Seu cadastro está sendo analisado pela nossa equipe e em breve você
      receberá um e-mail com as instruções de acesso ao sistema!
    </h3>
    <a
      class="btn btn-primary"
      href="https://www.smsfunnel.com.br/#NOSSOS-PLANOS"
      >Conheça nossos planos</a
    >
  </div>
</div>

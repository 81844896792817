import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Integration } from '../../models/Integration';
import { Postback } from '../../models/Postback';
import { PaginatedResult } from '../model/paginated-result';

const getIntegrationUrl = (integrationId: string): string => {
  return `${environment.postbackUrl}/${integrationId}`;
};

export const ADOOREI = 'c5bdc8c4-0f81-4974-86c1-fffd633c7716';
export const APPMAX = 'c7074699-86cb-11eb-8311-f0d4e2ef5168';
export const ATIVO_PAY = '0646680f-e05a-405d-8cc7-c6a9c97952ed';
export const AZPAG = '34b02b7b-0059-11ec-aa16-0242c0a80303';
export const BAGY = '0fddbfc4-9aae-498f-9286-de0e653fb201';
export const BRAIP = '292bce14-9b9a-11eb-921e-f0d4e2ef5168';
export const BR4BET = '6d61b96e-15b4-435d-82b1-704cf232bddf';
export const CARTPANDA = 'd25b493b-40cf-42fb-94a6-c14103d0a4f4';
export const CONEXAOPREMIUM = '5c241271-6b71-49c0-9cfb-b9c7538b663b';
export const CONVVERT = '9bc5518f-d444-4351-a77b-ba708279bc56';
export const DOPPUS = '7f7fef1f-96e5-4087-900b-3d2a8173dd11';
export const EDUZZ = '5b1bc403-82ef-11ec-a08f-1a170a0936bf';
export const EVIPES = '676968dc-ad0a-11eb-9474-542c137d6205';
export const FLEXY = '02ab411a-6831-4e20-8422-34d478a7ebf7';
export const GURU = '61a22b41-883a-11ec-bba8-0242ac110004';
export const HOTMART = '07917be1-c221-11eb-a4c9-f0d4e2ef5168';
export const ITECH = 'e782e8cf-af6c-4f57-a613-3cd8d1419ece';
export const KIWIFY = 'e798bce4-6ca8-4cf6-a673-4e7c93d6cd07';
export const LPQV = 'ea8b8d47-d282-4a42-a1c2-26711348a6e8';
export const MONEPAY = '5838a977-e606-40d5-8653-8e4fad9d570c';
export const MONETIZZE = '0f09ccfa-8b53-11eb-a9be-f0d4e2ef5168';
export const NEXUS_IGAMMING = 'f3f7a1a6-964c-4a74-a5f6-ded4db84f874';
export const SUBWAY_PIX = '50bf228a-4c11-4da8-a669-aa4eacf5fda1';
export const PAGSEGURO = '2bdeac27-b966-4772-8be5-252e80208c56';
export const PAYT = 'c7ca343f-8a89-11ec-8032-0242ac110005';
export const PERFECTPAY = 'ee39a3d2-20d7-11ec-8cba-0242c0a80303';
export const THEMART = '7aaa4d3f-0282-44c2-9c4c-a7a91a25e3eb';
export const TICTO = '2c00fcaa-698b-4e9e-843a-0d7fab3f0ada';
export const TKITECNOLOGIA = '1abce142-0951-4d5c-9404-cc0682963d14';
export const TRIBOPAY = '57de1a57-ce67-4978-8824-dd9b523d4ebf';
export const YAMPI = 'e4725c09-ae10-11eb-9474-542c137d6205';
export const YEVER = '0bdfbf8e-d5ab-426a-8595-1517289e3a57';
export const BEARPAY = '3b7115f2-ebf6-481a-abb6-9cce6ea39a84';
export const CLOUD_SOLUCOES = 'b9323e0b-503d-43e8-9da2-1688213a8dd7';
export const HOFFICEPAY = '8c5d4466-2b5a-45d4-94ec-5959cc411fa8';
export const IEXCLUSIVEPAY = '5141ce5d-1bc6-4cc9-a8e3-93ba1d14cdb9';
export const LOGICC = '7b23cd75-cd45-496b-bf4b-54de1ce6aa54';
export const ORBITA = '5b9a47da-a538-46c4-951c-8b9c56ec9498';
export const RUSHPAY = '07e56d94-e710-42fe-8e3d-fb74653e4524';
export const ZENITH = '200a20ce-4361-4017-bcef-debdf04ccd50';
export const ZENPAY = '42477999-ef8b-4467-8dd8-80e2ee59ace2';

@Injectable({ providedIn: 'root' })
export class IntegrationsService {

  constructor(private http: HttpClient) { }

  findById(id: string, postback: boolean = false): Observable<Integration> {
    let url = `${environment.apiUrl}/integrations/${id}`;

    if (postback) {
      url += `/postbacks`;
    }

    return this.http.get<Integration>(url)
      .pipe(map(integration => {
        integration.url = getIntegrationUrl(integration.id);
        return integration;
      }));
  }

  listAll(): Observable<Integration[]> {
    return this.http.get<Integration[]>(`${environment.apiUrl}/integrations`)
      .pipe(map(integrations => {
        integrations.forEach(integration => integration.url = getIntegrationUrl(integration.id));
        return integrations;
      }));
  }

  listPaginated(page: number, perPage: number, active: boolean = null): Observable<PaginatedResult<Integration[]>> {
    let url = `${environment.apiUrl}/integrations?page=${page}&per_page=${perPage}`;
    if (active !== null) {
      url += '&active=' + active;
    }
    return this.http.get<PaginatedResult<Integration[]>>(url)
      .pipe(map(paginatedResult => {
        paginatedResult.data.forEach(integration => integration.url = getIntegrationUrl(integration.id));
        return paginatedResult;
      }));
  }

  save(integration: Integration): Observable<Integration> {
    if (integration.id) {
      return this.http.put<Integration>(`${environment.apiUrl}/integrations/${integration.id}`, integration);
    }

    return this.http.post<Integration>(`${environment.apiUrl}/integrations`, integration);
  }

  delete(integrationId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/integrations/${integrationId}`);
  }

  listPostbacks(integrationId: string): Observable<Postback[]> {
    return this.http.get<Postback[]>(`${environment.apiUrl}/integrations/${integrationId}/postbacks`);
  }

  createCartpandaWebhooks(integrationUrl: string, storeId: string, storeToken: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/integrations/cartpanda`, {
      integrationUrl,
      storeId,
      storeToken
    });
  }

  createLeadLists(integrationId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/integrations/${integrationId}/create-lists`, {});
  }
}

<app-page-title icon="fa-pie-chart" title="Relatórios (beta)"></app-page-title>

<section class="reports">
  <div class="card mb-2">
    <a href="/#/analytics/campaigns" title="Relatório de cliques em links" class="title">Relatório de cliques em links</a>
    <p class="mb-0 small">Veja os cliques recebidos através das campanhas. Visualize a quantidade de envios, os cliques e o CTR por campanha e por sequência de mensagem enviada.</p>
  </div>

  <!-- <div class="card mb-2">
    <a href="/#/analytics/sales">Relatório analítico de vendas</a>
    <p>Veja a performance das conversões através de UTMs por período e também ações sem UTM source que foram impusionadas pelo envio de SMS.</p>
  </div> -->
</section>

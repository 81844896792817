import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent {

  @Input() isCheckbox: boolean;
  @Input() checked: boolean;
  @Input() name: string;
  @Input() text: string;
  @Input() value: any;

  @Output() onSelect = new EventEmitter<any>();

  onClick(): void {
    if (this.isCheckbox) {
      this.checked = !this.checked;
      this.onSelect.emit(this.checked);
    } else {
      this.onSelect.emit(this.value);
    }

  }

}

import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { ToastService } from './../../shared/components/toast/toast.service';
import { UserService } from './../../shared/services/user.service';

@Component({
  selector: 'app-password-reset-start',
  templateUrl: './password-reset-start.component.html',
  styleUrls: ['./password-reset-start.component.scss']
})
export class PasswordResetStartComponent implements OnInit {

  passwordResetForm: UntypedFormGroup;
  isLoading: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.passwordResetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  changePassword(): void {
    this.isLoading = true;
    const email = this.passwordResetForm.get('email').value;
    this.userService
      .startPasswordReset(email)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(() => {
        this.toastService.success('Foi enviado para seu e-mail as instruções para uma nova senha');
        this.router.navigate(['/password-reset-success'], { queryParams: { email }});
      });
  }

}

export const CLIENT = '3';
export const LIST = '2';
export const NUMBER = '1';

const EnumBroadcastTypeId = {
  client: CLIENT,
  list: LIST,
  number: NUMBER
}

export interface BroadcastWhatsappType {
  id: string;
  name: string;
}

export const getBroadcastTypeId = (broadcastType: string): string => {
  return EnumBroadcastTypeId[broadcastType] || null;
}

export const getBroadcastTypeName = (id: string): string => {
  if (id === CLIENT) {
    return 'client';
  }

  if (id === LIST) {
    return 'list';
  }

  return 'number';
}


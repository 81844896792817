import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET as BillingGET, PUT as BillingPUT} from 'src/app/models/Billing';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FinancialService {
  constructor(private http: HttpClient) { }

  getInfo() : Observable<BillingGET> {
    return this.http.get<BillingGET>(`${environment.apiUrl}/billing/config`);
  }
  
  updateInfo(cost: string | number, users: any[], description?: string) : Observable<BillingPUT> {
    return this.http.put<BillingPUT>(`${environment.apiUrl}/billing/config`, {
      cost,
      description,
      users
    });
  }
}

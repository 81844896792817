import { Injectable } from '@angular/core';
import { AppService } from '../services/app.service';

interface ErrorMessage {
  word: string;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class BlacklistValidatorService {

  constructor(private appService: AppService) {
    if (!this.appService.words) {
      this.appService.blackList().subscribe();
    }
  }

  validate(text: string): ErrorMessage {
    let error = null;
    const myWords = text?.toLocaleLowerCase().split(' ');
    if (myWords) {
      this.appService.words.forEach(word => {
        if (myWords.includes(word.toLocaleLowerCase())) {
          error = {
            word,
            message: `Você utilizou uma expressão que viola nossos termos de uso, se você acredita que isso seja um erro avise o suporte, palavra:  ${word}`
          };
          return;
        }
      });
    }

    return error;
  }
}

<app-page-title title="Provedores" icon="fa-tachometer"></app-page-title>

<app-plan-message></app-plan-message>

<div class="providers">
  <div class="row" *ngIf="providers; else loadingProviders">
    <div class="col-12" *ngIf="creditsLow">
      <div class="card bg-danger">
        Créditos do(s) provedor(es) abaixo do mínimo
      </div>
    </div>
    <div class="col-md-4 col-sm-12" *ngFor="let provider of providers">
      <div class="card credits strech" [class.danger]="!provider.active">
        <h2>{{ provider.name }}</h2>
        <p><i class="fa fa-money"></i> Total de créditos no provedor</p>
        <div *ngIf="provider.credits > 0; else noCredits">
          <h3 class="fa-2x">
            <b>{{ provider.credits | numberFormat }}</b>
          </h3>
        </div>

        <ng-template #noCredits>
          <h3 class="mt-3">Provedor pós-pago</h3>
        </ng-template>

        <app-switcher
          class="switcher"
          [checked]="provider.active"
          (checkedChange)="toggle(provider)"
        ></app-switcher>
      </div>
    </div>

    <div class="col-md-4 col-sm-12">
      <div class="card credits">
        <h2>Reservados</h2>
        <p><i class="fa fa-user"></i> Total de créditos reservados</p>
        <h3 class="fa-2x">
          <b>{{ reservatedCredits | numberFormat }}</b>
        </h3>
      </div>
    </div>
  </div>

  <ng-template #loadingProviders>
    <div class="row" *ngIf="isAdministrator">
      <div class="col">
        <div class="card credits">Carregando créditos dos provedores...</div>
      </div>
    </div>
  </ng-template>

  <div class="row">
    <apx-chart
      *ngIf="messagesChartOptions"
      style="width: 100%"
      [series]="messagesChartOptions.series"
      [chart]="messagesChartOptions.chart"
      [title]="messagesChartOptions.title"
      [tooltip]="messagesChartOptions.tooltip"
      [colors]="messagesChartOptions.colors"
      [grid]="messagesChartOptions.grid"
      [xaxis]="messagesChartOptions.xaxis"
      [dataLabels]="messagesChartOptions.dataLabels"
    >
    </apx-chart>
  </div>
</div>

<ng-template #notFound>
  <div class="card">Nenhuma mensagem enviada!</div>
</ng-template>

<div class="col-12">
  <div class="card gradient-row danger col-5">
    Importe seus numeros no padrão (99) 99999-9999!
  </div>
</div>
<div class="card mt-2 mb-4" *ngIf="!user.validated || !user.massive_sms_enabled">
  <p>ATENÇÃO: O envio de mensagens SMS por meio de Broadcast e a criação de listas manuais estão bloqueadas até o final
    análise da sua documentação. Para agilizar a liberação destas funcionalidades, entre em contato conosco através do suporte clicando
    <a [href]="supportLink" target="_blank">aqui</a>.</p>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyJson'
})
export class PrettyJsonPipe implements PipeTransform {

  transform(value: any): string {
    if (!value) {
      return null;
    }

    return JSON.stringify(value);
  }

}

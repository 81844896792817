import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { LoadingService } from './../../../shared/components/loading/loading.service';
import { ToastService } from './../../../shared/components/toast/toast.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {

  src: string;
  promocode: string;
  address: object;
  addressSearch: boolean;
  signUpFrom: UntypedFormGroup;
  documentSearch: boolean;
  documentExists: boolean;
  emailExists: boolean;

  subscriptions: Subscription[] = [];

  operationTypes: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private router: Router
  ) {
    this.signUpFrom = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10)]],
      document: ['', [Validators.required, Validators.minLength(14)]],
      postcode: ['', [Validators.required, Validators.minLength(9)]],
      street: ['', Validators.required],
      number: ['', Validators.required],
      complement: [''],
      district: ['', Validators.required],
      city: ['', Validators.required],
      region: ['', Validators.required],
      promocode: [this.promocode],
      src: [''],
      operation_type: ['']
    });

  }

  ngOnInit(): void {
    this.userService.getOperationTypes().subscribe(operation_types => this.operationTypes = operation_types);
    this.subscriptions.push(this.routeParams());
    this.subscriptions.push(this.queryParams());
    this.subscriptions.push(this.emailValueChanges());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  routeParams(): Subscription {
    return this.route.params.subscribe(params => {
      if (!params.code) {
        window.location.href = 'https://smsfunnel.com.br';
        return;
      }
      this.promocode = params.code;
    });
  }

  queryParams(): Subscription {
    return this.route.queryParams.subscribe(params => {
      this.src = params.src;
    })
  }

  emailValueChanges(): Subscription {
    return this.signUpFrom.get('email').valueChanges
      .subscribe(() => this.emailExists = false);
  }

  phoneChanged(phone: string): void {
    this.signUpFrom.patchValue({ phone });
  }

  documentChanged(document: string): void {
    if (document?.length === 14) {
      if (!this.documentSearch) {
        this.documentExists = false;
        this.documentSearch = true;
        this.userService.search(document)
          .subscribe(u => {
            if (u.found == true) {
              this.documentExists = true;
            }
          });
      }
    } else {
      this.documentExists = false;
      this.documentSearch = false;
    }

    this.signUpFrom.patchValue({ document });
  }

  cepChanged(postcode: string): void {
    this.signUpFrom.patchValue({ postcode });

    if (postcode.length === 9) {
      if (this.addressSearch) {
        return;
      }

      this.addressSearch = true;
      this.loadingService.start();
      const cepNumbers = postcode.replace(/\D/, '');
      fetch(`https://viacep.com.br/ws/${cepNumbers}/json`)
        .then(res => res.json().then(data => {
          this.address = data;

          this.signUpFrom.patchValue({
            street: data.logradouro,
            city: data.localidade,
            district: data.bairro,
            complement: data.complemento,
            region: data.uf
          });

          setTimeout(() => {
            document.getElementById('number')?.focus();
          }, 200);
        })).finally(() => this.loadingService.stop());
    } else {
      this.addressSearch = false;
    }
  }

  signUp(): void {
    const value = this.signUpFrom.getRawValue();

    if (!value.promocode) {
      value.promocode = this.promocode;
    }

    if (!value.src) {
      value.src = this.src;
    }

    if (this.documentExists || this.emailExists) {
      return;
    }

    const email = this.signUpFrom.get('email');
    if (email.value.trim() === '' || email.errors) {
      return;
    }

    this.emailExists = false;
    this.userService.search('', email.value).subscribe((r) => 
      {
        if (r.found == true) {
          this.emailExists = true;
          setTimeout(() => this.emailExists = false, 5000)
          return;
        }else {
            this.userService.signUp(value)
              .pipe(take(1))
              .subscribe(() => {
                this.toastService.success('Cadastro enviado com sucesso!');
                this.router.navigate(['/sign-up/success']);
              });
        }
      }
    );
  }

}

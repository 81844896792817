import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intervalType'
})
export class IntervalTypePipe implements PipeTransform {
  MINUTE = 1;
  HOUR = 2;
  DAY = 3;
  WEEK = 4;
  MONTH = 5;

  transform(value: number): string {
    const returns = [
      '',
      'minuto(s)',
      'hora(s)',
      'dia(s)',
      'semana(s)',
      'mês(ses)'
    ];

    return returns[value] || '';
  }

}

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../model/paginated-result';
import { ConsumptionReportFilter, DownloadCsvResponse } from 'src/app/models/Consumption-report';

@Injectable({ providedIn: 'root' })
export class ConsumptionReportService {
  constructor(private http: HttpClient) {}  

  getConsumptionReport(page: number, perPage: number, filter: ConsumptionReportFilter): Observable<PaginatedResult<ConsumptionReportFilter[]>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('per_page', perPage.toString())      

      if (filter.start_month) {
        params = params.set('start_month', filter.start_month.toString());
      }
      if (filter.end_month) {
        params = params.set('end_month', filter.end_month.toString());
      }
      if (filter.year) {
        params = params.set('year', filter.year.toString());
      }
      if (filter.user_id) {
        params = params.set('user_id', filter.user_id);
      }

    return this.http.get<PaginatedResult<ConsumptionReportFilter[]>>(`${environment.apiUrl}/consumption-report`, { params });
  }

  downloadCsv(filter: ConsumptionReportFilter): Observable<DownloadCsvResponse> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<DownloadCsvResponse>(`${environment.apiUrl}/consumption-report/export-csv`, { filter }, {
      headers,
    });
  }
}

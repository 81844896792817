import { CopyService } from './../../../shared/services/copy.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { LeadsService } from './../../../shared/services/leads.service';

@Component({
  selector: 'app-lead-postback',
  templateUrl: './lead-postback.component.html',
  styleUrls: ['./lead-postback.component.scss']
})
export class LeadPostbackComponent implements OnInit {

  leadId: string;
  postback$: Observable<any>;

  constructor(
    private leadService: LeadsService,
    private route: ActivatedRoute,
    private copyService: CopyService
  ) {
    this.route.params
      .pipe(take(1))
      .subscribe(params => {
        if (params.id) {
          this.leadId = params.id;
        }
      });
  }

  ngOnInit(): void {
    if (this.leadId) {
      this.postback$ = this.leadService.showPostback(this.leadId);
    }
  }

  back() {
    window.history.back();
  }

  copy(postback: string) {
    this.copyService.copy(postback, 'Postback copiado com sucesso!');
  }

}

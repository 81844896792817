import { AuthService } from './../../shared/services/auth.service';
import { Subscription } from 'rxjs';
import {Component, OnInit, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SmsService } from './../../shared/services/sms.service';

import {UserCounter} from '../../models/UserCounter';
import {UtilService} from '../../shared/services/util.service';
import {Month} from '../../models/Month';

@Component({
  selector: 'app-messages-report',
  templateUrl: './messages-report.component.html',
  styleUrls: ['./messages-report.component.scss']
})
export class MessagesReportComponent implements OnInit, OnDestroy {

  month = 0;
  year: number;
  error = null;
  searchClicked = false;
  months: Month[];
  get totalSent(): number {
    return this.result.reduce((prev, next) => prev + next.quantity, 0);
  }

  result: UserCounter[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private smsService: SmsService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.months = this.utilService.listMonths();

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.month = +params.month;
        this.year = +params.year;

        this.searchClicked = !!(this.month && this.year);

        this.listDailySent();
      }),

      this.authService.userChanged.subscribe(() => this.listDailySent())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  listDailySent(): void {
    if (this.searchClicked) {
      this.subscriptions.push(
        this.smsService.listMessagesByMonthAndyear(this.month, this.year)
          .subscribe(result => this.result = result)
      );

      return;
    }

    this.result = [];
  }

  search(): void {
    const date = new Date();
    this.error = null;
    if (!this.month || !this.year) {
      this.error = 'Mês e Ano são obrigatórios';
      return;
    } else if (+this.year > date.getFullYear()) {
      this.error = 'O ano deve ser menor que o atual';
      return;
    } else if (+this.year === date.getFullYear() && +this.month > date.getMonth() + 1) {
      this.error = 'O mês não pode ser posterior ao atual';
      return;
    }

    const queryParams = {
      month: this.month,
      year: this.year
    };

    this.router.navigate(['/messages-report'], { queryParams });
  }

  reset(): void {
    this.error = null;
  }

}

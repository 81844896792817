<app-page-title icon="fa-key" [title]="title"></app-page-title>
<div class="card elevated">
  <form [formGroup]="changePasswordForm" class="card-body" (submit)="update()">
    <div class="form-group mb-2 mb-2">
      <label for="email">E-mail</label>
      <span class="form-control">{{ user.email }}</span>
    </div>
    <div class="form-group mb-2 mb-2">
      <label for="password">Senha atual</label>
      <input
        type="password"
        class="form-control"
        formControlName="password"
        autofocus
        #password
      />
      <span *ngIf="changePasswordForm.get('password').touched">
        <small
          class="text text-danger"
          *ngIf="changePasswordForm.get('password').errors?.required"
        >
          Senha atual é obrigatória
        </small>
      </span>
    </div>

    <div class="form-group mb-2 mb-2">
      <label for="new_password">Nova senha</label>
      <input
        type="password"
        class="form-control"
        formControlName="new_password"
      />
      <span *ngIf="changePasswordForm.get('new_password').touched">
        <small
          class="text text-danger"
          *ngIf="changePasswordForm.get('new_password').errors?.required"
        >
          Nova senha é obrigatória
        </small>
        <small
          class="text text-danger"
          *ngIf="changePasswordForm.get('new_password').errors?.minlength"
        >
          A nova senha deve ter no mínimo 8 caracteres
        </small>
      </span>
    </div>

    <div class="form-group mb-2 mb-2">
      <label for="password_confirmation">Confirmação da nova senha</label>
      <input
        type="password"
        class="form-control"
        formControlName="password_confirmation"
      />
      <span *ngIf="changePasswordForm.get('password_confirmation').touched">
        <small
          class="text text-danger"
          *ngIf="
            changePasswordForm.get('password_confirmation').errors?.required
          "
        >
          Confirmação da nova senha é obrigatória
        </small>
        <small
          class="text text-danger"
          *ngIf="
            changePasswordForm.get('password_confirmation').errors?.minlength
          "
        >
          A nova senha deve ter no mínimo 8 caracteres
        </small>
        <small
          class="text text-danger"
          *ngIf="
            changePasswordForm.get('password_confirmation').errors?.different
          "
        >
          Nova senha não foi confirmada corretamente
        </small>
      </span>
    </div>

    <button class="btn btn-primary" [disabled]="changePasswordForm.invalid">
      Alterar
    </button>
  </form>
</div>

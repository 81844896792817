import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hidden-field',
  templateUrl: './hidden-field.component.html',
  styleUrls: ['./hidden-field.component.scss']
})
export class HiddenFieldComponent {

  @Input() name: string;
  @Input() value: string;
  @Input() show: string;

  constructor() { }

}

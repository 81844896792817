import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";

export interface ConfirmationProps {
  message: string;
  show: boolean;
  callbackYes?: () => any
  callbackNo?: () => any
}

@Injectable({providedIn: 'root'})
export class ConfirmationService {

  modalVisibility = new Subject<ConfirmationProps>();

  show(message: string, callbackYes: any = null, callbackNo: any = null): void {
    this.modalVisibility.next({
      message,
      show: true,
      callbackYes,
      callbackNo
    });
  }

  hide(): void {
    this.modalVisibility.next({
      message: null,
      show: false
    });
  }

}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Promotion } from './../../models/Promotion';

@Injectable({ providedIn: 'root' })
export class PromotionService {
  constructor(private http: HttpClient) { }

  listAll(): Observable<Promotion[]> {
    return this.http.get<Promotion[]>(`${environment.apiUrl}/promotions`);
  }
}

<section>
    <h1>Como utilizar?</h1>

    <div class="row">
        <div class="col-md-4 mb-4" *ngFor="let video of videos">
            <article class="card">
                <h3>{{video.title}}</h3>
                <div style="padding: 62.5% 0 0 0; position: relative">
                    <iframe *ngIf="video.type === 'vimeo'" [src]="video.url" [title]="video.title" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></iframe>
                    <iframe *ngIf="video.type === 'youtube'" [src]="video.url" [title]="video.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></iframe>
                </div>
            </article>
        </div>
    </div>

</section>

<script src="https://player.vimeo.com/api/player.js"></script>

<app-page-title icon="fa-envelope-o" [title]="title">
  <a
    *ngIf="campaignId"
    [routerLink]="['/campaigns/new']"
    class="btn btn-info btn-icon-right"
  >
    Nova automação
    <i class="fa fa-plus-circle"></i>
  </a>
</app-page-title>

<div class="card mb-3" *ngIf="showAlert">
  ATENÇÃO: A integração desta lista está desativada
</div>

<form [formGroup]="formCampaign" (submit)="save()">
  <div class="card mb-2" [class.active]="active">
    <div class="card-body">
      <div class="row" *ngIf="campaignId && false">
        <div class="col text-right">
          <div class="form-group">
            <button type="button" class="btn btn-info" (click)="resend()">
              Reenviar automação
              <i class="fa fa-paper-plane-o"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="">Automação</label>
            <input
              type="text"
              class="form-control"
              id="name"
              formControlName="name"
              #nameInput
            />
            <small
              *ngIf="formCampaign.get('name').errors?.required"
              class="text-danger"
            >
              Preencha o nome da automação
            </small>
            <small
              *ngIf="formCampaign.get('name').errors?.minlength"
              class="text-danger"
            >
              O nome da automação deve ter pelo menos 5 caracteres
            </small>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label>Lista</label>
            <select
              name="list"
              id="list"
              class="form-control"
              formControlName="lead_list_id"
            >
              <option *ngFor="let list of lists" [value]="list.id">
                {{
                  list.name +
                    (!list.integration || list.integration.active
                      ? ""
                      : " *** [integração desativada]")
                }}
              </option>
            </select>
            <small
              *ngIf="formCampaign.get('lead_list_id').errors?.required"
              class="text-danger"
            >
              Selecione uma lista
            </small>
          </div>
        </div>
      </div>

      <div class="row mb-2 mt-2">
        <div class="col-sm-12 col-md-6 d-flex justify-content-start align-items-center">
          <app-switcher
            [color]="true"
            [checked]="active"
            (change)="toggleActive()"
            label="Ativa?"
          ></app-switcher>
          <app-switcher
          *ngIf="campaign"
          [color]="true"
          style="margin-left: 70px;"
          class="customizable-switcher"
          (click)="includeAC()"
          [checked]="ac"
          label="Active Campaign"
          ngbTooltip="Ativar ou desativar Active Campaign na integração"
        ></app-switcher>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label>Enviar para</label>
            <select
              name="send_to_all"
              id="send_to_all"
              class="form-control"
              formControlName="send_to_all"
              (change)="toggleSendType()"
            >
              <option [ngValue]="false">Enviar apenas para novos leads</option>
              <option [ngValue]="true">
                Enviar para leads atuais e novos leads
              </option>
            </select>
            <small
              *ngIf="formCampaign.get('send_to_all').errors?.required"
              class="text-danger"
            >
              Selecione um método de envio
            </small>
          </div>
        </div>
      </div>

      <div *ngIf="ac" id="ac-infos" class="col-12 mt-4 mb-5 d-flex flex-column justify-content-center align-items-center">
        <div class="col-12 mb-4" style="margin-left: 1.5rem;">
          <h3 class="mb-2">Dados de autenticação para Active Campaign</h3>
          <h6>Não sabe como fazer? <a href="https://help.activecampaign.com/hc/pt-br/articles/207317590-Introdu%C3%A7%C3%A3o-%C3%A0-API#como-obter-seu-url-e-sua-chave-da-api-activecampaign-0-2" target="_blank" style="text-decoration: none;">Clique aqui!</a></h6>
        </div>
        <div class="col-12 row">
          <div class="form-group col-md-6">
            <label for="name">URL</label>
            <input
              id="acURL"
              type="text"
              class="form-control"
              formControlName="acURL"
              autocomplete="off"
            />
            <small
              class="text text-danger"
              *ngIf="acURLInvalid || formCampaign.get('acURL').errors?.invalidUrl || formCampaign.get('acURL').errors?.pattern"
            >
              A URL é inválida.
            </small>
          </div>
    
          <div class="form-group col-md-6">
            <label for="name">CHAVE</label>
            <input
              id="acKey"
              type="password"
              class="form-control"
              formControlName="acKey"
              autocomplete="off"
            />
            <small
              class="text text-danger"
              *ngIf="formCampaign.get('acKey').value && formCampaign.get('acKey').value.length < 30">
              A chave é inválida.
            </small>
          </div>
        </div>
      </div>

      <button class="btn btn-primary me-1" [disabled]="formCampaign.invalid">
        Salvar
      </button>

      <a [routerLink]="['/campaigns']" class="btn btn-secondary">{{
        sequences ? "Voltar" : "Cancelar"
      }}</a>
    </div>

    <app-sequences
      *ngIf="campaign"
      [campaign]="campaign"
      [leadList]="campaign.lead_list"
      [sequences]="sequences"
    ></app-sequences>
  </div>
</form>

import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];

  private show(text: string | TemplateRef<any>, options: any = {}): void {
    const toast = { text, ...options };
    this.toasts.push(toast);
    setTimeout(() => this.remove(toast), options.delay);
  }

  success(message: string, delay: number = 5000): void {
    this.show(message, { classname: 'text-light bg-success', icon: 'fa-check', delay });
  }

  error(message: string, delay: number = 5000): void {
    this.show(message, { classname: 'text-light bg-danger', icon: 'fa-times', delay });
  }

  info(message: string, delay: number = 5000): void {
    this.show(message, { classname: 'text-light bg-info', icon: 'fa-info-circle', delay });
  }

  warning(message: string, delay: number = 5000): void {
    this.show(message, { classname: 'bg-warning', icon: 'fa-exclamation-triangle', delay });
  }

  remove(toast): void {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}

import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { LeadResponse } from 'src/app/models/LeadResponse';
import { Month } from 'src/app/models/Month';
import { User } from 'src/app/models/User';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';
import { ADMINISTRATOR } from 'src/app/shared/services/user.service';

import { LeadResponseFilter } from '../../models/LeadResponseFilter';
import { ConfirmationService } from '../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { LeadResponseService } from '../../shared/lead-response.service';
import { AuthService } from '../../shared/services/auth.service';
import { UtilService } from '../../shared/services/util.service';
import {formatDateTime, shortDateOptions} from "../../shared/utils";

@Component({
  selector: 'app-lead-responses',
  templateUrl: './lead-responses.component.html',
  styleUrls: ['./lead-responses.component.scss']
})
export class LeadResponsesComponent implements OnDestroy {

  page = 1;
  perPage = 10;
  user: User;
  isOpen

  years: number[];
  months: Month[];

  form: UntypedFormGroup;

  subscriptions: Subscription[] = [];
  result$: Observable<PaginatedResult<LeadResponse[]>>;

  get blacklist(): boolean {
    return this.form?.value?.blacklist;
  }

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private leadResponseService: LeadResponseService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private utilService: UtilService,
    private fb: UntypedFormBuilder,
    private toast: ToastService,
    private confirmation: ConfirmationService
  ) {
    const year = this.utilService.getYear();
    const month = this.utilService.getMonth();

    this.years = this.utilService.listYearsApp();
    this.months = this.utilService.listMonths();

    this.form = this.fb.group({
      month: [month, [Validators.required]],
      year: [year, [Validators.required]],
      blacklist: [false],
      text: ['']
    });

    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      this.page = +params.page || this.page;
      this.perPage = +params.per_page || this.perPage;


      const formValues = { ...this.form.value };

      formValues.month = +params.month || formValues.month;
      formValues.year = +params.year || formValues.year;

      if (params.blacklist !== undefined) {
        formValues.blacklist = JSON.parse(params.blacklist);
      }

      this.form.patchValue(formValues);

      this.handleFilter();
    }));

    this.subscriptions.push(this.authService.userChanged.subscribe(this.handleFilter));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleFilter(): void {
    this.user = this.authService.getUser();
    const formValues = this.form.value;
    const filter: LeadResponseFilter = {
      blacklist: this.blacklist,
      month: formValues.month,
      year: formValues.year,
      text: formValues.text
    }
    this.result$ = this.leadResponseService.listPaginated(this.page, this.perPage, filter);
  }

  changeBlacklist(blacklist: boolean) {
    this.form.patchValue({ blacklist });
    this.result$ = null;
  }

  pageChanged(page: number) {
    const formValues = this.form.value;

    const queryParams = {
      page,
      month: +formValues.month,
      year: +formValues.year,
      blacklist: formValues.blacklist,
      text: formValues.text,
      per_page: this.perPage
    }

    const actualQueryParams = this.route.snapshot.queryParams;
    if (
      Number(actualQueryParams.page) === queryParams.page &&
      Number(actualQueryParams.year) === queryParams.year &&
      Number(actualQueryParams.per_page) === queryParams.per_page &&
      Number(actualQueryParams.month) === queryParams.month &&
      actualQueryParams.text === queryParams.text &&
      JSON.parse(actualQueryParams.blacklist) === queryParams.blacklist
    ) {
      this.handleFilter();
    }

    this.router.navigate(['/lead-responses'], { queryParams });
  }

  getTitle(leadResponse: LeadResponse): string {
    const date = new Date(leadResponse.received_date);
    let title = '[' + formatDateTime(date) + '] ';

    if (leadResponse.blacklist) {
      title += ' (Blacklist) ';
    }

    if (leadResponse.message.length > 120) {
      title += ' ' + leadResponse.message.substring(1, 117) + '...';
    } else {
      title += ' ' + leadResponse.message;
    }

    return title;
  }

  addToBlacklist(leadResponse: LeadResponse) {
    this.subscriptions.push(
      this.leadResponseService.addToBlacklist(leadResponse).subscribe(() => {
        this.toast.success('Lead incluído na blacklist com sucesso!');
        this.handleFilter();
      }, () => this.toast.error('Erro ao incluir lead na blacklist'))
    )
  }

  deleteFromBlacklist(leadResponse: LeadResponse) {
    this.confirmation.show(`Deseja realmente excluir o número ${leadResponse.phone} da blacklist?`, () => {
      this.subscriptions.push(
        this.leadResponseService.deleteFromBlacklist(leadResponse).subscribe(() => {
          this.toast.success('Lead excluído da blacklist com sucesso!');
          this.handleFilter();
        }, err => console.log(err))
      )
    });
  }

}

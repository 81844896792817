import { ConfirmationService } from './confirmation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements AfterViewInit {

  @ViewChild('modalConfirmacao') modal: any;
  message: string;

  constructor(
    private modalService: NgbModal,
    private confirmationService: ConfirmationService
  ) { }

  ngAfterViewInit(): void {
    this.confirmationService
      .modalVisibility
      .subscribe(options => {
        if (options.show) {
          this.message = options.message;

          this.modalService.open(this.modal)
          .result
          .then(
            res => {
              if (res === 'YES' && options.callbackYes) {
                options.callbackYes();
              } else if (options.callbackNo) {
                options.callbackNo();
              }
            }, err => () => { })
        }
      })
  }

}

<app-page-title icon="fa-envelope-o" title="Automações">
  <a [routerLink]="['/campaigns/new']" class="btn btn-primary btn-icon-right">
    Nova automação <i class="fa fa-plus-circle"></i>
  </a>
</app-page-title>
<div class="mb-3">
  <div class="row mb-2">
    <div class="col">
      <button class="btn me-2 btn-primary" (click)="filterByStatus()">
        <i class="fa fa-check me-1" *ngIf="active === null"></i>
        <span>Todas</span>
      </button>
      <button class="btn me-2 btn-info" (click)="filterByStatus(true)">
        <i class="fa fa-check me-1" *ngIf="active === true"></i>
        <span>Ativas</span>
      </button>
      <button class="btn me-2 btn-secondary" (click)="filterByStatus(false)">
        <i class="fa fa-check me-1" *ngIf="active === false"></i>
        <span>Inativas</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="input-group">
        <input
          type="text"
          name="name"
          class="form-control"
          placeholder="Digite o nome da lista para pesquisar"
          [(ngModel)]="name"
          (keyup)="filterKeyPress($event)"
        />
        <button class="btn btn-primary" (click)="pageChanged(page)">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="form-group mb-3">
      <label for="integration_id">Integração</label>
      <select name="integration_id" id="integration_id" class="form-control"
        [(ngModel)]="integrationId"
        (change)="pageChanged(page)">
        <option value="">Todas</option>
        <option *ngFor="let integration of integrations" [value]="integration.id">
          {{ integration.platform.name }} -
          {{ integration.name }}
        </option>
      </select>
    </div>
    <div class="form-group mb-3">
      <label for="list_id">Lista</label>
      <select name="list_id" id="list_id" class="form-control"
        [(ngModel)]="listId"
        (change)="pageChanged(page)">
        <option value="">Todas</option>
        <option *ngFor="let list of lists" [value]="list.id">
          {{ list.name }}
        </option>
      </select>
    </div>
  </div>
</div>

<div *ngIf="result && result.data.length > 0; else notFound">
  <div
    class="card gradient-row"
    *ngFor="let campaign of result.data"
    [class]="campaign.active ? 'primary' : 'danger'"
  >
    <div class="row">
      <div class="col-md-9">{{ campaign.name }}</div>
      <div class="col-md-3 icon-group">
        <app-switcher
          [checked]="campaign.active"
          (change)="toggleActive(campaign)"
        ></app-switcher>
        <button
          class="btn btn-icon btn-primary ms-1"
          (click)="showCopy(campaign)"
        >
          <i class="fa fa-file"></i>
        </button>
        <a
          class="btn btn-icon btn-primary ms-1"
          [routerLink]="['/campaigns/edit', campaign.id]"
        >
          <i class="fa fa-pencil"></i>
        </a>
        <button
          class="btn btn-icon btn-danger ms-1"
          (click)="confirmDelete(campaign)"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<app-pagination
  *ngIf="result"
  [currentPage]="result.current_page"
  [itemsPerPage]="result.per_page"
  [totalItems]="result.total"
  (pageChanged)="pageChanged($event)"
></app-pagination>

<ng-template #notFound>
  <div class="card mt-2">
    Nenhuma automação {{ name ? "encontrada" : "cadastrada" }}!
  </div>
</ng-template>

<ng-template #modalCampaign let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Copiar automação:
        <span>{{ campaign.name }}</span>
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group mb-2">
        <label for="name">Nome da automação</label>
        <input
          type="text"
          name="name"
          id="name"
          class="form-control"
          [(ngModel)]="copyName"
          autofocus
        />
      </div>
      <div class="form-group">
        <label for="list">Lista</label>
        <select
          name="list"
          id="list"
          class="form-control"
          *ngIf="lists$ | async as lists"
          [(ngModel)]="copyListId"
        >
          <option *ngFor="let list of lists" [value]="list.id">
            {{ list.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="copy(modal)">
        Copiar
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.close('NO')"
      >
        Cancelar
      </button>
    </div>
  </div>
</ng-template>
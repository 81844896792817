<div class="radio-button">
  <div class="radio" [class.checked]="checked">
  <input type="radio"
    [name]="name"
    [id]="name"
    [checked]="checked"
    (click)="onClick()" />
    <i class="fa fa-check"></i>
  </div>
  <label [for]="name" [class.checked]="checked">
    {{text}}
  </label>
</div>

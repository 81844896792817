<app-page-title
  title="Respostas dos Leads"
  icon="fa-envelope-o"
></app-page-title>

<form class="filter" [formGroup]="form" (submit)="pageChanged(1)">
  <div class="row">
    <div class="col">
      <label for="month">Mês</label>
      <select
        id="month"
        class="form-control"
        formControlName="month"
        (change)="result$ = null"
      >
        <option *ngFor="let month of months" [value]="month.number">
          {{ month.name }}
        </option>
      </select>
    </div>
    <div class="col">
      <label for="year">Ano</label>
      <select
        id="year"
        class="form-control"
        formControlName="year"
        (change)="result$ = null"
      >
        <option *ngFor="let year of years" [value]="year">
          {{ year }}
        </option>
      </select>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <label for="text">Mensagem ou Telefone</label>
      <input
        type="text"
        class="form-control"
        id="text"
        formControlName="text"
        placeholder="Digite o texto ou o número que deseja pesquisar"
      />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <app-switcher
        class="checkbox"
        display="block"
        [checked]="blacklist"
        (checkedChange)="changeBlacklist($event)"
        label="Apenas blacklist?"
      ></app-switcher>
    </div>
    <div class="col btn">
      <button type="submit" class="btn btn-primary">
        <i class="fa fa-search"></i> Buscar
      </button>
    </div>
  </div>
</form>

<div *ngIf="result$ | async as result">
  <div *ngIf="result?.data?.length > 0; else notFound">
    <app-accordion-card-flat
      *ngFor="let leadResponse of result?.data"
      [title]="getTitle(leadResponse)"
      [class]="leadResponse.blacklist ? 'danger' : 'primary'"
    >
      <div class="row message-line">
        <div class="col-md-5 col-sm-12">
          <i class="fa fa-phone me-2"></i>
          <b>Operadora: </b>
          {{ leadResponse.operator_name }}
        </div>

        <div class="col-md-5 col-sm-12">
          <a
            href="https://wa.me/{{ leadResponse.phone }}"
            class="btn btn-primary"
            target="_blank"
          >
            <i class="fa fa-whatsapp"></i>
          </a>
          {{ leadResponse.phone }}
        </div>

        <div class="col-md-2 col-sm-12">
          <button
            class="btn btn-primary"
            (click)="addToBlacklist(leadResponse)"
            *ngIf="!leadResponse.blacklist; else showDeleteFromBlacklist"
          >
            Adicionar para blacklist
          </button>

          <ng-template #showDeleteFromBlacklist>
            <button
              class="btn btn-danger"
              (click)="deleteFromBlacklist(leadResponse)"
              *ngIf="isAdministrator"
            >
              Excluir da blacklist
            </button>
          </ng-template>
        </div>

        <div class="mt-3" *ngIf="leadResponse.control?.message">
          <div class="row">
            <div class="col-10">
              <span><i class="fa fa-envelope me-2"></i> Mensagem enviada:</span>
            </div>
            <div class="col">
              <i class="fa fa-calendar me-2"></i>
              {{ leadResponse.control.sent_date | date : "dd/MM/yyyy HH:mm" }}
            </div>
          </div>
          <div class="card">{{ leadResponse.control.message }}</div>
        </div>

        <div class="mt-3" *ngIf="leadResponse.message.length > 120">
          <div class="row">
            <div class="col-10">
              <span><i class="fa fa-envelope me-2"></i>Mensagem recebida:</span>
            </div>
            <div class="col">
              <span><i class="fa fa-calendar me-2"></i>{{ leadResponse.received_date | date : "dd/MM/yyyy HH:mm" }}</span>
            </div>
          </div>
          <div class="card">{{ leadResponse.message }}</div>
        </div>
      </div>
    </app-accordion-card-flat>
  </div>

  <ng-template #notFound>
    <p class="card">
      Nenhuma resposta de lead foi recebida no período informado.
    </p>
  </ng-template>

  <app-pagination
    *ngIf="result"
    [currentPage]="result.current_page"
    [itemsPerPage]="result.per_page"
    [totalItems]="result.total"
    (pageChanged)="pageChanged($event)"
  ></app-pagination>
</div>

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserPayment } from 'src/app/models/UserPayment';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';

import { UserCommission } from '../../models/UserCommission';
import { UserService } from '../../shared/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partner-payments',
  templateUrl: './partner-payments.component.html',
  styleUrls: ['./partner-payments.component.scss']
})
export class PartnerPaymentsComponent implements OnInit {

  page_1 = 1;
  page_2 = 1;
  pending$: Observable<UserCommission>;
  payments$: Observable<PaginatedResult<UserPayment[]>>;
  pendingPaginate$: Observable<PaginatedResult<UserCommission[]>>;
  next_payment: UserCommission;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.page_1 = params.page_1 || this.page_1;
      this.page_2 = params.page_2 || this.page_2;
      this.listPaginated();
    });
  }

  listPaginated() {
    this.pending$ = this.userService.getPendingCommission();
    this.payments$ = this.userService.getPayments(this.page_1, 15);
    this.pendingPaginate$ = this.userService.getPendingCommissionsPaginate(this.page_2, 15);

    this.pending$.subscribe((item) => {
      // @ts-ignore
      this.next_payment = item.data[0];
    })
  }

  pageChangedPayments(page: any): void {
    const queryParams = {
      page_1: page,
      page_2: this.page_2,
    };

    this.router.navigate(['/partner/payments'], { queryParams });
  }

  pageChangedPending(page: any): void {
    const queryParams = {
      page_1: this.page_1,
      page_2: page,
    };

    this.router.navigate(['/partner/payments'], { queryParams });
  }
}

<div class="app-analytics-card card" [class.h-100]="autoHeight == 'true'">
  <div class="card-title">
    <div class="d-flex justify-content-between">
      <div>{{title}}</div>
      <div *ngIf="tooltipText">
        <i class="fa fa-question-circle-o" ngbTooltip="{{tooltipText}}"></i>
      </div>
    </div>
  </div>
  <div>
    <div class="highlight">{{highlightText}}</div>
    <ng-content></ng-content>
  </div>
</div>

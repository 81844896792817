import { User } from './User';

export interface Recharge {
  id: string;
  user_id: string;
  description: string;
  credits: number;
  price: number;
  total: number;
  created_at: Date;
  created_by: string;
  updated_at: Date;
  confirmated_at: Date;
  payment_type: string;
  payment_status: string;
  integration_id: string;
  target: string;
  target_name: string;
  invoice_status: number,
  invoice_date: string,
  invoice_url: string,

  user: User;
}

export const INVOICE_PENDING = 0;
export const INVOICE_PROCESSING = 1;
export const INVOICE_OK = 2;
export const INVOICE_FAILED = 3;
export const INVOICE_NO_LONGER_PROCESS = 99;

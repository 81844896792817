<app-page-title icon="fa-pie-chart" title="Relatório de automações (beta)">
  <a href="/#/analytics" class="btn btn-danger" title="Voltar para página anterior">Voltar</a>
</app-page-title>

<div>
  <div class="row mb-2">
    <div class="col-12 col-sm-12">
      <div class="form-group">
        <label>Filtros rápidos</label>
        <div class="mb-2 btn-group-filter">
          <button (click)="filterByDate('today')" class="btn btn-primary" [ngClass]="(filter == 'today' || filter == null) ? 'active' : ''">Hoje</button>
          <button (click)="filterByDate('yesterday')" class="btn btn-primary"  [ngClass]="(filter == 'yesterday') ? 'active' : ''">Ontem</button>
          <button (click)="filterByDate('-7 days')" class="btn btn-primary" [ngClass]="(filter == '-7 days') ? 'active' : ''">7 dias</button>
          <button (click)="filterByDate('-15 days')" class="btn btn-primary" [ngClass]="(filter == '-15 days') ? 'active' : ''">15 dias</button>
          <button (click)="filterByDate('this month')" class="btn btn-primary" [ngClass]="(filter == 'this month') ? 'active' : ''">Mês atual</button>
          <button (click)="filterByDate('last month')" class="btn btn-primary" [ngClass]="(filter == 'last month') ? 'active' : ''">Mês Anterior</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-2">
      <div class="form-group">
        <label>Filtrar por integração</label>
        <select class="form-control" name="integrationId" [(ngModel)]="integrationId">
          <option value="">Todas</option>
          <option *ngFor="let integration of integrations" [value]="integration.id">
            {{ integration.platform.name }} - {{ integration.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 col-sm-2">
      <div class="form-group">
        <label>Filtrar por automação</label>
        <input type="text" class="form-control" (keyup.enter)="filterBy()" [(ngModel)]="campaignName" />
      </div>
    </div>
    <div class="col-12 col-sm-4">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="">De</label>
            <input type="date" class="form-control" [(ngModel)]="fromAt">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="">Até</label>
            <input type="date" class="form-control" [(ngModel)]="toAt">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4 align-self-end">
      <button class="btn btn-primary w-50" (click)="filterBy()">Aplicar filtros</button>
      <button class="btn btn-secondary w-50" (click)="clearFilter()">Limpar filtros</button>
    </div>
  </div>
</div>

<div class="mt-5">
  <div class="d-flex align-items-center justify-content-between my-3">
    <h3 class="my-0">Automações</h3>
  </div>
  <table class="table" *ngIf="campaigns?.data.length > 0; else notFound">
    <thead>
      <tr>
        <th width="35%">Automação</th>
        <th>Envios</th>
        <th>Cliques</th>
        <th>CTR</th>
        <th width="10%" class="text-center">Sequência</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let campaign of campaigns.data">
        <td>{{campaign.name}}</td>
        <td>{{ campaign.qty_sent > 0 ? (campaign.qty_sent | number)?.replace(',', '.') : '-'}}</td>
        <td>{{ campaign.qty_clicks > 0 ? (campaign.qty_clicks | number)?.replace(',', '.') : '-'}}</td>
        <td>{{ campaign.qty_clicks > 0 && campaign.qty_sent > 0 ? ((campaign.qty_clicks/campaign.qty_sent) | percent:'1.0-2')?.replace('.', ',') : '-'}}</td>
        <td class="text-center">
          <a [routerLink]="['/analytics/campaigns', campaign.id]" [queryParams]="{from: fromAt, to: toAt}" title="Detalhamento da automação">
            <i class="fa fa-th-list text-white"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #notFound>
  <div class="card">
    Nenhuma informação disponível para os filtros aplicados.
  </div>
</ng-template>

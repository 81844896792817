import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';
import { User } from 'src/app/models/User';
import { Control } from './../../models/Control';
import { Provider } from './../../models/Provider';
import { UserCreditInfo } from './../../models/UserCreditInfo';
import { AuthService } from './../../shared/services/auth.service';
import { ChartData, DashboardService } from './../../shared/services/dashboard.service';
import { ADMINISTRATOR } from 'src/app/shared/services/user.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export type ChartOptions = {
  colors: string[];
  series: ApexAxisChartSeries;
  chart: ApexChart;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  grid: ApexGrid;
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  fill: any;
};

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  controls: Control[] = [];

  user: User;
  creditsLow = false;
  creditInfo: UserCreditInfo;
  reservatedCredits: number;

  messagesChartOptions: Partial<ChartOptions>;
  subscriptions: Subscription[] = [];

  messagesChartDateField: boolean = false;
  startedDay: Date;
  endedDay: Date;

  now: Date = new Date(Date.now());

  providers: Provider[];

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    this.startedDay = new Date();
    this.startedDay.setDate(this.now.getDate() - 7); // 7 dias atrás
    this.endedDay = new Date(this.now); // Hoje
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.userChanged.subscribe(() => {
        this.loadDashboard();
      })
    );

    const startDate = this.formatDate(this.startedDay);

    this.dashboardService.dailySents(startDate).subscribe((data) => this.showMessagesChart(data));

    this.loadDashboard();

    this.showMessagesChart(null);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  loadDashboard(): void {
    this.user = this.authService.getUser();

    const startDate = this.formatDate(this.startedDay);

    if(this.startedDay > this.now){
      return;
    }

    this.subscriptions.push(
      this.dashboardService.userCreditsInfo().subscribe((creditInfo) => this.creditInfo = creditInfo),

      this.dashboardService.lastMessages().subscribe((controls) => (this.controls = controls)),
    );

    if (this.isAdministrator) {
      this.subscriptions.push(
        this.dashboardService.reservatedCredits().subscribe((data) => this.reservatedCredits = +data.reservated_credits),

        this.dashboardService.dailySents(this.formatDate(this.startedDay)).subscribe((data) => this.showMessagesChart(data)),

        this.dashboardService.providerCredits().subscribe((providers) => {
          this.providers = providers;

          let credits = 0;
          providers.forEach(provider => {
            credits += provider.credits;
          });
          this.creditsLow = credits < 100;
        })
      );
    }
  }

  showMessagesChart(chartData: ChartData): void {
    const dataInicial = this.startedDay.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const dataFinal = this.endedDay.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });

    this.messagesChartOptions = {
      colors: ['#00BE9A'],
      series: [
        {
          name: `Mensagens enviadas entre ${dataInicial} a ${dataFinal}`,
          data: chartData ? chartData.data : [],
        },
      ],
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        foreColor: '#00BE9A',
      },
      dataLabels: {
        enabled: true,
      },
      grid: {
        show: false,
      },
      title: {
        text: `Mensagens enviadas entre ${dataInicial} a ${dataFinal}`,
        align: 'right',
      },
      xaxis: {
        type: 'category',
        categories: chartData ? chartData.labels : [],
      },
      tooltip: {
        enabled: false,
      },
      labels: chartData ? chartData.labels : [],
      legend: {
        horizontalAlign: 'left',
      },
      fill: {
        opacity: 0.3,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          opacityFrom: 0.7,
          opacityTo: 0.3,
        },
      },
    };

    // Mostra o seletor de data
    this.messagesChartDateField = true;
  }

  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.startedDay = event.value;
    this.endedDay = new Date(this.startedDay);
    this.endedDay.setDate(this.startedDay.getDate() + 7);
    this.loadDashboard();
  }

  private formatDate(date: Date): string {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }
}

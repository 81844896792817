import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/User';
import { UserFilter } from 'src/app/models/UserFilter';

import { ToastService } from './../../shared/components/toast/toast.service';
import { AuthService } from './../../shared/services/auth.service';
import { FinancialService } from 'src/app/shared/services/financial.service';

import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';

import { ViewChild, ElementRef } from '@angular/core';
import { ADMINISTRATOR, UserService } from 'src/app/shared/services/user.service';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';

@Component({
  selector: 'app-lists',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent implements OnInit, OnDestroy {
  // Puxar o elemento da descrição, para focar quando a tag for digitada automaticamente.
  @ViewChild('desc') descriptionElement: ElementRef;
  page = 1;
  per_page = 1;
  user: User;

  // Para o campo de pesquisa de usuário (tabela)
  userQuery: string = '';

  // Dados recebidos do servidor sobre os usuários (tratados para a tabela)
  ELEMENT_DATA = [];

  // Campo de custo
  costs: string | number;
  // Campo de descrição
  description: string = '';
  // Campo de usuários (uma lista pois caso haja alguma pesquisa ou mudança de página detectar pelos checkboxes seria inútil)
  selectedUsers: any[] = [];

  // Dados recebidos do servidor sobre os usuários (não tratados)
  result: PaginatedResult<User[]>;

  // Filtro padrão para os usuários
  filter: UserFilter = {
    active: true,
    validated: true,
    promocode: null,
    text: null,
    promocodeId: null
  };

  // Colunas exibidas na tabela (select é a checkbox)
  displayedColumns: string[] = ['select', 'name', 'email', 'phone', 'profile_url'];
  // Dados exibidos na tabela (Adaptados para a classe correta)
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  // "Gerenciador" de seleções
  selection = new SelectionModel(true, []);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private autService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
    // Service para o financial com as requisições baseadas no backend
    private financialService: FinancialService
  ) { }

  get administrator() {
    return this.user.profile_id === ADMINISTRATOR;
  }

  ngOnInit(): void {
    this.user = this.autService.getUser();

    if (!this.administrator){
      this.router.navigate(['/dashboard']);
      return;
    }

    this.financialService.getInfo().subscribe(data => {
      this.costs = data.cost;
      this.description = data.description;
      this.selectedUsers = data.users || [];
    })

    this.userService.listPaginated(this.page, 20, this.filter, null, true).subscribe(result => {
      this.result = result;
      this.result.data.forEach(user => {
        this.ELEMENT_DATA.push({selected: user.is_billable, id: user.id, name: user.name, email: user.email, phone: user.phone, profile_url: `/#/users?filter=${user.email}`});

        if(user.is_billable) {
          this.selectedUsers.push(user.id);
        }
      })
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  // Funções checkbox para a tabela

  // Marcar/desmarcar todos
  toggleAllRows(event: MatCheckboxChange):void {
    if (event.checked) {
      this.result.data.forEach(user => {
        this.selectedUsers.push(user.id);
      })
    } else {
      this.result.data.forEach(user => {
        this.selectedUsers.forEach((item, index) => {
          if(item === user.id) this.selectedUsers.splice(index,1);
        });
      })
    }
  }

  // Marcar/desmarcar um
  toggleRow(event: MatCheckboxChange):void {
      if (event.checked) {
        this.selectedUsers.push(event.source.value);
      } else {
        this.selectedUsers.forEach((item, index) => {
          if(item === event.source.value) this.selectedUsers.splice(index,1);
        });
      }
  }

  // Função de pesquisa de usuário da tabela (Server-Side)
  searchUsers() {
    this.filter = {
      text: this.userQuery,
      active: null,
      validated: null,
      promocode: null,
      promocodeId: null
    };

    this.userService.listPaginated(1, 20, this.filter, null, true).subscribe(result => {
      this.ELEMENT_DATA = [];
      this.result = result;
      this.result.data.forEach(user => {
        this.ELEMENT_DATA.push({id: user.id, name: user.name, email: user.email, phone: user.phone, profile_url: `/#/users?filter=${user.email}`});

        if(user.is_billable) {
          this.selectedUsers.push(user.id);
        }
      })
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  // Função para adicionar a tag ao texto/descrição
  addTag(variable: string) {
    this.descriptionElement.nativeElement.focus();
    this.description+= `{${variable}}`;
  }

  // Salvar dados
  save() {
    this.financialService.updateInfo(this.costs, this.selectedUsers, this.description).subscribe(r => {
      this.toastService.success('Configurações de nota fiscal salvas com sucesso!');
    })
  }

  // Para quando ir para próximas página de usuários
  pageChanged(page: any) {
    this.page = page;
    this.userService.listPaginated(this.page, 20, this.filter, null, true).subscribe(result => {
      this.ELEMENT_DATA = [];
      this.result = result;
      this.result.data.forEach(user => {
        this.ELEMENT_DATA.push({id: user.id, name: user.name, email: user.email, phone: user.phone, profile_url: `/#/users?filter=${user.email}`});

        if(user.is_billable) {
          this.selectedUsers.push(user.id);
        }
      })
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  ngOnDestroy(): void {}

}

import { SupportFormService } from './support-form.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss']
})
export class SupportFormComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}

import { CopyService } from './../../../shared/services/copy.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { APPMAX, IntegrationsService } from './../../../shared/services/integrations.service';
import { PlatformsService } from './../../../shared/services/platforms.service';
import { ToastService } from './../../../shared/components/toast/toast.service';

import { Integration } from './../../../models/Integration';
import { Platform } from 'src/app/models/Platform';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { urlValidatorService } from 'src/app/shared/validators/url.validator.service';
import { IntegrationActivationStatus } from 'src/app/models/IntegrationActivated';
import { ActiveCampaignService } from 'src/app/shared/services/active-campaign.service';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit, OnChanges {

  title = 'Nova integração';

  @Input() hidden = true;
  @Input() integration: Integration;
  @Output() saved = new EventEmitter<boolean>();
  @Output() toggleHidden = new EventEmitter<boolean>();
  
  @ViewChild('modalHelpMP') modalHelpMP: NgbModalRef;
  @ViewChild('integration') inputName: ElementRef;

  form: UntypedFormGroup;
  platforms: Platform[];
  integrationId: string;

  multiPlatform: boolean = false;
  activateMPHelp: boolean = false;
  integrations: Integration[] = [];
  filteredIntegrations: Integration[] = [];
  enableMP: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private platformsServices: PlatformsService,
    private modalService: NgbModal,
    private integrationsService: IntegrationsService,
    private copyService: CopyService,
    private ACService: ActiveCampaignService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      platform_id: ['', Validators.required],
      alternative_integration_id: [''],
      url: [''],
      acURL: ['', Validators.pattern('^$|^https:\\/\\/(?:[a-zA-Z0-9-]+\\.activehosted\\.com|[a-zA-Z0-9-]+\\.api-us\\d\\.com)$')],
      acKey: ['']
    });
    this.activatedRoute
      .params.subscribe(params => {
        this.integrationId = null;
        if (params.id) {
          this.integrationId = params.id;
          this.title = 'Alterar integração';

          this.integrationsService.findById(params.id)
            .subscribe(integration => {
              this.form.patchValue(integration);
            });
        }
      });

    this.platformsServices
      .listAll()
      .subscribe(platforms => this.platforms = platforms);

    this.integrationsService
      .listAll()
      .subscribe(integrations => this.integrations = integrations);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.integration) {
      this.integrationId = this.integration.id;
      this.integration.acURL = this.integration.acURL ? this.integration.acURL.replace('/api/3', '') : null;
      this.form.patchValue(this.integration);
      this.enableMP = this.integration.platform_id == APPMAX;
      this.multiPlatform = this.integration.alternative_integration_id ? true : false;
      this.filteredIntegrations = this.integrations.filter(integration => integration.id != this.integration.id);
    } else {
      this.integrationId = null;
      this.form?.reset();
    }

    setTimeout(() => this.inputName?.nativeElement.focus(), 200);
  }

  copy(): void {
    this.copyService.copy('url', 'URL de integração copiada!');
  }

  save(): void {
    const integration = this.form.getRawValue() as Integration;

    const successMessage = integration.id
    ? 'Integração alterado com sucesso!'
    : 'Integração cadastrado com sucesso!';

    const errorMessage = integration.id
      ? 'Erro ao alterar integração!'
      : 'Erro ao cadastrar integração!';
      

    this.integrationsService
      .save(integration)
      .subscribe(
        res => {
          this.toastService.success(successMessage);
          this.saved.emit(true);
          this.form.reset();
          this.integrations.push(integration);
        }
      );
  }

  openHelpMP(): void {
    this.modalService.open(this.modalHelpMP)
  }

  disableOrEnableMP(): void {
    if (!this.multiPlatform) {
      this.form.patchValue({ alternative_integration_id: '' });
    }
  }

}
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Twilio } from 'src/app/models/Twilio';
import { User } from 'src/app/models/User';
import { ConfirmationService } from 'src/app/shared/components/confirmation-modal/confirmation.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-modal-twilio',
  templateUrl: './modal-twilio.component.html',
  styleUrls: ['./modal-twilio.component.scss']
})
export class ModalTwilioComponent implements OnInit {

  @ViewChild('modalTwilio') modalTwilio: any;
  @ViewChild('accountInput') accountInput: HTMLInputElement;

  user?: User;
  editing: boolean;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private _modal: NgbModal,
    private _fb: UntypedFormBuilder,
    private _authService: AuthService,
    private _userService: UserService,
    private _toastService: ToastService,
    private _confirmation: ConfirmationService
  ) {
    this.form = _fb.group({
      account_sid: ['', [Validators.required]],
      auth_token: ['', [Validators.required]],
      phone: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.user = this._authService.getUser();
  }

  open() {
    this.editing = false;
    this.subscriptions.push(
      this._userService.getGlobalAccount().subscribe(globalAccount => {
        if (globalAccount.account_sid) {
          this.editing = true;
        }
        this.form.patchValue(globalAccount);
        this._modal.open(this.modalTwilio);
      })
    );

  }

  submit(modal: any) {
    const globalAccount = this.form.getRawValue() as Twilio;
    const message = this.editing
      ? 'Conta alterada com sucesso!'
      : 'Conta global ativada com sucesso!';

    this.subscriptions.push(
      this._userService.saveGlobalAccount(globalAccount).subscribe(() => {
        this._toastService.success(message);
        this.user.global = true;
        this._authService.updateUser(this.user);
        modal.close();
      },
        () => this._toastService.error('Erro ao ativar conta global'))
    );
  }

  delete(modal: any) {
    this._confirmation.show('Deseja realmente excluir sua conta global?', () => {
      this.subscriptions.push(
        this._userService.deleteGlobalAccount().subscribe(() => {
          this._toastService.success('Conta global excluída com sucesso!');
          this.user.global = false;
          this.user.globalActivated = false;
          this._authService.updateUser(this.user);
          modal.close();
        },
          () => this._toastService.error('Erro ao excluir conta global!'))
      );
    });
  }

}

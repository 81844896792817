<app-page-title title="Agências" icon="fa-fire"></app-page-title>

<div *ngIf="agencies$ | async as result">
  <div class="mb-2">
    <form (submit)="pageChanged(1)">
      <div class="row">
        <div class="col">
          <div class="input-group">
            <input type="text" name="filter" id="filter" class="form-control"
              placeholder="Pesquisar pelo nome ou e-mail" [(ngModel)]="filter" />
            <button class="btn btn-primary">
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div class="col-xs-12 mt-2 mb-2 d-flex flex-row">
            <div style="width: 90%;">
              <label>Ordenar por</label>
              <select name="order" id="order" [(ngModel)]="orderBy" [value]="orderBy" (change)="orderByChanged()"
                class="form-control">
                <option value="name">Nome</option>
                <option value="pending">Data da última pendência</option>
                <option value="credits">Saldo</option>
              </select>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center mt-2" style="width: 10%;">
              <label>Reverso</label>
              <app-switcher [checked]="invertedOrder" (change)="invertOrder()"
                style="max-height: 30px !important;user-select: none;"></app-switcher>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="result.data.length === 0" class="card">
    <span>Nenhuma agência cadastrada :(</span>
  </div>

  <div class="card mt-2" *ngFor="let agency of result.data">
    <div class="row">
      <div class="col">
        <h2>
          <div>{{ agency.name }} {{agency.pix_key ? '(chave pix: '+agency.pix_key+')' : ''}}</div>

          <button
            class="btn btn-secondary mx-2"
            [routerLink]="['/partner-management', agency.id, 'payments']"
            *ngIf="agency.commissions.length > 0"
          >
            Visualizar pagamentos
            <i class="fa fa-search"></i>
          </button>
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col"><b>Período</b></div>
      <div class="col"><b>Valor Pendente</b></div>
      <div class="col"><b>Valor Pago</b></div>
      <div class="col"><b>Status</b></div>
      <div class="col-3"></div>
    </div>

    <div *ngFor="let commission of agency.commissions">
      <div class="row payment mt-2 justify-content-center align-items-center" *ngIf="getPendingAmount(commission) != 0 || getPaidAmount(commission) != 0">
        <div class="col">
          {{ commission.period | period }}
        </div>
        <div class="col">
          {{ getPendingAmount(commission) | numberFormat : "R$ " : true : 2 }}
        </div>
        <div class="col">
          {{ getPaidAmount(commission) | numberFormat : "R$ " : true : 2 }}
        </div>
        <div class="col">
          {{ showStatus(commission) }}
        </div>

        <div class="col-3">
          <button class="btn btn-primary me-2" (click)="register(agency, commission)"
            *ngIf="commission.status === 'pending'">
            Registrar
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="hasZeroValues(agency)">
      <div role="button" class="showPendingBtn" *ngIf="!showPendings.includes(agency)"
        (click)="alternateHiddenStatus(agency)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="var(--bs-primary)" style="width:32px; height:32px"
          viewBox="0 0 512 512">
          <path
            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
        </svg>
      </div>
      <div *ngFor="let commission of agency.commissions">
        <div class="row payment mt-2 justify-content-center align-items-center"
          *ngIf="showPendings.includes(agency) && getPaidAmount(commission) === 0 && getPendingAmount(commission) === 0">
          <div class="col">
            {{ commission.period | period }}
          </div>
          <div class="col">
            {{ getPendingAmount(commission) | numberFormat : "R$ " : true : 2 }}
          </div>
          <div class="col">
            {{ getPaidAmount(commission) | numberFormat : "R$ " : true : 2 }}
          </div>
          <div class="col">
            {{ showStatus(commission) }}
          </div>

          <div class="col-3">
            <button class="btn btn-primary me-2" (click)="register(agency, commission)"
              *ngIf="commission.status === 'pending'">
              Registrar
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <app-pagination *ngIf="result" [currentPage]="result.current_page" [itemsPerPage]="result.per_page"
      [totalItems]="result.total" (pageChanged)="pageChanged($event)"></app-pagination>
  </div>
</div>

<ng-template #paymentModal let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Registrar pagamento</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <form [formGroup]="paymentForm">
        <div class="row">
          <div class="col">
            <label for="amount">Valor *</label>
            <span class="form-control">
              {{
              paymentForm.get("amount").value
              | numberFormat : "R$ " : true : 2
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="amount">Período *</label>
            <span class="form-control">
              {{ paymentForm.get("period").value | period }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="payment_date">Data do Pagamento *</label>
            <input type="date" name="payment_date" id="payment_date" class="form-control"
              formControlName="payment_date" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="description">Observação</label>
            <textarea name="description" id="description" class="form-control" formControlName="description"></textarea>
          </div>
        </div>z
        <div class="row">
          <div class="col">
            <span>(*) campos obrigatórios</span>
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="save(modal)" [disabled]="paymentForm.invalid">
        Salvar
      </button>
      <button type="button" class="btn btn-secondary" (click)="modal.close('NO')">
        Cancelar
      </button>
    </div>
  </div>
</ng-template>
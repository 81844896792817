import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../models/User';
import { Call4UService } from '../../services/call4u.service';
import { ToastService } from '../toast/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'call4u-activate-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class Call4UPopupComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSequenceCall', {static: true}) modalSequenceCall: ElementRef<any>;
  @Input() onClose: CallableFunction;

  mode: string = 'activate'
  modalRef: any
  modalErrorMessage: string
  togglePopUp: boolean
  user: User

  docs_url: string
  login_url: string

  constructor(
    private modalService: NgbModal,
    private Call4UService: Call4UService,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.new(this.modalSequenceCall);
  }

  new(modal: any) {
    this.modalRef = this.modalService.open(modal, {size: 'lg'})
    this.modalRef.result.then((result) => {        
      window.location.reload();
    },
    (reason) => {
      if (this.onClose) {
        this.onClose(this.mode == 'activate' ? false : true);
      }
    })
  }

  next() {
    this.Call4UService.activate().subscribe((activate) => {
 
      if (!activate || !activate.status) {  
        let message = activate.message ?? "Não foi possível ativar sua assinatura, tente novamente mais tarde."
        
        if(message === "Número de telefone inválido"){
          this.mode = 'error'
          this.modalErrorMessage = message
          return
        }
        this.modalService.dismissAll();
        return this.toastService.error(message)
      };
      this.docs_url = activate.docs;
      this.login_url = activate.login;
      this.toastService.success(activate.message);
      this.mode = 'video';
      window.open('https://app.call4u.com.br/sign-in', '_blank');
    })
  }

  editProfile(){
    this.router.navigate(["/profile"])
    this.modalService.dismissAll();
  }

  openPopup(): void {
    this.togglePopUp = !this.togglePopUp;
  }

  ngAfterViewInit(): void {
    // After
  }
}

<ng-template #modalSequenceCall let-modal>
  <div>
    <div *ngIf="mode === 'activate'" class="modal-body">
      <div class="hello-from-call4u">
        <h1>Ative as chamadas de voz na sua campanha!</h1>
        <h6>Já está disponível a integração SMS Funnel + Call4U, trazendo ainda mais recursos para a sua campanha e
          potencializando os seus resultados.</h6>
      </div>

      <div class="benefits-from-call4u">
        <div class="benefit-from-call4u">
          <i class="fa fa-solid fa-check"></i>
          <h6>Configure disparos de chamadas de voz na sua campanha</h6>
        </div>
        <div class="benefit-from-call4u">
          <i class="fa fa-solid fa-check"></i>
          <h6>Qualifique e se aproxime ainda mais do seu público</h6>
        </div>
        <div class="benefit-from-call4u">
          <i class="fa fa-solid fa-check"></i>
          <h6>Converta ainda mais com campanhas híbridas</h6>
        </div>
        <div class="benefit-from-call4u">
          <i class="fa fa-solid fa-check"></i>
          <h6>Ative hoje e ganhe <b>200 ligações grátis</b> para testar a ferramenta!</h6>
        </div>
      </div>

      <div class="terms-from-call4u">
        <h6>Ao ativar você concorda com o envio dos seus dados para Call4U para criação de acesso a plataforma e
          informação dos contatos para disparo das chamadas de voz.</h6>
      </div>

      <div class="options-from-call4u">
        <div class="option-from-call4u">
          <button class="close" (click)="modalRef.close()">
            <h1>
              Fechar
            </h1>
          </button>
        </div>

        <div class="option-from-call4u" (click)="next()">
          <button class="activate">
            <h1>
              Ativar agora
            </h1>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'video'" class="modal-body">
      <div class="hello-from-call4u">
        <h1>Parabéns! Você ativou e ganhou 200 ligações grátis.</h1>
        <h6>Preparamos um material ensinando o passo a passo para cadastrar a sua primeira campanha com chamadas de voz.
          Veja o vídeo e faça o download do material abaixo.</h6>
        <h6>Seu login é seu email junto com esta senha: <span class="call4u-pwd">newCallUser</span> - <a target="_blank"
            [attr.href]="login_url"><b>Realize o login aqui</b></a></h6>
      </div>
      <div class="video-from-call4u">
        <iframe src="https://www.youtube.com/embed/X-_fVmTMPXs" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </div>
      <div class="options-from-call4u">
        <div class="option-from-call4u">
          <button class="close" (click)="modalRef.close()">
            <h1>
              Fechar
            </h1>
          </button>
        </div>
        <div class="option-from-call4u">
          <a [attr.href]="docs_url" target="_blank" style="text-decoration: none;">
            <button class="activate">
              <h1 style="font-size: 115%;">
                Ver documentação
              </h1>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'error'" class="modal-body modal-error">
      <div class="hello-from-call4u">
        <h1>Atualize o seu cadastro e tente novamente:</h1>
        <h6>{{modalErrorMessage }}</h6>
      </div>

      <div class="options-from-call4u">
        <div class="option-from-call4u">
          <button class="close" (click)="modalRef.close()">
            <h1>
              Fechar
            </h1>
          </button>
        </div>

        <div class="option-from-call4u" (click)="editProfile()">
          <button class="activate">
            <h1>
              Editar perfil
            </h1>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
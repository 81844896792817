<form [formGroup]="addressForm">
  <div class="row mb-3">
    <div class="col-md-6">
      <label for="postcode" *ngIf="showLabels">CEP:</label>
      <input
        type="text"
        #postcode
        id="postcode"
        name="postcode"
        class="form-control"
        placeholder="{{ showLabels ? '' : 'CEP' }}"
        maxlength="9"
        formControlName="postcode"
        (keypress)="changePostcode($event)"
        (keyup)="changePostcode($event)"
        (keydown)="changePostcode($event)"
        (blur)="changePostcode($event, true)"
      />
      <span
        class="text text-danger ms-2"
        *ngIf="
          addressForm.get('postcode').errors?.minlength ||
          addressForm.get('postcode').errors?.maxlength ||
          (addressForm.get('postcode').touched &&
            addressForm.get('postcode').errors?.required)
        "
      >
        CEP é obrigatório
      </span>
    </div>
  </div>

  <div class="row mb-3" *ngIf="showAddress">
    <div class="col-md-8">
      <label for="street" *ngIf="showLabels">Endereço</label>
      <input
        type="text"
        id="street"
        name="street"
        class="form-control"
        placeholder="{{ showLabels ? '' : 'Endereço' }}"
        formControlName="street"
      />
      <span
        class="text text-danger ms-2"
        *ngIf="
          addressForm.get('street').errors?.minlength ||
          addressForm.get('street').errors?.maxlength ||
          (addressForm.get('street').touched &&
            addressForm.get('street').errors?.required)
        "
      >
        Endereço é obrigatório
      </span>
    </div>

    <div class="col-md-4" *ngIf="showAddress">
      <label for="number" *ngIf="showLabels">Número</label>
      <input
        type="number"
        id="number"
        name="number"
        class="form-control"
        placeholder="{{ showLabels ? '' : 'Número' }}"
        formControlName="number"
      />
      <span
        class="text text-danger ms-2"
        *ngIf="
          addressForm.get('number').errors?.minlength ||
          addressForm.get('number').errors?.maxlength ||
          addressForm.get('number').errors?.required
        "
      >
        Número é obrigatório
      </span>
    </div>
  </div>

  <div class="row mb-3" *ngIf="showAddress">
    <div class="col-md-6">
      <label for="complement" *ngIf="showLabels">Complemento</label>
      <input
        type="text"
        id="complement"
        name="complement"
        class="form-control"
        placeholder="{{ showLabels ? '' : 'Complemento' }}"
        formControlName="complement"
      />
    </div>

    <div class="col-md-6">
      <label for="district" *ngIf="showLabels">Bairro</label>
      <input
        type="text"
        id="district"
        name="district"
        class="form-control"
        placeholder="{{ showLabels ? '' : 'Bairro' }}"
        formControlName="district"
      />
      <span
        class="text text-danger ms-2"
        *ngIf="
          addressForm.get('district').errors?.minlength ||
          addressForm.get('district').errors?.maxlength ||
          (addressForm.get('district').touched &&
            addressForm.get('district').errors?.required)
        "
      >
        Bairro é obrigatório
      </span>
    </div>
  </div>

  <div class="row mb-3" *ngIf="showAddress">
    <div class="col-md-6">
      <label for="city" *ngIf="showLabels">Cidade</label>
      <input
        type="text"
        id="city"
        name="city"
        class="form-control"
        placeholder="{{ showLabels ? '' : 'Cidade' }}"
        formControlName="city"
      />
      <span
        class="text text-danger ms-2"
        *ngIf="addressForm.get('city').errors?.required"
      >
        Cidade é obrigatório
      </span>
    </div>

    <div class="col-md-6">
      <label for="region" *ngIf="showLabels"
        >Estado
        <i
          class="fa fa-question-circle"
          ngbTooltip="Para alterar você deve pesquisar seu CEP"
        ></i>
      </label>
      <input
        type="text"
        id="region"
        name="region"
        class="form-control"
        placeholder="{{ showLabels ? '' : 'Estado' }}"
        formControlName="region"
        readonly
      />
      <span
        class="text text-danger ms-2"
        *ngIf="addressForm.get('region').errors?.required"
      >
        Estado é obrigatório
      </span>
    </div>
  </div>
</form>

import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Address } from 'src/app/models/Address';

import { AddressService } from './../../services/address.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, AfterViewInit {


  @Input() showLabels = true;
  @Input() focused = false;
  @Input() showAddress: boolean;
  @Input() addressForm: UntypedFormGroup;
  @Output() showAdressChange = new EventEmitter<boolean>();

  @ViewChild('postcode') postcodeInput: ElementRef<HTMLInputElement>;

  constructor(
    private addressService: AddressService
  ) { }

  ngOnInit(): void {
    const { postcode } = this.addressForm.getRawValue();

    if (postcode) {
      this.setShowAddress(true);
      this.showLabels = true;
    }

    this.addressForm.patchValue({ postcode: this.formatPostcode(postcode) });
  }

  ngAfterViewInit(): void {
    if (this.focused) {
      setTimeout(() => this.postcodeInput?.nativeElement.focus(), 200);
    }
  }

  changePostcode(event: any, reset: boolean = false): void {
    const events = ['Backspace', 'Tab', 'Delete'];

    if (events.indexOf(event.code) > -1) {
      return;
    }

    const postcode = event.target.value?.replace(/\D/g, '');

    if (!postcode) {
      this.setShowAddress(false);
      this.patchAddress(null);
      return;
    }

    let formattedPostcode = this.formatPostcode(postcode);

    if (postcode.length < 8) {
      this.setShowAddress(false);
    }

    if (reset && formattedPostcode.length < 9) {
      formattedPostcode = '';
    }

    if (postcode.length === 8 && !this.showAddress) {
      this.addressService.search(postcode).subscribe((address) => {
        this.patchAddress(address);
        this.setShowAddress(true);
      });
    }

    this.addressForm.patchValue({ postcode: formattedPostcode });
  }

  setShowAddress(showAddress: boolean) {
    this.showAddress = showAddress;
    this.showAdressChange.emit(showAddress);
  }

  formatPostcode(postcode: string): string {
    if (postcode) {
      postcode = postcode.replace(/\D/g, '');
      let formattedPostcode = '';
      for (const element of postcode) {
        formattedPostcode += element;
        if (formattedPostcode.length === 5) {
          formattedPostcode += '-';
        }
      }
      return formattedPostcode;
    }

    return null;
  }

  patchAddress(address: Address): void {
    if (!address) {
      this.addressForm.patchValue({
        postcode: '',
        street: '',
        number: '',
        complement: '',
        city: '',
        region: '',
        district: ''
      });
    } else {
      this.addressForm.patchValue({
        street: address.street,
        number: address.number,
        city: address.city,
        region: address.region,
        district: address.district,
        complement: address.complement
      });
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AnalyticsCampaign } from 'src/app/models/AnalyticsCampaign';
import { AnalyticsSequence } from 'src/app/models/AnalyticsSequence';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private http: HttpClient
  ) {}

  getCampaigns(campaingName: string, fromAt: string, toAt: string, integrationId: string): Observable<PaginatedResult<AnalyticsCampaign[]>> {
    let endpoint = `${environment.apiUrl}/analytics/campaigns?`;

    if (campaingName) {
      endpoint+= `&name=${campaingName}`;
    }

    if (integrationId) {
      endpoint+= `&integration_id=${integrationId}`;
    }

    if (fromAt) {
      endpoint+= `&from=${fromAt}`;
    }

    if (toAt) {
      endpoint+= `&to=${toAt}`;
    }

    return this.http.get<PaginatedResult<AnalyticsCampaign[]>>(endpoint);
  }

  getSequences(campaignId: string, fromAt: string, toAt: string): Observable<PaginatedResult<AnalyticsSequence[]>> {
    let endpoint = `${environment.apiUrl}/analytics/sequences?`;

    if (campaignId) {
      endpoint+= `&campaign_id=${campaignId}`;
    }

    if (fromAt) {
      endpoint+= `&from=${fromAt}`;
    }

    if (toAt) {
      endpoint+= `&to=${toAt}`;
    }

    return this.http.get<PaginatedResult<AnalyticsSequence[]>>(endpoint);
  }
}

<div *ngIf="show">
  <div class="card gradient-row danger" *ngIf="freePlan; else userPlan">
    Assine nosso plano
    <a class="link" href="https://azpague.com.br/cart/U9s6Jx" target="_blank">clicando aqui</a>
    e tenha acesso à recargas ao custo de 0,13 a 0,07 por SMS.
  </div>
  <ng-template #userPlan>
    <div class="card mb-2" *ngIf="freePlan">
      <h2>Seu plano: {{ user.plan.name }}</h2>
      <p>
        Assine nosso plano
        <a class="link" href="https://azpague.com.br/cart/U9s6Jx" target="_blank">clicando aqui</a>
        e tenha acesso à recargas ao custo de 0,13 a 0,07 por SMS.
      </p>
    </div>
  </ng-template>
</div>

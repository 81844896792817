import { User } from 'src/app/models/User';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FREE_PLAN } from 'src/data/plans';
import { ADMINISTRATOR } from '../../services/user.service';

@Component({
  selector: 'app-plan-message',
  templateUrl: './plan-message.component.html',
  styleUrls: ['./plan-message.component.scss']
})
export class PlanMessageComponent implements OnInit {

  user: User;
  show: boolean;
  freePlan: boolean;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.show = this.user.profile_id !== ADMINISTRATOR;
    this.freePlan = !this.user.plan || this.user.plan.id === FREE_PLAN;
  }

}

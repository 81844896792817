import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { Agency } from 'src/app/models/Agency';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';

import { ToastService } from './../../shared/components/toast/toast.service';
import { AgencyService } from './../../shared/services/agency.service';
import { UserCommission } from 'src/app/models/UserCommission';
import { AgencyFilter } from 'src/app/models/AgencyFilter';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-partner-management',
  templateUrl: './partner-management.component.html',
  styleUrls: ['./partner-management.component.scss']
})
export class PartnerManagementComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModal') modal: any;

  page = 1;
  pageSize = 10;
  filter = '';
  agencies$: Observable<PaginatedResult<Agency[]>>;

  paymentForm: UntypedFormGroup;

  subscription: Subscription;

  invertedOrder: boolean;

  orderBy: string;

  now = new Date().toISOString().slice(0,10);

  showPendings: Agency[] = [];

  constructor(
    private agencyService: AgencyService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      id: [null],
      amount: [null, [Validators.required]],
      period: [null, [Validators.required]],
      payment_date: [this.now, [Validators.required]],
      description: [null]
    });

    this.route.queryParams.subscribe(params => {
      this.page = +params.page || this.page;
      this.filter = params.filter || this.filter;
      this.pageSize = +params.per_page || this.pageSize;
      this.orderBy = params.orderBy || this.orderBy;
      this.listAgencies();
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  alternateHiddenStatus(agency) {
    if (this.showPendings.includes(agency)) {
      this.showPendings = this.showPendings.filter(ag => {
        return ag != agency;
      })
    }
    else this.showPendings.push(agency);
  }

  hasZeroValues(agency) {
    const filter = agency.commissions.filter(commission => {
      return this.getPaidAmount(commission) !== 0 && this.getPendingAmount(commission) !== 0;
    })
    return filter.length == 0;
  }

  listAgencies() {
    this.invertedOrder = this.route.queryParams['_value']['invertedOrder'];

    if (this.invertedOrder) {
      this.invertedOrder = JSON.parse(this.invertedOrder.toString())
    }

    this.orderBy = this.route.queryParams['_value']['orderBy'];

    let filter: AgencyFilter = null;

    let order = this.orderBy;

    let inverted = this.invertedOrder;

    if (this.filter) {
      filter = {
        text: this.filter
      };
    }

    this.agencies$ = this.agencyService.listAgencies(this.page, this.pageSize, filter, order, inverted);
  }

  showStatus(commission: UserCommission) {
    return commission.status === 'pending' ? 'Pendente' : 'Pago';
  }

  getPendingAmount(commission: UserCommission) {
    const paidAmount = this.getPaidAmount(commission);
    return commission.amount - paidAmount;
  }

  getPaidAmount(commission: UserCommission) {
    return commission.payments.reduce((i, p) => i + Number(p.amount), 0);
  }

  invertOrder() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        invertedOrder: !this.invertedOrder
      },
      queryParamsHandling: 'merge'
    })
  };

  orderByChanged() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        orderBy: this.orderBy
      },
      queryParamsHandling: 'merge'
    })
  }

  pageChanged(page: number) {
    let queryParams = { page, per_page: this.pageSize, filter: this.filter, invertedOrder: this.invertedOrder, orderBy: this.orderBy};
    if (!this.filter) {
      delete queryParams.filter;
    }
    this.router.navigate(['/partner-management'], { queryParams });
  }

  register(agency: Agency, commission: UserCommission) {
    this.paymentForm.reset();
    this.now = new Date().toISOString().slice(0,10);
    this.paymentForm.patchValue({
      id: commission.id,
      amount: this.getPendingAmount(commission),
      period: commission.period,
      payment_date: this.now
    });

    this.modalService.open(this.modal).result.then(btn => {
      if (btn === 'YES') {
        const payment = this.paymentForm.getRawValue();
        this.subscription = this.agencyService.savePayment(agency, payment)
          .subscribe(() => {
            this.toastService.success('Pagamento incluído com sucesso!');
            this.listAgencies();
          },
            e => this.toastService.error('Erro ao cadastrar pagamento.'));
      }
    });
  }

  save(modal: any) {
    const payment = this.paymentForm.getRawValue();
    const actualDate = new Date();
    const paymentDate = new Date(payment.payment_date);
    if (paymentDate > actualDate) {
      this.toastService.error('A Data do Pagamento não pode ser maior que a data atual.');
      return;
    }

    modal.close('YES');
  }
}

import { AuthService } from '../../../shared/services/auth.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SmsService } from '../../../shared/services/sms.service';

import { Control } from '../../../models/Control';
import { PaginatedResult } from '../../../shared/model/paginated-result';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

  date: string;
  page = 1;
  perPage = 8;
  result: PaginatedResult<Control[]>;
  subscriptions: Subscription[] = [];

  get title(): string {
    const date = this.date.split('-').reverse().join('/');
    return `Mensagens Enviadas em ${date}`;
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private smsService: SmsService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.date = params.date;
        console.log(this.date);
      }),
      this.route.queryParams.subscribe(params => {
        this.page = params.page || this.page;
        this.perPage = params.per_page || this.perPage;
        this.listPaginated();
      }),

      this.authService.userChanged.subscribe(() => this.listPaginated())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  listPaginated(): void {
    this.subscriptions.push(
      this.smsService.listMessagesPaginated(this.date, this.page, this.perPage)
        .subscribe(result => this.result = result)
    );
  }

  pageChanged(page: number): void {
    this.router.navigate(['/messages-report', this.date], { queryParams: { page }});
  }

  back(): void {
    const queryParams = {
      month: +this.date.split('-')[1],
      year: +this.date.split('-')[0]
    };
    this.router.navigate(['/messages-report'], { queryParams });
  }

}

import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function urlValidatorService(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const urlPattern = /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
    const url = control.value;

    if (!url || urlPattern.test(url)) {
      return null; // URL válida, retorna null para indicar que é válida
    } else {
      return { invalidUrl: true }; // URL inválida, retorna um objeto com a chave 'invalidUrl'
    }
  };
}

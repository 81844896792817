<h1>Analisar sites curtos</h1>
<div class="card">
  <form (submit)="check()">
    <div class="form-group">
      <label>URL Curta:*</label>
      <input type="text" name="site" class="form-control" [(ngModel)]="site" />
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-success">Verificar</button>
    </div>
  </form>

  <fieldset *ngIf="siteInformation">
    <legend>Informações do site</legend>
    <div class="form-group">
      <label>URL Original:</label>
      <span class="form-control">{{ siteInformation.long_url }}</span>
    </div>
    <div class="row">
      <div class="form-group col">
        <label>Data/Hora criação:</label>
        <span class="form-control">{{ siteInformation.created_at | date:'dd/MM/yyyy' }}</span>
      </div>
      <div class="form-group col">
        <label>Quantidade de Clicks:</label>
        <span class="form-control">{{ siteInformation.clicks }}</span>
      </div>
      <div class="form-group col">
        <label>UTM Source:</label>
        <span class="form-control">{{ siteInformation.utm_source || '-' }}</span>
      </div>
    </div>

    <div class="form-group overflow">
      <label>Histórico de clicks</label>
      <table class="table">
        <thead>
          <tr>
            <th>IP</th>
            <th>Browser</th>
            <th>Data/Hora</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let history of siteInformation.history">
            <td>{{ history.ip }}</td>
            <td>{{ history.user_agent }}</td>
            <td>{{ history.created_at | date:'dd/MM/yyyy' }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form-group">
      <button type="button" class="btn btn-success" (click)="openSiteModal(modalSite)">
        <i class="fa fa-search"></i>
        Visualizar
      </button>

      <button type="button" class="btn btn-danger ms-2" (click)="deleteConfirmation()">
        <i class="fa fa-trash"></i>
        Excluir
      </button>
    </div>
  </fieldset>
</div>

<ng-template #modalSite let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Site</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <iframe [src]="verificationSite">Carregando...</iframe>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-danger ms-2" (click)="deleteConfirmation()">
        <i class="fa fa-trash"></i>
        Excluir
      </button>
    </div>
  </div>
</ng-template>

<app-page-title icon="fa-bullhorn" title="Filtro"></app-page-title>

<form *ngIf="administrator" (submit)="onFilter()">
  <div class="row col-md-12">
    <div class="col-md-12 col-sm-12" style="width: fit-content">
      <mat-form-field>
        <mat-label>Data inicial</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="startedMonth"
          name="startedMonth"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <small
          class="text text-danger"
          *ngIf="startedMonth && endedMonth && startedMonth > endedMonth"
          >Período inválido.</small>
      </mat-form-field>
    </div>
    <div class="col-md-12 col-sm-12" style="width: fit-content">
      <mat-form-field>
        <mat-label>Data final</mat-label>
        <input
          matInput
          [matDatepicker]="picker2"
          [(ngModel)]="endedMonth"
          name="endedMonth"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <small
          class="text text-danger"
          *ngIf="startedMonth && endedMonth && startedMonth > endedMonth"
          >Período inválido.</small>
      </mat-form-field>
    </div>
  </div>
  <div class="row col-md-12 col-sm-12 flex-wrap">
    <div class="row mb-5">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>Nome do envio massivo</label>
          <input
            type="text"
            name="name"
            [(ngModel)]="name"
            class="form-control"
            placeholder="Nome do envio massivo"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group mb-3">
          <label for="providerAccountId">Provedores</label>
          <select
            name="providerAccountId"
            id="providerAccountId"
            class="form-control"            
            [(ngModel)]="providerAccountId"
          >
            <option value="">Todas</option>
            <option *ngFor="let provider of providers" [value]="provider.id">
              {{ provider.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label>Pesquisar por mensagem</label>
        <input
          type="text"
          name="message"
          [(ngModel)]="messageBroadcast"
          class="form-control"
          placeholder="texto do envio massivo"
        />
      </div>
      <div
        style="width: fit-content"
        class="d-flex justify-content-center align-items-center"
      >
        <button
          class="btn btn-primary mt-4"
          type="submit"
        >
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
    <hr class="mt-4" />
    <div class="col-xs-12 mt-2 mb-2 d-flex flex-row">
      <div style="width: 90%">
        <label>Ordenar por</label>
        <select
          name="orderBy"
          id="orderBy"
          class="form-control"
          [(ngModel)]="orderBy"
          [value]="orderBy"
          (change)="orderByChanged()"
        >
          <option value="created_at">Data</option>
          <option value="broadcast_status_id">Status</option>
          <option value="leads_count">Leads</option>
        </select>
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-2"
        style="width: fit-content; margin: auto"
      >
        <label>Reverso</label>
        <app-switcher
          [checked]="invertedOrder"
          (change)="invertOrder()"
          style="max-height: 30px !important; user-select: none"
        ></app-switcher>
      </div>
    </div>
  </div>
</form>

<div *ngIf="administrator">
  <div *ngIf="result?.data.length > 0; else notFound">
    <app-accordion-panel
      *ngFor="let broadcast of result.data"
      [title]="getTitle(broadcast)"
      [class]="
        isScheduled(broadcast)
          ? 'warning'
          : isCancelled(broadcast)
          ? 'danger'
          : 'primary'
      "
    >
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <label>Envio</label>
          <p>{{ broadcast.broadcast_type.name }}</p>
        </div>
        <div class="col-md-2 col-sm-6">
          <label>Status</label>
          <p>{{ broadcast.broadcast_status_id | broadcastStatus }}</p>
        </div>
        <div class="col-md-2 col-sm-6">
          <label>Data envio</label>
          <p>{{ broadcast.scheduled_date | date : "dd/MM/yyyy, H:mm" }}</p>
        </div>
        <div class="col-md-2 col-sm-6">
          <label>Usuário</label>
          <p>{{ broadcast.user.name }}</p>
        </div>
        <div class="col-md-1 col-sm-4">
          <label>Leads totais</label>
          <p>{{ broadcast.leads_count }}</p>
        </div>
        <div class="col-md-1 col-sm-4">
          <label>mensagem</label>
          <p>{{ broadcast.message }}</p>
        </div>
        <div class="col-md-1 col-sm-4" *ngIf="broadcast.short_url">
          <label>Total clicks</label>
          <p>{{ broadcast.clicks || "0" }}</p>
        </div>
      </div>
      <div class="row" *ngIf="isCancelled(broadcast)">
        <div class="col">
          <label>Motivo do cancelamento</label>
          <p>{{ broadcast.reason }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-2 text-right">
          <a
            class="btn btn-secondary me-1"
            [routerLink]="['/broadcasts/view', broadcast.id]"
          >
            <i class="fa fa-search"></i>
          </a>
        </div>
      </div>
    </app-accordion-panel>
  </div>
</div>

<app-pagination
  *ngIf="result && result.data.length > 0"
  [currentPage]="result.current_page"
  [itemsPerPage]="result.per_page"
  [totalItems]="result.total"
  (pageChanged)="pageChanged($event)"
></app-pagination>

<ng-template #notFound>
  <div class="card" *ngIf="!loading && user.validated">
    <div class="col">Nenhum envio em massa realizado!</div>
  </div>
</ng-template>

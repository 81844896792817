import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

import { PaginatedResult } from './../model/paginated-result';
import { Campaign } from './../../models/Campaign';
import { CampaignFilter } from './../../models/CampaignFilter';

@Injectable({ providedIn: 'root' })
export class CampaignService {

  constructor(private http: HttpClient) { }

  listPaginated(page: number, pageSize: number, filter: CampaignFilter = null): Observable<PaginatedResult<Campaign[]>> {
    let url = `${environment.apiUrl}/campaigns?page=${page}&per_page=${pageSize}`;

    if (filter !== null && (filter.name || filter.active !== null || filter.integration !== null || filter.list !== null)) {
      url += '&filter=' + JSON.stringify(filter);
    }

    return this.http.get<PaginatedResult<Campaign[]>>(url);
  }

  findById(id: string): Observable<Campaign> {
    return this.http.get<Campaign>(`${environment.apiUrl}/campaigns/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/campaigns/${id}`);
  }

  save(campaign: Campaign): Observable<Campaign> {
    if (campaign.acURL) {
      campaign.acURL += '/api/3'
    }
    if (campaign.id) {
      return this.update(campaign);
    }
    return this.create(campaign);
  }

  resend(id: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/campaigns/${id}/resend`, {});
  }

  copy(id: string, name: string, leadListId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/campaigns/${id}/copy`, { name, lead_list_id: leadListId });
  }

  private create(campaign: Campaign): Observable<Campaign> {
    return this.http.post<Campaign>(`${environment.apiUrl}/campaigns`, campaign);
  }

  private update(campaign: Campaign): Observable<Campaign> {
    return this.http.put<Campaign>(`${environment.apiUrl}/campaigns/${campaign.id}`, campaign);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnalyticsCampaign } from 'src/app/models/AnalyticsCampaign';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';
import { Integration } from 'src/app/models/Integration';
import { IntegrationsService } from 'src/app/shared/services/integrations.service';

import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['../analytics.component.scss']
})
export class AnalyticsCampaignsComponent implements OnInit, OnDestroy {
  filter: string;
  fromAt: string;
  toAt: string;
  campaignName: string;

  integrationId: string;

  campaigns: PaginatedResult<AnalyticsCampaign[]>;
  integrations: Integration[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private integrationService: IntegrationsService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        this.fromAt = params.from;
        this.toAt = params.to;
        this.campaignName = params.name;
        this.integrationId = params.integration_id || '';

        this.loadFromApi();
      }),
      this.integrationService.listAll().subscribe(integrations => this.integrations = integrations)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  loadFromApi(): void {
    this.subscriptions.push(
      this.analyticsService.getCampaigns(this.campaignName, this.fromAt, this.toAt, this.integrationId)
      .subscribe(result => {
        this.campaigns = result;
      })
    );
  }

  filterBy(): void {
    const queryParams = {
      from: this.fromAt,
      to: this.toAt,
      name: this.campaignName,
      integration_id: this.integrationId,
    };

    this.router.navigate(['/analytics/campaigns'], { queryParams });
  }

  clearFilter(): void {
    this.router.navigate(['/analytics/campaigns']);
    this.filter = null;
  }

  filterByDate(value: string): void {
    let from = new Date();
    let to = new Date();

    this.filter = value;

    if (value == 'yesterday') {
      from.setDate(from.getDate()-1);
      to.setDate(to.getDate()-1);
    }

    if (value == '-7 days') {
      from.setDate(from.getDate()-7);
    }

    if (value == '-15 days') {
      from.setDate(from.getDate()-15);
    }

    if (value == 'this month') {
      from.setDate(1);
      to = new Date(to.getFullYear(), to.getMonth()+1, 0);
    }

    if (value == 'last month') {
      from.setMonth(to.getMonth()-1);
      from.setDate(1);
      to.setMonth(to.getMonth()-1);
      to = new Date(to.getFullYear(), to.getMonth()+1, 0);
    }

    const [fromDay, fromMonth, fromYear] = from.toLocaleDateString('pt-br').split('/');
    const [toDay, toMonth, toYear] = to.toLocaleDateString('pt-br').split('/');

    const queryParams = {
      from: `${fromYear}-${fromMonth}-${fromDay}`,
      to: `${toYear}-${toMonth}-${toDay}`,
      name: this.campaignName,
      integration_id: this.integrationId,
    };

    this.router.navigate(['/analytics/campaigns'], { queryParams });
  }
}

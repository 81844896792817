import { ToastService } from './../../shared/components/toast/toast.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/User';

import { AuthService } from './../../shared/services/auth.service';
import { AGENCY_CLIENT, UserService } from './../../shared/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  user: User;
  userForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  diffNF: boolean;
  errorCEP: boolean = false;

  oldCNPJCPF: string = '';

  operationType: string;
  
  operationTypes: any[] = [];

  get isAgency(): boolean {
    return this.user.profile_id === AGENCY_CLIENT;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private toast: ToastService
  ) {
    this.diffNF = false;
    this.user = authService.getUser();
    this.userService.getOperationTypes().subscribe(operation_types => this.operationTypes = operation_types);

    this.userForm = fb.group({
      id: [this.user.id],
      name: [this.user.name, [Validators.required, Validators.minLength(3)]],
      email: [this.user.email],
      phone: [this.user.phone, [Validators.required]],
      document: [this.user.document, [Validators.required]],
      pix_key: [this.user.pix_key],
      utm_source: [this.user.utm_source],
      password: [''],
      operation_type: [this.user.operation_type],
      postcode: [
        this.user.postcode,
        [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
      ],
      street: [
        this.user.street,
        [Validators.required, Validators.minLength(3)],
      ],
      number: [this.user.number, Validators.required],
      complement: [this.user.complement],
      district: [
        this.user.district,
        [Validators.required, Validators.minLength(3)],
      ],
      city: [this.user.city, Validators.required],
      region: [this.user.region, Validators.required],
      billing_document: [this.user.billing_document],
      billing_company_name: [this.user.billing_company_name],
      billing_company_alias: [this.user.billing_company_alias],
      billing_email: [this.user.billing_email],
      billing_im: [this.user.billing_im],
      billing_postcode: [this.user.billing_postcode],
      billing_street: [this.user.billing_street],
      billing_number: [this.user.billing_number],
      billing_complement: [this.user.billing_complement],
      billing_district: [this.user.billing_district],
      billing_city: [this.user.billing_city],
      billing_state: [this.user.billing_state],
    });

    if (this.user.billing_document) {
      this.diffNF = true;
    }
  }

  ngOnInit(): void {
    this.userService.findById(this.user.id).subscribe(user => {
      this.user = user;
      this.userForm.patchValue({ operation_type: this.user.operation_type })
    });
  }

  ngOnDestroy(): void {}

  update(): void {
    const user = this.userForm.getRawValue() as User;
    user.billing_update = true;

    if (this.diffNF == false) {
      user.billing_document = null;
      user.billing_company_name = null;
      user.billing_company_alias = null;
      user.billing_email = null;
      user.billing_im = null;
      user.billing_postcode = null;
      user.billing_street = null;
      user.billing_number = null;
      user.billing_complement = null;
      user.billing_district = null;
      user.billing_city = null;
      user.billing_state = null;
    }

    this.subscriptions.push(
      this.userService.save(user).subscribe(user => {
        this.user = user;
        this.toast.success('Dados alterados com sucesso!');

        if (this.authService.getSimulatedUser()) {
          this.authService.setSimulatedUser(user);
        } else {
          this.authService.setUser(user);
        }
      })
    );
  }

  formatCpfCnpj(e) {
    const cleanedValue = e.target.value.replace(/\D/g, '');

    if (e.target.value.length < this.oldCNPJCPF.length) {
      this.oldCNPJCPF = e.target.value
      return;
    }
    if (cleanedValue.length <= 11) {
      // Aplica máscara para CPF parcial
      const newValue = cleanedValue.replace(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
      this.oldCNPJCPF = newValue.toString();
      return e.target.value = newValue
    } else {
      // Aplica máscara para CNPJ parcial
      const newValue = cleanedValue.replace(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
      this.oldCNPJCPF = newValue.toString();
      return e.target.value = newValue
    }
  }

  cepChanged(e): void {
    const postcode = e.target.value ?? '';

    if ([8, 9].includes(postcode.length)) {
      this.errorCEP = false;
      const cepNumbers = postcode.replace(/\D/, '');
      fetch(`https://viacep.com.br/ws/${cepNumbers}/json`)
        .then(res => res.json().then(data => {
          this.userForm.patchValue({
            billing_street: data.logradouro,
            billing_city: data.localidade,
            billing_district: data.bairro,
            billin_complement: data.complemento,
            billing_state: data.uf
          });

          const signal = e.target.value.includes('-') ? '' : '-';

          e.target.value = e.target.value.slice(0, 5) + signal + e.target.value.slice(5, e.target.value.length);

          setTimeout(() => {
            document.getElementById('billing_number')?.focus();
          }, 200);
        })).catch(e => {
          this.errorCEP = true;
          return
        })
    }
  }

}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbCollapse} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-accordion-card-flat',
  templateUrl: './accordion-card-flat.component.html',
  styleUrls: ['./accordion-card-flat.component.scss']
})
export class AccordionCardFlatComponent implements OnInit {

  @ViewChild('collapse') collapse!: NgbCollapse;
  @Input() title: string;
  @Input('class') style: string;
  @Input() isHidden = true;

  @Output() change = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {

  }

  toggle() {
    this.isHidden = !this.isHidden;
    this.collapse.toggle(!this.isHidden);
    this.change.emit(!this.isHidden);
  }

}

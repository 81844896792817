import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthService } from './../services/auth.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLogged()) {
      let token = this.authService.getToken();

      if (req.url.includes('mercadopago.com')) {
        token = environment.mercadoPago.publicKey;
      }

      req = req.clone({
        headers: new HttpHeaders(`Authorization: Bearer ${token}`)
      });
    }

    return next.handle(req);
  }

}

<app-page-title icon="fa-fire" title="Pagamentos">
  <a routerLink="/partner" class="btn btn-secondary btn-icon-right">
    voltar <i class="fa fa-arrow-left"></i>
  </a>
</app-page-title>

<div class="card mb-2" *ngIf="next_payment">
  <h2>Próximo pagamento</h2>
  <div class="row">
    <div class="col">
      <label>Data</label>
      <span class="form-control">{{
        next_payment.payment_date | date : "dd/MM/yyyy"
      }}</span>
    </div>
    <div class="col">
      <label>Valor</label>
      <span class="form-control">{{
        next_payment.amount | numberFormat : "R$ " : true
      }}</span>
    </div>
  </div>
  <div class="row mt-2" *ngIf="false">
    <div class="col">
      <button class="btn btn-primary">Converter em créditos SMS</button>
    </div>
  </div>
</div>

<div class="card" *ngIf="payments$ | async as payments; else noPayments">
  <h2>Pagamentos Realizados</h2>
  <div class="row">
    <div class="col"><b>Data</b></div>
    <div class="col"><b>Valor</b></div>
    <div class="col"><b>Tipo</b></div>
  </div>
  <ng-container class="card mt-2" *ngIf="payments.data.length > 0; else noPayments">
    <div class="row mt-2" *ngFor="let payment of payments.data">
      <div class="col">{{ payment.payment_date | date : "dd/MM/yyyy" }}</div>
      <div class="col">{{ payment.amount | numberFormat : "R$ " : true  }}</div>
      <div class="col">{{ payment.type }}</div>
    </div>
  </ng-container>

  <app-pagination
    *ngIf="payments"
    [currentPage]="payments.current_page"
    [itemsPerPage]="payments.per_page"
    [totalItems]="payments.total"
    (pageChanged)="pageChangedPayments($event)"
  >
  </app-pagination>
</div>

<div class="card mt-2" *ngIf="pendingPaginate$ | async as pendingPaginate; else nopendingPaginate">
  <h2>Pagamentos Pendentes</h2>
  <div class="row">
    <div class="col"><b>Data</b></div>
    <div class="col"><b>Valor</b></div>
    <div class="col"><b>Período</b></div>
  </div>
  <ng-container class="card mt-2" *ngIf="pendingPaginate.data && pendingPaginate.data.length > 0; else nopendingPaginate">
    <div class="row mt-2" *ngFor="let pending of pendingPaginate.data">
      <div class="col">{{ pending.payment_date | date : "dd/MM/yyyy" }}</div>
      <div class="col">{{ pending.amount | numberFormat : "R$ " : true }}</div>
      <div class="col">{{ pending.period | period  }}</div>
    </div>
  </ng-container>
  <app-pagination
    *ngIf="pendingPaginate"
    [currentPage]="pendingPaginate.current_page"
    [itemsPerPage]="pendingPaginate.per_page"
    [totalItems]="pendingPaginate.total"
    (pageChanged)="pageChangedPending($event)"
  ></app-pagination>
</div>

<ng-template #noPayments>
  <div class="card">
    <span>Nenhum pagamento realizado até o momento :( </span>
  </div>
</ng-template>

<ng-template #noPending>
  <div class="card mb-2">
    <span>Nenhuma comissão até o momento :( </span>
  </div>
</ng-template>

<ng-template #nopendingPaginate>
  <div class="card mt-2">
    <span>Sem pagamentos pendentes até o momento :( </span>
  </div>
</ng-template>

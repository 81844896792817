import { Provider } from './../../models/Provider';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Control } from './../../models/Control';
import { UserCreditInfo } from './../../models/UserCreditInfo';

export interface ChartData {
  data: number[];
  labels: string[];
}

@Injectable({ providedIn: 'root' })
export class DashboardService {

  constructor(private http: HttpClient) { }

  userCreditsInfo(): Observable<UserCreditInfo> {
    return this.http.get<UserCreditInfo>(`${environment.apiUrl}/user-credits-info`);
  }

  providerCredits(): Observable<Provider[]> {
    return this.http.get<Provider[]>(`${environment.apiUrl}/provider-credits`);
  }

  reservatedCredits(): Observable<any>{
    return this.http.get(`${environment.apiUrl}/reservated-credits`)
  }

  lastMessages(): Observable<Control[]> {
    return this.http.get<Control[]>(`${environment.apiUrl}/last-messages`);
  }

  dailySents(startDate: string): Observable<ChartData> {
    return this.http.get<ChartData>(`${environment.apiUrl}/daily-sents`, {
      params: {
        startDate
      }
    });
  }

  updateProvider(provider: Provider): Observable<any> {
    return this.http.put(`${environment.apiUrl}/provider/${provider.id}`, provider);
  }

}

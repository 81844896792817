import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

export interface Video {
  title: string;
  id: string;
  type?: string;
  url?: SafeUrl;
}

@Injectable({ providedIn: 'root' })
export class HelpService {
  constructor(private http: HttpClient) { }

  tutorial(): Observable<Video[]> {
    return this.http.get<Video[]>(`${environment.apiUrl}/tutorial`);
  }
}

import { Directive, OnInit, ElementRef, Renderer2, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appCreditCard]',
  outputs: ['valueChanged']
})
export class CreditCardDirective implements OnInit {

  valueChanged = new EventEmitter<string>();

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.changeCardNumber(event);
  }

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    this.changeCardNumber(event);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    this.changeCardNumber(event);
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.setAttribute(elementRef.nativeElement, 'maxlength', '19');
  }

  ngOnInit(): void {
    this.changeCardNumber(this.elementRef.nativeElement.value);
  }

  changeCardNumber(event: any): void {
    let cardNumber = '';

    if (typeof(event) === 'string') {
      cardNumber = event?.replace(/\D/g, '');
    } else {
      const events = ['Backspace', 'Tab', 'Delete'];

      if (events.includes(event.code)) {
        return;
      }

      cardNumber = event.target.value?.replace(/\D/g, '');
    }

    if (!cardNumber) {
      this.elementRef.nativeElement.value = '';
      this.valueChanged.emit('');
      return;
    }

    let formattedCardNumber = '';
    for (const unitCardNumber of cardNumber) {
      formattedCardNumber += unitCardNumber;
      if ([4, 9, 14].includes(formattedCardNumber.length)) {
        formattedCardNumber += ' ';
      }
    }

    this.elementRef.nativeElement.value = formattedCardNumber;

    this.valueChanged.emit(formattedCardNumber);
  }

}

import { map, first } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { of } from 'rxjs';

export class InputCompareValidator {

  static compare(confirmationControl: string): any {

    return (control: AbstractControl) => {
      if (control?.parent) {
        const otherValue = control.parent.get(confirmationControl).value;

        return control
          .valueChanges
          .pipe(map(value => ((value && otherValue && value !== otherValue) ? { different : true } : null)))
          .pipe(first());
      }

      return of();

    };
  }

}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LeadList } from 'src/app/models/LeadList';

import { Lead } from '../../../models/Lead';
import { ConfirmationService } from '../../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { PaginatedResult } from '../../../shared/model/paginated-result';
import { LeadsService } from '../../../shared/services/leads.service';
import { ListsService } from '../../../shared/services/lists.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/models/User';
import { UtilService } from 'src/app/shared/services/util.service'

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit, OnDestroy {

  @Input() leadListId: string;
  @Input() showImportButton = true;
  @Input() reload: EventEmitter<boolean>;
  @Input() showPostbackButton: boolean;
  @Output() onImport = new Subject<LeadList>();

  page = 1;
  perPage = 5;
  fromFile: boolean;
  deleteLeads: boolean;
  exportLeads: boolean;
  file: File;
  text: string;
  filter: string;
  importError: boolean;
  animateError: boolean;
  result: PaginatedResult<Lead[]>;

  subscriptions: Subscription[] = [];
  leadText: string;

  @ViewChild('modalLeadText') leadTextModal: NgbModalRef;

  user: User;
  isUserValidated: boolean = false;
  

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private modalService: NgbModal,
    private leadsService: LeadsService,
    private listsService: ListsService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private UtilService: UtilService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.isUserValidated = this.user.validated; 

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(params => {
        this.page = params.page || 1; 
        this.UtilService.getExportLeads(this.user.id)
        this.listPaginated();
      })
    );

    this.reload.subscribe(() => this.listPaginated());

    this.subscriptions.push(
      this.UtilService.exportLeads$.subscribe(value => {
        this.exportLeads = value;
        this.cdr.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listPaginated(): void {
    if (this.leadListId) {
      if (this.result) {
        this.result.total = 0;
      }
      this.subscriptions.push(
        this.leadsService.listByLeadListId(this.leadListId, this.page, this.perPage, this.filter).subscribe(result => {
          this.result = result;
          if (result.last_page < this.page) {
            this.pageChanged(result.last_page);
          }
        })
      );
    }
  }

  getWhatsapp(lead: Lead) {
    let phone = lead.phone;
    if (phone.length <= 11) {
      phone = `55${phone}`
    }
    let text = 'Oi';
    if (lead.name) {
      text += ', ' + lead.name.split(' ')[0];
    }
    return `https://wa.me/+${phone}?text=${text}`;
  }

  getCheckoutUrl(lead: Lead): string {
    return lead.checkout_short_url || lead.checkout_url;
  }

  getTicketUrl(lead: Lead): string {
    return lead.ticket_short_url || lead.ticket_url;
  }

  getPixUrl(lead: Lead): string {
    return lead.pix_short_url || lead.pix_url;
  }

  getMembersUrl(lead: Lead): string {
    return lead.members_short_url || lead.members_url;
  }

  getCustomizedUrl(lead: Lead): string {
    return lead.customized_short_url || lead.customized_url;
  }

  async copy(text: string, url: string) {
    try {
      await navigator.clipboard.writeText(url);
      this.toastService.success(`${text} copiada com sucesso`);
    } catch (e) {
      this.toastService.error(`Erro ao copiar ${text}`);
    }
  }

  open(content: any): void {
    this.file = null;
    this.text = '';
    this.animateError = false;
    this.importError = false;
    this.deleteLeads = false;
    this.modalService.open(content);
  }

  import(modal: any): void {
    if (!this.file && !this.text) {
      this.importError = true;
      this.animateError = true;
      setTimeout(() => {
        this.animateError = false;
      }, 2000);
      return;
    }

    if (this.deleteLeads) {
      this.confirmationService.show('Você irá excluir todos os leads desta lista. Deseja continuar?', () => this.doImport(modal));
    } else {
      this.doImport(modal);
    }
  }

  doImport(modal: any) {
    this.leadsService.import(this.leadListId, this.file, this.text, this.deleteLeads)
      .pipe(finalize(() => modal.close()))
      .subscribe(res => {
        this.listPaginated();
        this.toastService.success('Sua importação está em progresso!');
        this.onImport.next(res);
      },
        res => this.toastService.error(res.error.message || 'Erro ao importar! Tente novamente mais tarde.')
      )
  }

  export(): void {
    this.leadsService.export(this.leadListId);
  }

  showPostbacks(lead: Lead): boolean {
    const createdAt = new Date(lead.created_at);
    const actualDate = new Date();

    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const millisecondsTotal = Math.abs(actualDate.getTime() - createdAt.getTime());
    const days = Math.floor(millisecondsTotal / oneDayInMilliseconds) + 1;

    return days < 7;
  }

  changeFile(file: File): void {
    this.file = file;
    this.importError = false;
  }

  pageChanged(page: number): void {
    this.router.navigate(['/lists/edit', this.leadListId], { queryParams: { page } });
  }

  confirmDelete(lead: Lead): void {
    this.confirmationService.show(`Deseja realmente excluir o(a) ${lead.name}?`, () => this.delete(lead.id));
  }

  delete(id: string): void {
    this.subscriptions.push(
      this.leadsService.delete(id).subscribe(
        () => {
          this.toastService.success('Lead excluído com sucesso!');
          this.listPaginated();
        },
        res => this.toastService.error(res.error.message || 'Erro ao excluir Lead! Tente novamente mais tarde.')
      )
    );
  }

  showLeadText(lead: Lead): void {
    this.leadText = lead.message_content;
    this.open(this.leadTextModal);
  }

}
